import axiosInstance from "../axiosConfig";
import { environment } from "../environment/environment";

interface Dependenci {
  id: number;
  name: string;
  company_id: number;
  total_tasks?: number;
  total_tasks_approved?: number;
}

export const dependenciesGet = async (): Promise<Dependenci[]> => {
  try {
    const res = await axiosInstance.get(`${environment.baseUrl}dependencies?filter[period]=${localStorage.getItem("period")}`);

    if (res.statusText !== "OK") {
      return [];
    }

    return res.data;
  } catch (error) {
    return [];
  }
};

export const registerDependencie = async (props: Dependenci): Promise<Boolean> => {
  if (props.name === '') return false;

  try {
    const res = await axiosInstance.post(`${environment.baseUrl}dependencies`, props);

    if (res.statusText !== "OK") {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};

export const changeDependenciStatus = async (
  user: number
): Promise<Boolean> => {
  try {
    const res = await axiosInstance.delete(
      `${environment.baseUrl}dependencies/${user}`,
    );

    return true;
  } catch (error) {
    return false;
  }
};

interface PropsResponse {
  message: string;
  errors: {
    code: string[];
    percentage_assigned: string[];
  },
  success: {
    status: string;
  }
}

const reset: PropsResponse = {
  message: '',
  errors: {
    code: [],
    percentage_assigned: [],
  },
  success: {
    status: ''
  }
};

export const editDependenci = async (props: Dependenci): Promise<PropsResponse> => {
  if (props.name === '') return reset;

  try {
    const res = await axiosInstance.put(
      `${environment.baseUrl}dependencies/${props.id}`,
      props
    ).then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

    return res;
  } catch (error) {
    return reset;
  }
};
