import { useEffect, Fragment, useState, useRef } from 'react';
import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import { Dialog, Transition } from '@headlessui/react';
import { useParams, Link } from "react-router-dom";
import sortBy from 'lodash/sortBy';
import Swal from 'sweetalert2';
import moment from 'moment';

import Card from "components/card";
import InputField from "components/fields/InputField";
import TextareaField from "components/fields/TextareaField";

import { environment } from "../../../../environment/environment";

import {
  MdFileUpload,
  MdOutlineInfo,
  MdCheckCircle,
  MdOutlineError,
  MdFilePresent,
  MdOutlineArrowBack,
  MdOutlineDownloadDone,
} from "react-icons/md";

import {
  useTask,
  useEvidences
} from '../../../../hooks/useTasks';

import {
  useEvidenceRegister
} from '../../../../hooks/useTasks';

function CheckTable() {
  const { id_guideline, id_task } = useParams();

  const { task, getTask } = useTask()
  const { evidences, getEvidences } = useEvidences()

  const [modal21, setModal21] = useState(false);
  const [modal22, setModal22] = useState(false);

  const [logs_evidence, setLogsevidence] = useState([]);

  useEffect(() => {
    getEvidences(Number(id_task));
    getTask(Number(id_task))
  }, [getEvidences, id_task, getTask]);

  // Get users list
  useEffect(() => {
    setInitialRecords(sortBy(evidences, 'id'))
    setRecordsData(sortBy(evidences, 'id'))
  }, [evidences])

  const [page, setPage] = useState(1);
  const PAGE_SIZES = [10, 20, 30, 50, 100];
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const [initialRecords, setInitialRecords] = useState(sortBy([], 'id'));
  const [recordsData, setRecordsData] = useState(initialRecords);

  const [hideCols, setHideCols] = useState<any>(['age', 'dob', 'isActive']);

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: 'id',
    direction: 'asc',
  });

  useEffect(() => {
    setPage(1);
}, [pageSize]);

  useEffect(() => {
    const from = (page - 1) * pageSize;
    const to = from + pageSize;
    setRecordsData([...initialRecords.slice(from, to)]);
  }, [page, pageSize, initialRecords]);

  useEffect(() => {
    const data = sortBy(initialRecords, sortStatus.columnAccessor);
    setInitialRecords(sortStatus.direction === 'desc' ? data.reverse() : data);
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortStatus]);




  const fileData: BlobPart[] = ['contenido del archivo']; // Puedes proporcionar los datos del archivo aquí
  const fileName = 'archivo.txt';
  const fileOptions: FilePropertyBag = { type: 'text/plain', lastModified: Date.now() };
  
  const [formData, setFormData] = useState({
    id: 0,
    name: '',
    file: new File(fileData, fileName, fileOptions),
    approve: false,
    description: '',
    tasks_id: [0]
  });

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [evt.target.name]: evt.target.value });
  };


  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleChangeFile = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const files = evt.target.files;

    if (files && files.length > 0) {
      setSelectedFile( evt.target.files[0] )
      setFormData({ ...formData, [evt.target.name]: files[0] });
    }
  };

  const handleChangeTextarea = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormData({ ...formData, [evt.target.name]: evt.target.value });
  };

  const handleSave = () => {
    // Logic for saving the saved title
    setFormData({
      id: 0,
      name: '',
      file: new File(fileData, fileName, fileOptions),
      approve: false,
      description: '',
      tasks_id: [Number(id_task)]
    })

    setModal21(true)
  };

  const { register, loadingRE, evidenceReg, setResponseRE } = useEvidenceRegister()

  useEffect(() => {
    const toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 5000,
    });

    if (register) {
      if (register.status) {
        toast.fire({
          icon: 'success',
          title: '¡Evidencia registrada exitosamente!',
          padding: '10px 20px',
        });
  
        setResponseRE({ status: false, message: '' });
        setSelectedFile(null)
        getEvidences(Number(id_task));
      }else{
        if (register.message) {
  
          toast.fire({
            icon: 'error',
            title: register.message,
            padding: '10px 20px',
          });
        }
      }
      setModal21(false)
    }
  }, [register, onchange, setResponseRE, getEvidences])

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    evidenceReg(formData);
  }

  const fileInputRef = useRef(null);

  const handleFileButtonClick = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    fileInputRef.current.click();
  };


  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit', // 'numeric' will give you the month as a number with leading zeros
    day: '2-digit',   // 'numeric' will give you the day as a number with leading zeros
  };

  const formatDate = (date: Date) => {
    return `Fecha y hora: ${moment(date).format('DD/MM/YYYY HH:mm:ss')}`;
  }

  const clearUrl = (url: string) => {
    return (url).replace('public/', '');
  }

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <div className="relative flex flex-row justify-between mt-5">
        <div className="flex items-center">
          <Link
            className="flex h-9 w-9 items-center justify-center rounded-full bg-indigo-100 dark:bg-indigo-100 dark:bg-white/5"
            to={ id_guideline ? `/admin/guidelines/${id_guideline}/tasks` : `/admin/tasks-assigned` }
          >
            <MdOutlineArrowBack className="h-6 w-6 text-gray-800 dark:text-white" />
          </Link>

          <h4 className="text-xl font-bold text-navy-700 ms-4 dark:text-white">
            Evidencias {task ? (<>/ {task.name}</>) : (<></>)}
          </h4>
        </div>

        <button onClick={handleSave} className="linear mt-2 w-1/6 rounded-xl bg-brand-500 py-[10px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Cargar
        </button>
      </div>

      <Transition appear show={modal21} as={Fragment}>
        <Dialog as="div" open={modal21} onClose={() => setModal21(false)}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0" />
            </Transition.Child>
            <div className="fixed inset-0 z-[999] bg-[black]/60">
                <div className="flex min-h-screen items-start justify-center px-4">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="panel my-8 w-full max-w-xl overflow-hidden  rounded-lg border-0 p-0 text-black dark:text-white-dark">
                            <Card extra={"w-full h-full p-3"}>

                            <div className="flex items-center justify-between bg-[#fbfbfb] px-5 py-3 dark:bg-[#121c2c]">
                                <h5 className="text-lg font-bold">Cargar evidencia</h5>
                                <button onClick={() => setModal21(false)} type="button" className="text-white-dark hover:text-dark">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                </button>
                            </div>

                              <div className="mt-2 mb-8 w-full">
                                <form className="space-y-5" onSubmit={submitForm}>

                                  <InputField
                                    value={formData.name}
                                    variant="auth"
                                    extra="mb-3"
                                    label="Nombre*"
                                    placeholder="Nombre"
                                    id="name"
                                    type="text"
                                    name="name"
                                    onChange={handleChange}
                                  />

                                  <div className="col-span-5 h-full w-full rounded-xl bg-lightPrimary dark:!bg-navy-700 2xl:col-span-6">
                                    <button onClick={handleFileButtonClick} className="flex h-full w-full flex-col items-center justify-center rounded-xl border-[2px] border-dashed border-gray-200 py-3 dark:!border-navy-700 lg:pb-0">

                                      {selectedFile && (
                                        <>
                                          <MdOutlineDownloadDone className="text-[80px] text-lime-500 dark:text-white" />
                                          <div className="mt-4">

                                            <p className="text-lg">Documento seleccionado:</p>
                                            <p className="font-semibold">{selectedFile.name}</p>
                                          </div>
                                        </>
                                      )}
                                      
                                      {!selectedFile &&  (
                                        <>
                                          <MdFileUpload className="text-[80px] text-brand-500 dark:text-white" />
                                          <h4 className="text-xl font-bold text-brand-500 dark:text-white">
                                            Cargar documento
                                          </h4>
                                        </>
                                      )}

                                      <p className="mt-2 text-sm font-medium text-gray-600">
                                        (PDF,DOCX,XLSX)
                                      </p>
                                    </button>

                                    <input
                                      type="file"
                                      name="file"
                                      accept=".pdf, .xlsx, .docx" // Define los tipos de archivos permitidos
                                      style={{ display: 'none' }} // Oculta el input, pero sigue siendo accesible
                                      onChange={handleChangeFile}
                                      ref={fileInputRef}
                                    />
                                  </div>

                                  <TextareaField
                                    value={formData.description}
                                    variant="auth"
                                    extra="mb-3"
                                    label="Descrición*"
                                    placeholder="Descripción"
                                    id="description"
                                    type="text"
                                    name="description"
                                    onChange={handleChangeTextarea}
                                  />

                                  <div className="flex w-full justify-end">
                                    <button
                                      type="submit"
                                      disabled={loadingRE}
                                      className="linear mt-2 px-5 rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                                        {loadingRE ? (
                                          'Cargando...'
                                        ) : (
                                          'Registrar'
                                        )} 
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </Card>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
      </Transition>

      <Transition appear show={modal22} as={Fragment}>
        <Dialog as="div" open={modal22} onClose={() => setModal22(false)}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0" />
            </Transition.Child>
            <div className="fixed inset-0 z-[999] bg-[black]/60">
                <div className="flex min-h-screen items-start justify-center px-4">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="panel my-8 w-full max-w-xl overflow-hidden  rounded-lg border-0 p-0 text-black dark:text-white-dark">
                            <Card extra={"w-full h-full p-3"}>

                              <div className="flex items-center justify-between bg-[#fbfbfb] px-5 py-3 dark:bg-[#121c2c]">
                                <h5 className="text-lg font-bold">Bitacora</h5>
                                <button onClick={() => setModal22(false)} type="button" className="text-white-dark hover:text-dark">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                </button>
                              </div>
                              <div className="mt-2 mb-8 w-full h-96 overflow-auto">

                                <div className="w-full mx-auto mt-10 relative">

                                  <div className="timeline">
                                    <div className="timeline__group">
                                      <span className="timeline__year time" aria-hidden="true">Linea de tiempo</span>
                                      <div className="timeline__cards">


                                        {logs_evidence.map((log, i) => (
                                          <div key={i} className="timeline__card card">
                                            <header className="card__header">
                                              <time className="time" dateTime={log.created_at}>
                                                <span className="time__day"> {formatDate(log.created_at)}</span>
                                              </time>
                                            </header>
                                            <div className="card__content">
                                              {log.comment ? (
                                                <p>Comentario: {log.comment}</p>
                                              ):(<></>)}
                                              
                                              <div className='flex justify-between'>
                                                <span className="text-blue-500 font-bold">{log.tag}</span>

                                                <span className="bg-navy-800 rounded-xl px-2 py-1 text-sm font-bold text-white">
                                                  {log.user.name}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                        
                                      </div>
                                    </div>
                                  </div>

                                </div>        

                                <div className="flex w-full justify-end">
                                  <button
                                    onClick={() => setModal22(false)}
                                    className="linear mt-2 px-5 rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                                  >
                                    Cerrar
                                  </button>
                                </div>
                              </div>
                          </Card>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
      </Transition>

      <div className="datatables">
        <DataTable
            className="whitespace-nowrap table-hover"
            records={recordsData}
            columns={[
                {
                    accessor: 'name',
                    title: 'TAREA',
                    sortable: true,
                    hidden: hideCols.includes('name'),
                    render: ({ name }) => <div className={`capitalize`}>
                      <p className="text-base font-bold text-navy-700 dark:text-white">
                        { name }
                      </p>
                    </div>,
                },
                {
                    accessor: 'description',
                    title: 'DESCRIPCIÓN',
                    sortable: true,
                    hidden: hideCols.includes('description'),
                    render: ({ description }) => <div className={''}>{ description }</div>,
                },
                {
                    accessor: 'file',
                    title: 'EVIDENCIA',
                    sortable: true,
                    hidden: hideCols.includes('file'),
                    render: ({ file }) => <div className={``}>
                      {file.map((item: { path: string }, f: number) => {
                        return (
                          <Link
                            key={`f-`+f}
                            className="flex h-9 w-9 items-center justify-center rounded-full dark:bg-white/5"
                            target='_blank'
                            to={ environment.storageUrl+clearUrl(item.path) }
                          >
                            <MdFilePresent className="h-6 w-6 text-gray-800 dark:text-white" />
                          </Link>
                        )
                      })}
                    </div>,
                },
                {
                    accessor: 'companie',
                    title: 'ESTADO',
                    sortable: true,
                    hidden: hideCols.includes('companie'),
                    render: ({ approve }) => <div className={`capitalize`}>
                        {approve ? (
                          <>
                            <MdCheckCircle className="text-green-500 me-1 dark:text-green-300" />
                            <p className='text-sm'>Aprobado</p>
                          </>
                        ) : (
                          <>
                            <MdOutlineError className="text-amber-500 me-1 dark:text-amber-300" />
                            <p className='text-sm'>No aprobado</p>
                          </>
                        )}
                    </div>,
                },
                {
                    accessor: 'grade',
                    title: 'FECHA CARGA',
                    sortable: true,
                    hidden: hideCols.includes('grade'),
                    render: ({ created_at }) => <div className={`capitalize`}>{ 
                      new Intl.DateTimeFormat('en-US', options).format(Date.parse(created_at))
                    }</div>,
                },
                {
                  accessor: 'logs',
                  title: 'BITACORA',
                  sortable: true,
                  hidden: hideCols.includes('logs'),
                  render: ({ logs }) => <div className={`capitalize`}>
                    <button onClick={() => {
                      setLogsevidence(logs)
                      setModal22(true)
                    }}
                    disabled={logs.length === 0}
                    className="flex h-9 w-9 items-center justify-center rounded-full bg-indigo-100 dark:bg-indigo-100 dark:bg-white/5">
                      <MdOutlineInfo className="h-6 w-6 text-gray-800 dark:text-white" />
                    </button>
                  </div>,
                },
            ]}
            highlightOnHover
            totalRecords={initialRecords.length}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            sortStatus={sortStatus}
            onSortStatusChange={setSortStatus}
            minHeight={200}
            paginationText={({ from, to, totalRecords }) => `Mostrando  ${from} a ${to} de ${totalRecords} resultados`}
        />
    </div>

    </Card>
  );
}

export default CheckTable;
