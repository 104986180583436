import axiosInstance from "../axiosConfig";
import { environment } from "../environment/environment";

interface autoDiagnosis {
  id: number;
  diagnostic: number;
  guideline_id: number;
}

export const autoDiagnosisGet = async (): Promise<autoDiagnosis[]> => {
  try {
    const res = await axiosInstance.get(`${environment.baseUrl}autodiagnostics`);

    if (res.statusText !== "OK") {
      return [];
    }

    return res.data;
  } catch (error) {
    return [];
  }
};

export const registerAutoDiagnostic = async (props: autoDiagnosis[]): Promise<Boolean> => {
  if (props.length === 0) return false;

  try {
    const res = await axiosInstance.post(`${environment.baseUrl}autodiagnostics`, props);

    if (res.statusText !== "OK") {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};

export const editAutoDiagnosis = async (props: autoDiagnosis[]): Promise<Boolean> => {
  if (props.length === 0) return false;

  try {
    const res = await axiosInstance.put(`${environment.baseUrl}autodiagnostics/${localStorage.getItem("period")}`, props);

    if (res.statusText !== "OK") {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};

export const closeAutoDiagnostic = async (period_id: number): Promise<Boolean> => {
  try {
    const res = await axiosInstance.get(`${environment.baseUrl}periods/close/autodiagnostic/${period_id}`);

    if (res.statusText !== "OK") {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};
