import { useState, useCallback } from "react";

import {
  editUser,
  usersGet,
  registerUser,
  changeUserStatus,
  usersGetDependencie,
  permissionsUserGet
} from "../services/usersService";

interface Users {
  id: number;
  name: string;
  email: string;
  company_id: number;
  rol_id: number;
  dependency?: {
    name: string;
  },
  tasks?:[]
}

export const useUsers = () => {
  const [users, setUsers] = useState<Users[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const getUsers = useCallback(async (permited: string) => {
    try {
      setLoading(true);
      setError(null);
      const newUsers = await usersGet(permited);
      setUsers(newUsers);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { users: users, getUsers, loading };
};

export const useUsersDependenci = () => {
  const [users, setUsers] = useState<Users[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const getUsersDependencie = useCallback(async (id_dependecie: number) => {
    try {
      setLoading(true);
      setError(null);
      const newUsers = await usersGetDependencie(id_dependecie);
      setUsers(newUsers);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { users: users, getUsersDependencie, loading };
};

export const useUserRegister = () => {
  const [register, setResponseRU] = useState<Boolean>(false);
  const [loadingRu, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const userReg = useCallback(async (props: Users): Promise<Boolean> => {
    try {
      setLoading(true);
      setError(null);
      const userResponse = await registerUser(props);
      setResponseRU(userResponse);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
    return false;
  }, []);

  return { register: register, userReg, setResponseRU };
};

export const useUserEdit = () => {
  const [ edited, setResponseEU] = useState<Boolean>(false);
  const [loadingRu, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const userEdit = useCallback(async (props: Users): Promise<Boolean> => {
    try {
      setLoading(true);
      setError(null);
      const userResponse = await editUser(props);
      setResponseEU(userResponse);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
    return false;
  }, []);

  return { edited: edited, userEdit, setResponseEU };
};

export const useStateUser = () => {
  const [ statusChange, setResponseCS ] = useState<Boolean>(false);
  const [ loadingCS, setLoading ] = useState<Boolean>(false);

  const [ errors , setError ] = useState(null);

  const changeState = useCallback(async (id: number): Promise<Boolean> => {
    try {
      setLoading(true);
      setError(null);
      const userResponse = await changeUserStatus(id);
      setResponseCS(userResponse);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
    return false;
  }, []);

  return { statusChange: statusChange, setResponseCS, changeState, loadingCS };
};

interface Permissions {
  permissions: string[];
}

export const usePermissionsUser = () => {
  const [ permissions, setPermissions ] = useState<Permissions>({ permissions: []});
  const [ loading, setLoading ] = useState<Boolean>(false);

  const [ errors, setErrors ] = useState(null);

  const getPermissions = useCallback(async () => {
    try {
      setLoading(true);
      setErrors(null);
      const newUsers = await permissionsUserGet();
      setPermissions(newUsers);
    } catch (e: any) {
      setErrors(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { permissions: permissions, getPermissions, setPermissions, errors, loading };
};
