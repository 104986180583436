import Banner from "assets/images/Banner.png";

interface Props {
  name: string;
}

const Parent = ({name}: Props) => {
  return (
    <div className="flex flex-col justify-center items-center mt-5 rounded-sm"
      style={{
        backgroundImage: `url(${Banner})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '6rem'
      }}
    >
      <div className="px-2 -bottom-12 flex w-auto dark:!border-navy-700">
        <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
          {name}
        </h4>
      </div>
    </div>
  );
};

export default Parent;