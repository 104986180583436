import InputField from "components/fields/InputField";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

import { useParams } from "react-router-dom";

import { 
  usePasswordReset
} from '../../hooks/useAuth';

export default function SignIn() {

  const navigate = useNavigate();

  const { token, email } = useParams();

  const [formData, setFormData] = useState({
    token: '',
    email: '',
    password: '',
    password_confirmation: '',
  });

  useEffect(()=>{
    formData.token = token;
    formData.email = email;
  }, [token, email, formData])
  
  const { password_reset, PasswordReset } = usePasswordReset()
  
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [evt.target.name]: evt.target.value });
  };

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    PasswordReset(formData)
  }

  useEffect(() => {
    const toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
    });

    if (password_reset) {
      if (password_reset?.success) {
        toast.fire({
          icon: 'success',
          title: `Se asigno la contrseña correctamente!`,
          padding: '10px 20px',
        });

        navigate('/auth/login');
      } else if ( password_reset?.errors.email || password_reset?.errors.token ) {
        toast.fire({
          icon: 'error',
          title: 'El token de restablecimiento de contraseña no es válido.',
          padding: '10px 20px',
        });
      } else if ( password_reset?.errors.password ) {
        toast.fire({
          icon: 'error',
          title: 'La contraseña debe tener al menos 8 caracteres y signos *#@$.',
          padding: '10px 20px',
        });
      }else {
        navigate('/auth/login');
      }
    }
  }, [password_reset, navigate])

  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Asignar contraseña
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Asignar nueva contraseña al usuario
        </p>

        <form onSubmit={submitForm}>
          {/* Password */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Contraseña*"
            placeholder="Min. 8 characters"
            id="password"
            type="password"
            name="password"
            onChange={handleChange}
          />
          {/* Password */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Confirmar contraseña*"
            placeholder="Min. 8 characters"
            id="password_confirmation"
            type="password"
            name="password_confirmation"
            onChange={handleChange}
          />
          {/* Checkbox */}
          <button type="submit" className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
            Establecer contraseña
          </button>
        </form>
      </div>
    </div>
  );
}
