import axiosInstance from "../axiosConfig";
import { environment } from "../environment/environment";

interface Company {
  id: number;
  name: string;
  nit: number;
  logo: File;
  name_user: string;
  email: string;
  _method?: string;
}

export const companiesGet = async (permission: string): Promise<Company[]> => {
  try {
    const res = await axiosInstance.get(permission === 'all' ?
      `${environment.baseUrl}companies`
      :
      `${environment.baseUrl}companies?filter[period]=${localStorage.getItem("period")}`
    );

    if (res.statusText !== "OK") {
      return [];
    }

    return res.data;
  } catch (error) {
    return [];
  }
};

export const periodscompanieGet = async (): Promise<Company[]> => {
  try {
    const res = await axiosInstance.get(
      `${environment.baseUrl}periods?filter[company]=${localStorage.getItem("company")}`
    );

    if (res.statusText !== "OK") {
      return [];
    }

    return res.data;
  } catch (error) {
    return [];
  }
};

export const registerCompany = async (props: Company): Promise<Boolean> => {
  if (props.name === '') return false;

  try {
    const res = await axiosInstance.post(`${environment.baseUrl}companies`, props, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (res.statusText !== "OK") {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};


interface PropsResponse {
  message: string;
  errors: {
    logo: string[];
    name: string[];
  },
  success: {
    status: string;
  },
  valid?: Company
}
const reset: PropsResponse = {
  message: '',
  errors: {
    logo: [],
    name: [],
  },
  success: {
    status: '',
  }
};

export const editCompani = async (props: Company): Promise<PropsResponse> => {
  if (props.name === '') return reset;

  props._method = 'PUT';

  try {
    const res = await axiosInstance.post(
      `${environment.baseUrl}companies/${props.id}`,
      props,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    ).then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

    return res;
  } catch (error) {
    return reset;
  }
};