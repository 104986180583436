import { useState, useCallback } from "react";

import {
  registerAutoDiagnostic,
  editAutoDiagnosis,
  autoDiagnosisGet,
  closeAutoDiagnostic
} from "../services/autoDiagnosisService";

interface autoDiagnosis {
  id: number;
  diagnostic: number;
  guideline_id: number;
}

export const useAutoDiagnosis = () => {
  const [ autoDiagnosis, setUsers ] = useState<autoDiagnosis[]>([]);
  const [ loading, setLoading ] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const getAutoDiagnosis = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const newUsers = await autoDiagnosisGet();
      setUsers(newUsers);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { autoDiagnosis: autoDiagnosis, getAutoDiagnosis, loading };
};

export const useAutoDiagnosisRegister = () => {
  const [register, setResponseRC] = useState<Boolean>(false);
  const [loadingRu, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const autoDiagnosisReg = useCallback(async (props: autoDiagnosis[]): Promise<Boolean> => {
    try {
      setLoading(true);
      setError(null);
      const userResponse = await registerAutoDiagnostic(props);
      setResponseRC(userResponse);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
    return false;
  }, []);

  return { register: register, autoDiagnosisReg, setResponseRC, loadingRu };
};

export const useAutoDiagnosisEdit = () => {
  const [ edited, setResponseEA ] = useState<Boolean>(false);
  const [ loadingRu, setLoading ] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const autoDiagnosisEdit = useCallback(async (props: autoDiagnosis[]): Promise<Boolean> => {
    try {
      setLoading(true);
      setError(null);
      const userResponse = await editAutoDiagnosis(props);
      setResponseEA(userResponse);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
    return false;
  }, []);

  return { edited: edited, autoDiagnosisEdit, setResponseEA, loadingRu };
};

export const useCloseAutoDiagnostic = () => {
  const [ closed, setResponseCS] = useState<Boolean>(false);
  const [ loading, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const closedAutoDiagnostic = useCallback(async (period_id: number) => {
    try {
      setLoading(true);
      setError(null);
      const response = await closeAutoDiagnostic(period_id);
      setResponseCS(response);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { closed: closed, closedAutoDiagnostic, loading };
};

