import { createSlice } from '@reduxjs/toolkit';

interface MySliceState {
  id_node: number;
}

const initialState: MySliceState = {
  id_node: 0,
};

const nodeSlice = createSlice({
  name: 'sanction',
  initialState: initialState,
  reducers: {
    setIdNode: (state, { payload }) => {
      state.id_node = payload;
    },
  }
});

export const { setIdNode } = nodeSlice.actions;

export default nodeSlice.reducer;