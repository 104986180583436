import axios from "axios";
import axiosInstance from '../axiosConfig';
import { environment } from '../environment/environment';

interface Props {
  user: string;
  pass: string;
}

interface PropsRespoonseLogin {
  token: string;
  user: {
    id: string;
    email: string;
    name: string;
    surname: string;
  },
  period: {
    id: number;
  }
  company: {
    id: number;
    logo: {
      path: string,
    },
    type_guideline: string;
  }
}

const userLogin: PropsRespoonseLogin = {
  token: '',
  user: {
    id: '',
    email: '',
    name: '',
    surname: '',
  },
  period: {
    id: 0,
  },
  company: {
    id: 0,
    logo: {
      path: '',
    },
    type_guideline: ''
  },
};

export const loginUser = async ({ user, pass }: Props): Promise<PropsRespoonseLogin> => {
  if (user === "" || pass === "") return userLogin;

  try {
    const res = await axios
      .post(
        `${environment.baseUrl}login`,
        {
          email: user,
          password: pass,
        }
      );

    if (res.statusText !== "OK") {
      return userLogin
    }

    return res.data;
  } catch (error) {
    return userLogin;
  }
};

export const logout = async (): Promise<Boolean> => {
  try {
    const res = await axiosInstance
      .post(
        `${environment.baseUrl}logout`
      );

    if (res.statusText !== "OK") {
      return false
    }

    localStorage.removeItem('authToken')
    localStorage.removeItem('uuid')
    localStorage.removeItem('first_name')
    localStorage.removeItem('last_name')
    localStorage.removeItem('surname')
    
    return true;
  } catch (error) {
    return false;
  }
};

interface PropsPasswordReset {
  token: string;
  email: string;
  password: string;
  password_confirmation: string;
}


interface PropsRespoonsePR {
  success: string;
  error: {
    email: string;
  }
  errors : {
    email: string;
    password: string;
    token: string;
  }
}

const passwordResetV: PropsRespoonsePR = {
  success: "",
  error: {
    email: "",
  },
  errors : {
    email: "",
    password: "",
    token: "",
  }
};

export const passwordReset = async (props: PropsPasswordReset): Promise<PropsRespoonsePR> => {
  if (props.password === "" || props.password_confirmation === "") return passwordResetV;

  try {
    const res = await axios
      .post(
        `${environment.baseUrl}resetPassword`, props
      ).then((response) => {
        return response.data;
      }).catch((error) => {
        return error.response.data;
      });

    return res;
  } catch (error) {
    return passwordResetV;
  }
};

interface PropsForgotPassword {
  email: string;
}

export const forgotPasswordEC = async (props: PropsForgotPassword): Promise<PropsRespoonsePR> => {
  if (props.email === "") return passwordResetV;

  try {
    const res = await axios
      .post(
        `${environment.baseUrl}forgotPassword`, props
      ).then((response) => {
        return response.data;
      }).catch((error) => {
        return error.response.data;
      });

    return res;
  } catch (error) {
    return passwordResetV;
  }
};
