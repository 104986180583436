import { useState, useCallback } from "react";

import {
  companiesGet,
  editCompani,
  registerCompany,
  periodscompanieGet,
} from "../services/companyService";

interface Company {
  id: number;
  name: string;
  nit: number;
  logo: File;
  name_user: string;
  email: string;
}

interface Period {
  id: number;
  name: string;
  period: string;
  can_modify_structure: boolean;
  nodes: [
    {
      auto_diagnostic: number;
      percentage_assigned: number;
      percentage_progress: number;
      name: string;
      parent_id: number; 
    }
  ]
}

export const useComapnies = () => {
  const [companies, setUsers] = useState<Company[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const getCompanies = useCallback(async (permission: string) => {
    try {
      setLoading(true);
      setError(null);
      const newUsers = await companiesGet(permission);
      setUsers(newUsers);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { companies: companies, getCompanies, loading };
};

export const useComapniePeriods = () => {
  const [periods, setPeriods] = useState<Company[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const getPeriods = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const periodsGeting = await periodscompanieGet();
      setPeriods(periodsGeting);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { periods: periods, getPeriods, loading };
};

export const useCompanyRegister = () => {
  const [register, setResponseRC] = useState<Boolean>(false);
  const [loadingRu, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const companyReg = useCallback(async (props: Company): Promise<Boolean> => {
    try {
      setLoading(true);
      setError(null);
      const userResponse = await registerCompany(props);
      setResponseRC(userResponse);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
    return false;
  }, []);

  return { register: register, companyReg, setResponseRC, loadingRu };
};

interface PropsResponse {
  message: string;
  errors: {
    logo: string[];
    name: string[];
  },
  success: {
    status: string;
  },
  valid?: Company
}

const reset: PropsResponse = {
  message: '',
  errors: {
    logo: [],
    name: []
  },
  success: {
    status: ''
  }
};

export const useCompaniEdit = () => {
  const [ edited, setResponseED ] = useState<PropsResponse>(null);
  const [ loadingED, setLoading ] = useState<boolean>(false);

  const [ errors, setError ] = useState(null);

  const companiEdit = useCallback(async (props: Company): Promise<PropsResponse> => {
    try {
      setLoading(true);
      setError(null);
      const userResponse = await editCompani(props);
      setResponseED(userResponse);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
    return reset;
  }, []);

  return { edited: edited, companiEdit, setResponseED, loadingED, errors };
};

