import { combineReducers, configureStore } from '@reduxjs/toolkit';
import nodeSlice from './nodeSlice';

const rootReducer = combineReducers({
    node: nodeSlice,
});

export default configureStore({
    reducer: rootReducer,
});

export type IRootState = ReturnType<typeof rootReducer>;
