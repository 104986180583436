import { useEffect, useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import InputField from "components/fields/InputField";
import Card from "components/card";
import Swal from 'sweetalert2';

import {
  MdDeleteForever,
  MdAdd
} from "react-icons/md";

import {
  useLevelRegister
} from '../../../../hooks/useStructures'

interface LevelsData {
  name: string; 
  file: File;
  code: string;
}

const Banner = () => {

  const [modal21, setModal21] = useState(false);

  const fileData: BlobPart[] = ['contenido del archivo']; // Puedes proporcionar los datos del archivo aquí
  const fileName = 'archivo.txt';
  const fileOptions: FilePropertyBag = { type: 'text/plain', lastModified: Date.now() };

  const [formData, setFormData] = useState({
    name: '',
    file: new File(fileData, fileName, fileOptions),
    code: '',
    period_id: Number(localStorage.getItem('period')),
  });

  const { register, levelsReg, setResponseRC } = useLevelRegister()

  useEffect(() => {
    if (register) {
      const toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
      });
      toast.fire({
        icon: 'success',
        title: '¡Registrado exitosamente!',
        padding: '10px 20px',
      });
    }

    setModal21(false)
    setResponseRC(false)
  }, [register, setResponseRC])

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [evt.target.name]: evt.target.value });
  };

  const handleChangeFile = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const files = evt.target.files;

    if (files && files.length > 0) {
      setFormData({ ...formData, [evt.target.name]: files[0] });
    }
  };
  
  const [levels, setLevels] = useState<Array<LevelsData>>([]);

  const [formDataR, setFormDataR] = useState({
    levels: [...levels],
    code: 'GD',
    period_id: Number(localStorage.getItem('period')),
  });

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    levelsReg(formDataR);
  }

  const addLevelList = () => {
    setLevels([...levels, { name: formData.name, file: formData.file, code: formData.code }]);

    formData.name = '';
    formData.file = null;
    formData.code = null;
  }

  useEffect(()=>{
    formDataR.levels = [...levels]
  }, [levels, formDataR])

  const rmLevelList = (index: number) => {
    levels.splice(index, 1);
    setLevels([...levels]);
  };

  return (
    <div className="flex flex-col bg-clip-border px-3 py-4 w-full items-end">

        {/* Background and profile */}
        <button onClick={() => {
            setModal21(true)
        }} className="linear mt-2 w-1/6 rounded-xl bg-brand-500 py-[10px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
            Registrar niveles
        </button>

        <Transition appear show={modal21} as={Fragment}>
            <Dialog as="div" open={modal21} onClose={() => setModal21(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0" />
                </Transition.Child>
                <div className="fixed inset-0 z-[999] bg-[black]/60">
                    <div className="flex min-h-screen items-start justify-center px-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="panel my-8 w-full max-w-xl overflow-hidden  rounded-lg border-0 p-0 text-black dark:text-white-dark">
                                <Card extra={"w-full h-full p-3"}>

                                <div className="flex items-center justify-between bg-[#fbfbfb] px-5 py-3 dark:bg-[#121c2c]">
                                    <h5 className="text-lg font-bold">Registrar</h5>
                                    <button onClick={() => setModal21(false)} type="button" className="text-white-dark hover:text-dark">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                        </svg>
                                    </button>
                                </div>

                                  <div className="mt-2 mb-8 w-full">
                                    <form className="space-y-5" onSubmit={submitForm}>

                                      <div className='flex'>
                                        <div className="w-full">
                                          <InputField
                                            value={formData.name}
                                            variant="auth"
                                            extra="mb-3 w-11/12"
                                            label="Nivel*"
                                            placeholder="Nivel"
                                            id="name"
                                            type="text"
                                            name="name"
                                            onChange={handleChange}
                                          />
                                        </div>

                                        <div className="w-full">
                                          <div className='mb-3 w-11/12'>
                                            <label className='text-sm text-navy-700 dark:text-white ml-3 font-bold' htmlFor="file">Logo*</label>
                                            <input
                                              type="file"
                                              id="file"
                                              name="file"
                                              accept='image/*'
                                              onChange={handleChangeFile}
                                              className='mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none'
                                            />
                                          </div>
                                        </div>

                                        <div className="w-full">
                                          <div className='mb-3 w-11/12'>
                                            <label className='text-sm text-navy-700 dark:text-white ml-3 font-bold' htmlFor="file">Codigo*</label>
                                            <input
                                              type="text"
                                              id="code"
                                              name="code"
                                              onChange={handleChange}
                                              className='mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none'
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className='flex w-full items-end justify-end'>
                                        <div onClick={addLevelList} className="cursor-pointer w-12 h-12 rounded-sm flex justify-center items-center bg-brand-500 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                                          <MdAdd />
                                        </div>
                                      </div>

                                      <hr />

                                        <div className='h-64 overflow-y-scroll space-y-4 scrollbar-thumb-rounded-full scrollbar-thumb-blue-500 scrollbar-track-gray-300 scrollbar-thumb-hover:scale-125'>
                                          {levels.map((level, i) => (
                                            <div key={i} className="flex w-full items-center justify-between rounded-2xl bg-white p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                              <div className="flex items-center">
                                                <div className="ml-4">
                                                  <p className="text-base font-medium text-navy-700 dark:text-white">
                                                    {level.name}
                                                  </p>
                                                </div>
                                              </div>
                                              <div onClick={() => rmLevelList(i)} className="cursor-pointer mr-4 flex items-center justify-center text-gray-600 dark:text-white">
                                                <MdDeleteForever />
                                              </div>
                                            </div>
                                          ))}
                                        </div>

                                      <hr />

                                      <div className="flex w-full justify-end">
                                        <button type="submit" className="linear mt-2 px-5 rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                                          Registrar
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                </Card>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>

    </div>
  );
};

export default Banner;