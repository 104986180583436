import axiosInstance from "../axiosConfig";
import { environment } from "../environment/environment";

interface Task {
  id: number;
  name: string;
  guideline_id: number;
  approver_id: string
  deadline: string;
  created_at?: string;
  uploaders: Array<{
    id: number;
    name: string;
  }>,
  description: string;
}

const task: Task = {
  id: 0,
  name: "",
  guideline_id: 0,
  approver_id: "",
  deadline: "",
  created_at: "",
  uploaders: [{
    id: 0,
    name: "",
  }],
  description: "",
}

export const tasksGet = async (id_guideline: number): Promise<Task[]> => {
  try {
    const res = await axiosInstance.get(`${environment.baseUrl}tasks?filter[guideline]=${id_guideline}&include=uploaders`);

    if (res.statusText !== "OK") {
      return [];
    }

    return res.data;
  } catch (error) {
    return [];
  }
};

export const taskGet = async (id: number): Promise<Task> => {
  try {
    const res = await axiosInstance.get(`${environment.baseUrl}tasks/${id}?&include=uploaders`);

    if (res.statusText !== "OK") {
      return task;
    }

    return res.data;
  } catch (error) {
    return task;
  }
};

export const tasksAssignedGet = async (): Promise<Task[]> => {
  try {
    const res = await axiosInstance.get(`${environment.baseUrl}users/${localStorage.getItem('uuid')}?include=taskUploader`);

    if (res.statusText !== "OK") {
      return [];
    }

    return res.data.task_uploader;
  } catch (error) {
    return [];
  }
};

export const registerTask = async (props: Task): Promise<Boolean> => {
  if (props.name === '') return false;

  try {
    const res = await axiosInstance.post(`${environment.baseUrl}tasks`, props);

    if (res.statusText !== "OK") {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};

export const removeTask = async (task_id: number): Promise<Boolean> => {
  if (task_id === 0) return false;

  try {
    const res = await axiosInstance.delete(`${environment.baseUrl}tasks/${task_id}`);

    if (res.statusText !== "OK") {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};

export const editedTask = async (props: Task): Promise<Boolean> => {
  if (props.name === '') return false;

  try {
    const res = await axiosInstance.put(`${environment.baseUrl}tasks/${props.id}`, props);

    if (res.statusText !== "OK") {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};

interface Evidence {
  id: number;
  name: string;
  approve: boolean;
  description: string;
  tasks_id: Array<number>;
  created_at?: string;
  name_file?: string;
  path_file?: string;
  logs?: Array<{
    id: number;
    tag: string;
    comment: string;
    created_at: string;
    user: {
      name: string;
    }
  }>,
  file?: Array<{
    path: string;
  }>
}

interface EvidenceReg {
  id: number;
  name: string;
  approve: boolean;
  description: string;
  tasks_id: Array<number>;
  created_at?: string;
  name_file?: string;
  path_file?: string;
  logs?: Array<{
    id: number;
    tag: string;
    comment: string;
    created_at: string;
  }>,
  file: File
}

export const evidencesGet = async (task: number): Promise<Evidence[]> => {
  try {
    let res;
    if (task === 0) {
      res = await axiosInstance.get(`${environment.baseUrl}evidences?include=file,logs.user&filter[period]=${localStorage.getItem("period")}`);
    }else{
      res = await axiosInstance.get(`${environment.baseUrl}evidences?filter[task]=${task}&include=logs,file,logs.user&filter[period]=${localStorage.getItem("period")}`);
    }

    if (res.statusText !== "OK") {
      return [];
    }

    return res.data;
  } catch (error) {
    return [];
  }
};

interface RespoonseEvidenceReg {
  status: boolean;
  message: string;
}

export const registerEviedence = async (props: EvidenceReg): Promise<RespoonseEvidenceReg> => {
  if (props.name === '') return { status: false, message: "El nombre no puede estar vacio" };

  try {
    return await axiosInstance.post(`${environment.baseUrl}evidences`, props, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((res) => {
      return res.data;
    }).catch((error) => {
      return error.response.data;
    });
  } catch (error) {
    return { status: false, message: "Error al registrar la evidencia" };
  }
};

interface EvidenceApprove {
  id: number;
  approve: boolean;
  comment: string;
}

export const approveEviedence = async (props: EvidenceApprove): Promise<Boolean> => {
  if (props.comment === '') return false;

  try {
    const res = await axiosInstance.put(`${environment.baseUrl}evidences/approve/${props.id}`, props);

    if (res.statusText !== "OK") {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};
