import { useState, useCallback } from "react";

import {
  editRol,
  rolesGet,
  rolGet,
  registerRol,
  changeRolStatus,
  permissionsGet,
  addPermission,
  removePermission
} from "../services/rolesService";

interface Roles {
  id: number;
  name: string;
  guard_name: string;
}

interface Permissions {
  id: number;
  name: string;
  description: string;
  guard_name: string;
  isAssigned: boolean;
}

export const useRoles = () => {
  const [ roles, setRoles ] = useState<Roles[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const getRoles = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const newUsers = await rolesGet();
      setRoles(newUsers);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { roles: roles, getRoles, loading };
};

export const useRol = () => {
  const [ rol, setRoles ] = useState<Roles>();
  const [ loading, setLoading] = useState<Boolean>(false);

  const [ errors, setError] = useState(null);

  const getRol = useCallback(async (rol: number) => {
    try {
      setLoading(true);
      setError(null);
      const newUsers = await rolGet(rol);
      setRoles(newUsers);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { rol: rol, getRol, loading, errors };
};

export const usePermissions = () => {
  const [ permissions, setPermissions ] = useState<Permissions[]>([]);
  const [ loading, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const getPermissions = useCallback(async (rol: number) => {
    try {
      setLoading(true);
      setError(null);
      const newUsers = await permissionsGet(rol);
      setPermissions(newUsers);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { permissions: permissions, getPermissions, loading };
};

export const useRolRegister = () => {
  const [register, setResponseRR] = useState<Boolean>(false);
  const [loadingRu, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const rolReg = useCallback(async (props: Roles): Promise<Boolean> => {
    try {
      setLoading(true);
      setError(null);
      const userResponse = await registerRol(props);
      setResponseRR(userResponse);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
    return false;
  }, []);

  return { register: register, rolReg, setResponseRR, loadingRu };
};

export const useRolEdit = () => {
  const [ edited, setResponseEU] = useState<Boolean>(false);
  const [ loadingRu, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const userEdit = useCallback(async (props: Roles): Promise<Boolean> => {
    try {
      setLoading(true);
      setError(null);
      const userResponse = await editRol(props);
      setResponseEU(userResponse);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
    return false;
  }, []);

  return { edited: edited, userEdit, setResponseEU, loadingRu };
};

export const useStateRol = () => {
  const [ statusChange, setResponseCS ] = useState<Boolean>(false);
  const [ loadingCS, setLoading ] = useState<Boolean>(false);

  const [ errors , setError ] = useState(null);

  const changeState = useCallback(async (id: number): Promise<Boolean> => {
    try {
      setLoading(true);
      setError(null);
      const userResponse = await changeRolStatus(id);
      setResponseCS(userResponse);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
    return false;
  }, []);

  return { statusChange: statusChange, setResponseCS, changeState, loadingCS, errors };
};

interface PropsPermissions {
  role: number;
  permissions: Array<number>;
}

export const useAddPermissionToRole = () => {
  const [addPermissionRol, setResponseAuth] = useState<Boolean>(false);
  const [loadingAP, setLoading] = useState<Boolean>(false);

  const [error, setError] = useState(null);

  const addPermissionToRole = useCallback(
    async ({  permissions, role }: PropsPermissions): Promise<Boolean> => {
      try {
        setLoading(true);
        setError(null);
        const userResponse = await addPermission({ permissions, role });
        setResponseAuth(userResponse);
      } catch (e: any) {
        setError(e.message);
      } finally {
        // tanto en el try como en el catch
        setLoading(false);
      }

      return false;
    }, []
  );

  return { addPermissionRol: addPermissionRol, error, addPermissionToRole, loadingAP, setResponseAuth };
};

export const useRemovePermissionFromRole = () => {
  const [remove, setResponseRmvP] = useState<Boolean>(false);
  const [loadingRR, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const removePermissionFromRole = useCallback(
    async ({ permissions, role }: PropsPermissions): Promise<Boolean> => {
      try {
        setLoading(true);
        setError(null);
        const userResponse = await removePermission({ permissions, role });
        setResponseRmvP(userResponse);
      } catch (e: any) {
        setError(e.message);
      } finally {
        // tanto en el try como en el catch
        setLoading(false);
      }
      return false;
    }, []
  );

  return { remove: remove, removePermissionFromRole, loadingRR, setResponseRmvP };
};
