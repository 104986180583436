import { useEffect, Fragment, useState, useContext } from 'react';
import InputField from "components/fields/InputField";
import { MdModeEditOutline, MdDelete } from "react-icons/md";
import Swal from 'sweetalert2';
import Select, { MultiValue } from 'react-select';

import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import sortBy from 'lodash/sortBy';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';

import { AbilityContext } from '../../../../casl/abilityContext';

import Card from "components/card";

import {
  useUsers,
  useUserEdit,
  useUserRegister,
  useStateUser
} from '../../../../hooks/useUsers';

import {
  useDependencies
} from '../../../../hooks/useDependencies';

import {
  useRoles
} from '../../../../hooks/useRoles';

interface User {
  id: number;
  name: string;
  email: string;
  company_id: number;
  dependency_id: number;
  rol_id: number;
}

function CheckTable() {
  const { t } = useTranslation();

  const ability = useContext(AbilityContext);

  const { dependencies, getDependencies } = useDependencies()

  useEffect(() => {
    getDependencies();
  }, [getDependencies]);

  const { users, getUsers } = useUsers()

  useEffect(() => {
    ability.can('super_admin', 'users') ? getUsers('all') : getUsers('');
  }, [getUsers, ability]);

  const { roles, getRoles } = useRoles()

  useEffect(() => {
    getRoles();
  }, [getRoles]);



  // Get users list
  useEffect(() => {
    setInitialRecords(sortBy(users, 'id'))
    setRecordsData(sortBy(users, 'id'))
  }, [users])

  const [page, setPage] = useState(1);
  const PAGE_SIZES = [10, 20, 30, 50, 100];
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const [initialRecords, setInitialRecords] = useState(sortBy([], 'id'));
  const [recordsData, setRecordsData] = useState(initialRecords);

  const [hideCols] = useState<any>(['age', 'dob', 'isActive']);

  const [search, setSearch] = useState('');
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: 'id',
    direction: 'asc',
  });

  useEffect(() => {
    setPage(1);
}, [pageSize]);

  useEffect(() => {
    const from = (page - 1) * pageSize;
    const to = from + pageSize;
    setRecordsData([...initialRecords.slice(from, to)]);
  }, [page, pageSize, initialRecords]);

  useEffect(() => {
    setInitialRecords(() => {
      return users.filter((item) => {
        return (
          item.id.toString().includes(search.toLowerCase()) ||
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          item.email.toLowerCase().includes(search.toLowerCase())
        );
      });

    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    const data = sortBy(initialRecords, sortStatus.columnAccessor);
    setInitialRecords(sortStatus.direction === 'desc' ? data.reverse() : data);
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [sortStatus]);

  /** This is for register */

  const { register, userReg, setResponseRU } = useUserRegister()

  useEffect(() => {
    if (register) {
      const toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
      });
      toast.fire({
        icon: 'success',
        title: 'Usuario registrado exitosamente!',
        padding: '10px 20px',
      });

      ability.can('super_admin', 'users') ? getUsers('all') : getUsers('');
    }
    setModal21(false)
    setResponseRU(false)
  }, [register, getUsers, setResponseRU, ability])


  /** This is for edit */

    const { edited, userEdit, setResponseEU } = useUserEdit()

  useEffect(() => {
    if (edited) {
      const toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
      });
      toast.fire({
        icon: 'success',
        title: 'Usuario editado exitosamente!',
        padding: '10px 20px',
      });

      ability.can('super_admin', 'users') ? getUsers('all') : getUsers('');
    }
    setModal21(false)
    setResponseEU(false)
  }, [edited, getUsers, setResponseEU, ability])

  const [formData, setFormData] = useState({
    id: 0,
    name: '',
    email: '',
    dependency_id: 0,
    company_id: 0,
    rol_id: 0
  });

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    isEditing ? userEdit(formData) : userReg(formData);
  }

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [evt.target.name]: evt.target.value });
  };

  const handleSelectChange = (name: string, evt: { value: number; label: string; }) => {
    setFormData({ ...formData, [name]: evt.value });
  };

  const [options_select, setOptionsSelect] = useState<MultiValue<{ value: number; label: string; }>>([])

  useEffect(() => {
    const list = dependencies.map(objeto => ({
      value: objeto.id,
      label: objeto.name
    }));

    setOptionsSelect(list)
  }, [dependencies])


  const [role_options, setRoleSelect] = useState<MultiValue<{ value: number; label: string; }>>([])

  useEffect(() => {
    const list = roles.map(objeto => ({
      value: objeto.id,
      label: objeto.name
    }));

    setRoleSelect(list)
  }, [roles])

  const [modal21, setModal21] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleSave = () => {
    // Logic for saving the saved title
    setFormData({
      id: 0,
      name: '',
      email: '', 
      dependency_id: 0,
      company_id: 0,
      rol_id: 0
    })

    setIsEditing(false);
    setModal21(true)
  };

  const handleEdit = (user: User) => {

    // Logic for saving the saved title
    setFormData({
      id: user.id,
      name: user.name,
      email: user.email, 
      dependency_id: user.dependency_id,
      company_id: user.company_id,
      rol_id: user.rol_id
    })

    setIsEditing(true);
    setModal21(true)
  };

  /* Processo for chage status */
  const { statusChange, setResponseCS, changeState } = useStateUser()

  const changeStatus = (id: number) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'linear mt-2 px-5 rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200',
        cancelButton: 'btn btn-dark ltr:mr-3 rtl:ml-3',
        popup: 'sweet-alerts',
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: 'Eliminar usuario',
        text: "¿Está seguro de ejecutar esta acción?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        padding: '2em',
      })
      .then((result) => {
        if (result.value) {
          //execute the Hook to perform the request
          changeState(id)
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire('Cancelado', 'No se ejecutó ninguna acción', 'error');
        }
      });
  }

  useEffect(() => {
    if (statusChange) {
      const toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
      });
      toast.fire({
        icon: 'info',
        title: '¡Eliminado exitosamente!',
        padding: '10px 20px',
      });

      setResponseCS(false)
      ability.can('super_admin', 'users') ? getUsers('all') : getUsers('');
    }
}, [statusChange, setResponseCS, getUsers, ability])

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className="relative flex items-center justify-between pt-4">
        <button onClick={handleSave} className="linear mt-2 w-1/6 rounded-xl bg-brand-500 py-[10px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Nueva
        </button>
        
        <Transition appear show={modal21} as={Fragment}>
            <Dialog as="div" open={modal21} onClose={() => setModal21(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0" />
                </Transition.Child>
                <div className="fixed inset-0 z-[999] bg-[black]/60">
                    <div className="flex min-h-screen items-start justify-center px-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="panel my-8 w-full max-w-xl overflow-hidden  rounded-lg border-0 p-0 text-black dark:text-white-dark">
                                <Card extra={"w-full h-full p-3"}>

                                <div className="flex items-center justify-between bg-[#fbfbfb] px-5 py-3 dark:bg-[#121c2c]">
                                    <h5 className="text-lg font-bold">
                                    { isEditing? 'Editar' : 'Registrar' } - Usuario
                                    </h5>
                                    <button onClick={() => setModal21(false)} type="button" className="text-white-dark hover:text-dark">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                        </svg>
                                    </button>
                                </div>

                                  <div className="mt-2 mb-8 w-full">
                                    <form className="space-y-5" onSubmit={submitForm}>

                                      <InputField
                                        value={formData.name}
                                        variant="auth"
                                        extra="mb-3"
                                        label="Nombre*"
                                        placeholder="User..."
                                        id="name"
                                        type="text"
                                        name="name"
                                        onChange={handleChange}
                                      />

                                      <InputField
                                        value={formData.email}
                                        variant="auth"
                                        extra="mb-3"
                                        label="Correo electrónico*"
                                        placeholder="user@godi.com"
                                        id="email"
                                        type="email"
                                        name="email"
                                        onChange={handleChange}
                                      />

                                      <div className='mb-3'>
                                        <label
                                          className={`text-sm text-navy-700 dark:text-white`}
                                        >Dependencia*</label>
                                        <Select
                                          options={options_select}
                                          value={options_select.filter(item => item.value === formData.dependency_id)}
                                          placeholder="Seleccionar Dependencia*"
                                          id="node_structure_id"
                                          name="node_structure_id"
                                          onChange={(e:any) => handleSelectChange('dependency_id', e)}
                                        />
                                      </div>

                                      <div className='mb-3'>
                                        <label
                                          className={`text-sm text-navy-700 dark:text-white`}
                                        >Rol*</label>
                                        <Select
                                          options={role_options}
                                          value={role_options.filter(item => item.value === formData.rol_id)}
                                          placeholder="Seleccionar Rol*"
                                          id="node_structure_id"
                                          name="node_structure_id"
                                          onChange={(e:any) => handleSelectChange('rol_id', e)}
                                        />
                                      </div>

                                      <div className="flex w-full justify-end">
                                        <button type="submit" className="linear mt-2 px-5 rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                                          { isEditing? 'Guardar cambios' : 'Registrar' }
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                </Card>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>

        <div className="flex gap-5 ltr:ml-auto rtl:mr-auto">
          <div className="text-right">
            <InputField
              value={search}
              variant="auth"
              extra="mb-3 mr-5 w-full"
              label=""
              placeholder="Buscar..."
              id="name"
              type="text"
              name="name"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      </header>

      <div className="datatables">
        <DataTable
            striped={true} // Add striped prop
            horizontalSpacing="md" // Add horizontalSpacing prop (adjust the value as needed)
            verticalSpacing="md" // Add verticalSpacing prop (adjust the value as needed)
            fontSize={16}
            className="whitespace-nowrap table-hover"
            records={recordsData}
            columns={[
                {
                  accessor: 'id',
                  title: 'ID',
                  sortable: true,
                  hidden: hideCols.includes('id'),
                  render: ({id}, index) => <div className={`capitalize`}>{ index+1 }</div>,
                },
                {
                  accessor: 'name',
                  title: 'Nombre',
                  sortable: true,
                  hidden: hideCols.includes('first_name'),
                  render: ({ name }) => <div className={`uppercase`}>{ name }</div>,
                },
                {
                  accessor: 'email',
                  title: 'Correo electrónico',
                  sortable: true,
                  hidden: hideCols.includes('email'),
                  render: ({ email }) => <div className={``}>{ email }</div>,
                },
                {
                  accessor: 'dependency',
                  title: 'Dependencia',
                  sortable: true,
                  hidden: hideCols.includes('dependency'),
                  render: ({ dependency }) => <div className={``}>
                    {dependency ? (
                      dependency.name
                    ):(<></>)}
                  </div>,
                },
                {
                  accessor: 'is_active',
                  title: 'Acciones',
                  sortable: true,
                  hidden: hideCols.includes('is_active'),
                  render: (row) => <div className={`capitalize`}>
                    <div className="mt-3 flex items-center gap-3">
                      <div onClick={() => handleEdit(row)} className="cursor-pointer mr-4 flex items-center justify-center text-gray-600 dark:text-white">
                        <MdModeEditOutline />
                      </div>
                      <div onClick={() => changeStatus(row.id)} className="cursor-pointer mr-4 flex items-center justify-center text-gray-600 dark:text-white">
                        <MdDelete />
                      </div>
                    </div>
                  </div>,
                },
            ]}
            highlightOnHover
            totalRecords={initialRecords.length}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            sortStatus={sortStatus}
            onSortStatusChange={setSortStatus}
            minHeight={200}
            paginationText={({ from, to, totalRecords }) => `${t('showing')}  ${from} ${t('to')} ${to} ${t('of')} ${totalRecords} ${t('results')}`}
        />
      </div>

    </Card>
  );
}

export default CheckTable;
