import React, { useEffect, useState } from "react";

import Card from "components/card";
import Parent from "./Parent";
import Box from "./Card";
import Breadcrumb from "./Breadcrumb";
import EvidenceRegister from './EvidenceRegister';

import GuidelinesLogo from '../../../../assets/images/guidelines.png';

import { FiSearch } from "react-icons/fi";

import {
  MdDragIndicator,
  MdOutlineArrowBack,
  MdDriveFolderUpload,
} from "react-icons/md";

// Hooks
import {
  useStructures,
  useLevels
} from '../../../../hooks/useStructures'

import {
  useGuidelinesParent
} from '../../../../hooks/useGuidelines';

import {
  useTasks,
  useEvidences
} from '../../../../hooks/useTasks';

interface LevelsData {
  id: number;
  level_num: number;
  id_parent?: number;
  name: string; 
  path_file: string;
  code: string;
}

const Banner = () => {
  const [name_parent, setNameParent] = useState<string>('GOBIERNO DIGITAL')
  const [parent, setParent] = useState<number>(null)

  const { tasks, getTasks, setTasks } = useTasks()
  const { getLevels, levels } = useLevels()
  const { evidences, getEvidences } = useEvidences()
  const { getStructures, structure } = useStructures()

  const { guidelines, getGuidelinesParent, setGuidelines } = useGuidelinesParent()


  useEffect(()=>{
    getStructures(parent)
  }, [getStructures, parent])

  useEffect(()=>{
    getLevels( Number(localStorage.getItem('period')) )
  }, [getLevels])

  const [level_activated, setLevelActivate] = useState<LevelsData>()

  const [levelsBreadcrumb, setLevesBreadcrumb] = useState<LevelsData[]>([])

  useEffect(()=>{
    setLevelActivate( levels.filter(e => e.level_num === 1)[0] )
  }, [levels])

  const selectNewParent = (e: number) => {
    setNameParent(structure.filter(s => s.id === e)[0].name)
    setParent(e)

    setLevelActivate( levels.filter(e => e.level_num === level_activated.level_num+1 )[0] )

    if ( levels.filter(e => e.level_num === level_activated.level_num+1 )[0] === undefined ) {
      getGuidelinesParent(e)
    }
  }

  useEffect(()=>{
    if (level_activated) {
      level_activated.id_parent = parent;
      setLevesBreadcrumb([...levelsBreadcrumb, level_activated ]);
    }
  }, [level_activated, parent])


  const setPositon = (level: LevelsData) => {
    setParent( level.id_parent )
    setLevesBreadcrumb( [...levels.filter(e => e.level_num < level.level_num)] );
    setLevelActivate( levels.filter(e => e.level_num === level.level_num )[0] );

    setNameParent( (level.id_parent != null) ? structure.filter(s => s.id === level.id_parent)[0].name : 'GOBIERNO DIGITAL' )
  }

  const selectTaskGuidelines = (e: number) => {
    getTasks(e)
  }

  const [ tabEvidences, setTabE ] = useState('')

  const [ task_id, setTaskId ] = useState(0)

  const selectEvidencesTask = (e: number) => {
    setTaskId(e)
    setTabE('evidences')
    getEvidences(e)
  }

  const handleChangeEvidences = () => {
    getEvidences(task_id)
  }

  const setTabEvidences = () => {
    setTabE('')
  }

  const clearUrl = (url: string) => {
    return (url).replace('public/', '');
  }

  const [filteredStructure, setFilteredStructure] = useState([]);

  useEffect(()=>{
    setFilteredStructure(structure);
  },[structure, parent])

  const [search, setSearch] = useState('');
  useEffect(() => {
    setFilteredStructure( () => {
      return structure.filter((item) => {
        return (
          item.id.toString().includes(search.toLowerCase()) ||
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          item.percentage_assigned.toString().includes(search.toLowerCase())
        );
      })
    })
  }, [search, structure]);

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit', // 'numeric' will give you the month as a number with leading zeros
    day: '2-digit',   // 'numeric' will give you the day as a number with leading zeros
  };

  return (
    <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
      <div className="w-full">

        {/* Print parent component */}
        <Breadcrumb levels={levelsBreadcrumb} onclick={(e) => {
          setPositon(e)
          setGuidelines([])
          setTasks([])
          setTabE('')
        }} />

        {/* Print parent component */}
        <Parent name={name_parent} />
        
        <div className="border-t border-gray-300 my-4"></div>

        {level_activated ? (
          <div className="flex w-full items-end justify-end">
            <p className="text-lg font-medium">
              { level_activated.name }
            </p>
          </div>
        ) : (
          <></>
        )}

        { !(tasks.length>0) && guidelines.length > 0 ? (
          <div className="flex w-full items-end justify-end">
            <p className="text-lg font-medium">
              {localStorage.getItem("type_guideline") === 'true' ? 'Artefactos' : 'Lineamientos'}
            </p>
          </div>
        ) :(
          <></>
        )}

        { tabEvidences === '' && tasks.length > 0 ? (
          <div className="flex w-full items-end justify-end">
            <p className="text-lg font-medium">
              Tareas
            </p>
          </div>
        ) :(
          <></>
        )}

        { tabEvidences === 'evidences' ? (
          <div className="flex w-full items-end justify-end">
            <p className="text-lg font-medium">
              Evidencias
            </p>
          </div>
        ) :(
          <></>
        )}

        <div className="w-full flex justify-end">

          <div className="mt-[3px] w-[200px] flex h-[61px] flex-grow justify-around rounded-full px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[220px] md:flex-grow-0 md:gap-1 xl:w-[220px] xl:gap-2">
            <div className=" flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[200px]">
              <p className="pl-3 pr-2 text-xl">
                <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
              </p>
              <input
                type="text"
                value={search}
                placeholder="Buscar..."
                onChange={(e) => setSearch(e.target.value)}
                className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-5 gap-4 px-2">
          {/* Print childre´s component */}
          {/* { level_activated && structure.filter(e => e.parent_id === parent).map((col, i)=>{
            return <Box code={level_activated.code} key={i} path_file={clearUrl(level_activated.path_file)} percentage_assigned={col.percentage_assigned} percentage_progress={col.percentage_progress} name={col.name} id={col.id} onclick={(e) => selectNewParent(e)} />
          })} */}

          {level_activated && filteredStructure
            .filter((item) => item.parent_id === parent)
              .map((col, i) => {
                return <Box
                  code={level_activated.code}
                  key={i}
                  path_file={clearUrl(level_activated.path_file)}
                  percentage_assigned={col.percentage_assigned}
                  percentage_progress={col.percentage_progress}
                  name={col.name}
                  id={col.id}
                  onclick={(e) => selectNewParent(e)}
                />
          })}

          { !(tasks.length>0) && guidelines.map((col, i)=>{
            return <Box key={i} code={col.code} file={GuidelinesLogo} percentage_assigned={col.percentage_assigned} percentage_progress={col.percentage_progress} name={col.name} id={col.id} onclick={(e) => selectTaskGuidelines(e)} />
          })}
        </div>

          { tabEvidences === '' && tasks.length > 0 ? (
            <div className="datatables">
              <div className="h-full w-full">

              <button
                className="flex h-9 w-9 items-center justify-center rounded-full bg-indigo-100 dark:bg-indigo-100 dark:bg-white/5"
                onClick={() => {
                  setTasks([])
                }}
              >
                <MdOutlineArrowBack className="h-6 w-6 text-gray-800 dark:text-white" />
              </button>
      
                <div className="mt-5 flex items-center justify-between p-2">
                  <div className=" flex items-center justify-center w-1/4 border-2 h-10">
                    <h6 className='text-sm font-semibold'>
                      TAREA
                    </h6>
                  </div>
                  <div className="flex items-center justify-center w-1/4 border-2 h-10">
                    <h6 className='text-sm font-semibold'>
                      USUARIOS ASIGNADOS
                    </h6>
                  </div>
                  <div className="flex items-center justify-center w-1/4 border-2 h-10">
                    <h6 className='text-sm font-semibold'>
                      FECHA LIMITE
                    </h6>
                  </div>
                  <div className="flex items-center justify-center w-1/4 border-2 h-10">
                    <h6 className='text-sm font-semibold'>
                      FECHA CREACIÓN
                    </h6>
                  </div>
                  <div className="flex items-center justify-center w-1/4 border-2 h-10">
                    <h6 className='text-sm font-semibold'>
                      EVIDENCIAS
                    </h6>
                  </div>
                </div>
      
                {tasks.map((task, i) => (
                  <div key={`task-${i}`} className="mt-5 flex items-center justify-between p-2">
                    <div className="flex items-start justify-start gap-2 w-1/4">
                      <MdDragIndicator className="h-6 w-6 text-navy-700 dark:text-white" />
                      <p className="text-base font-bold text-navy-700 dark:text-white">
                        {task.name}
                      </p>
                    </div>
                    <div className="flex items-center justify-center w-1/4">
                      <ul className="list-disc">
                        {task.uploaders.map((uploader, u)=> (
                          <li key={`u-${u}`}><p className='text-sm'>{uploader.name}</p></li>
                        ))}
                      </ul>
                    </div>
                    <div className="flex items-center justify-center w-1/4">
                      <span className="font-normal">{
                        new Intl.DateTimeFormat('en-US', options).format(Date.parse(task.deadline))
                      }</span>
                    </div>
                    <div className="flex items-center justify-center w-1/4">
                      <span className="font-normal">{
                        new Intl.DateTimeFormat('en-US', options).format(Date.parse(task.created_at))
                      }</span>
                    </div>
                    <div className="flex items-center justify-center w-1/4">
                      <button
                        className="capitalize dark:hover:text-white w-10 rounded-sm bg-gray-300 p-2 font-medium text-white transition duration-200 hover:bg-gray-700 active:bg-gray-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                        onClick={() => selectEvidencesTask(task.id)}
                      >
                        <MdDriveFolderUpload className="h-6 w-6 text-navy-700 hover:text-white" />
                      </button>
                    </div>
                  </div>
                ))}
      
              </div>
            </div>
          ): (
            <></>
          )}

          { tabEvidences === 'evidences' ? (
            <EvidenceRegister task_id={task_id} evidences={evidences} onchange={handleChangeEvidences} setTab={setTabEvidences} />
          ) : (
            <></>
          )}
          
      </div>
    </Card>
  );
};

export default Banner;
