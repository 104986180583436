import { useEffect, useState, useCallback } from 'react';
import Chart from 'react-apexcharts';

interface Data {
  series: [
    {
      name: string;
      data: number[];
      ids: number[];
    },
    {
      name: string;
      data: number[];
      ids: number[];
    },
  ],
}

interface Props {
  categories: string[];
  data: Data;
  onclick?: (id: number) => void;
}

function RadarChart({ categories, data, onclick }: Props ) {

  const [radar, setRadarType] = useState<boolean>(true);

  const [chartData, setChartData] = useState<Data>();
  
  useEffect(()=>{
    if ( data.series[0].data.length > 0 ) {
      setChartData(data)
    }
  }, [data])

  const [radarChartOptions, setRfarChartOptions] = useState<ApexCharts.ApexOptions>();

  const handlePointClick = useCallback((
    event: MouseEvent,
    chartContext: any,
    config: any) => {
      if (config && config.dataPointIndex >= 0 ) {
        var clickedId = data.series[0].ids[config.dataPointIndex];
        onclick( clickedId )
      }
  }, [data, onclick]);

  useEffect(()=>{
    setRfarChartOptions({
      chart: {
        toolbar: {
          show: true,
        },
        events: {
          click: handlePointClick, // Manejar el evento de clic en el gráfico
        },
        type: radar ? "radar" : "bar",
      },
      // Otras opciones del gráfico radar
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: undefined
        },
        onDatasetHover: {
        },
        theme: "dark",
      },
      xaxis: {
        categories: categories,
        labels: {
          show: true,
          style: {
            colors: "#A3AED0",
            fontSize: "14px",
            fontWeight: "500",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
        labels: {
          show: true,
          style: {
            colors: "#CBD5E0",
            fontSize: "14px",
          },
        },
      },
      grid: {
        show: false,
        strokeDashArray: 5,
        yaxis: {
          lines: {
            show: true,
          },
        },
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          type: "vertical",
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          colorStops: [
            [
              {
                offset: 0,
                color: "#4318FF",
                opacity: 1,
              },
              {
                offset: 100,
                color: "rgba(67, 24, 255, 1)",
                opacity: 0.28,
              },
            ],
            [
              {
                offset: 0,
                color: "#13D897",
                opacity: 1,
              },
              {
                offset: 100,
                color: "rgba(218, 247, 166, 1)",
                opacity: 0.28,
              },
            ],
          ],
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          borderRadius: 3,
          columnWidth: "40px",
        },
      },
    })
  }, [chartData, handlePointClick, categories, radar]);

  return (
    chartData ? (
      <>
        <div className='mb-3'>
          <select
            onChange={(e) => {setRadarType(!radar)}}
            className={`mt-2 flex w-1/4 items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none`}
            >
              <option value="true">Gráfico de Radar</option>
              <option value="false">Gráfico de Barras</option>
          </select>
        </div>
    
        <Chart
          series={chartData.series}
          type={radar ? "radar" : "bar"}
          height={500}
          options={radarChartOptions}
        />
      </>
    ):(<></>)
  );
}

export default RadarChart;
