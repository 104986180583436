import axiosInstance from "../axiosConfig";
import { environment } from "../environment/environment";

interface Period {
  id: number;
  name: string;
  period: string;
  can_modify_structure: boolean;
  nodes: [
    {
      auto_diagnostic: number;
      percentage_assigned: number;
      percentage_progress: number;
      name: string;
      parent_id: number; 
    }
  ]
}

export const periodsGet = async (): Promise<Period[]> => {
  try {
    const res = await axiosInstance.get(`${environment.baseUrl}periods`);

    if (res.statusText !== "OK") {
      return [];
    }

    return res.data;
  } catch (error) {
    return [];
  }
};

export const periodGet = async (): Promise<Period> => {
  try {
    const res = await axiosInstance.get(`${environment.baseUrl}periods/${localStorage.getItem('period')}`);

    if (res.statusText !== "OK") {
      return new Promise((resolve, reject) => reject(res.statusText));
    }

    return res.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject('Error'));
  }
};

interface PeriodRegister {
  valid: {
    id: number;
  }
}

export const periodRegister = async (): Promise<PeriodRegister> => {
  try {
    const res = await axiosInstance.post(`${environment.baseUrl}periods`);

    if (res.statusText !== "OK") {
      return new Promise((resolve, reject) => reject(res.statusText));
    }

    return res.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject('Error'));
  }
};

interface Permissions {
  period: number;
}

export const periodsUserGet = async (): Promise<Permissions> => {
  try {
    const res = await axiosInstance
      .get(
        `${environment.baseUrl}users/periods/${localStorage.getItem('uuid')}`,
      ).then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response.data;
      });

    return res;
  } catch (error) {
    return { period: 0 };
  }
};

export const implementStructure = async (id_period: number): Promise<Boolean> => {
  try {
    const res = await axiosInstance
      .get(
        `${environment.baseUrl}periods/clone/${id_period}`,
      ).then((response) => {
        
        if (response.statusText !== "OK") {
          return false;
        }

        return true;

      })
      .catch((error) => {
        return false;
      });

    if (!res) {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};
