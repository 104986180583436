import { useEffect, Fragment, useState } from 'react';
import InputField from "components/fields/InputField";
import TextareaField from "components/fields/TextareaField";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import sortBy from 'lodash/sortBy';
import { Dialog, Transition } from '@headlessui/react';
import moment from 'moment';

import { DataTable, DataTableSortStatus } from 'mantine-datatable';

import Card from "components/card";

import {
  MdOutlineInfo,
  MdFilePresent,
  MdCheckCircle,
  MdOutlineError,
  MdDragIndicator,
  MdOutlineLocalPolice,
} from "react-icons/md";

import {
  useEvidences,
  useEvidenceApprove
} from '../../../../hooks/useTasks';
import { environment } from 'environment/environment';

function CheckTable() {

  const [modal22, setModal22] = useState(false);

  const [logs_evidence, setLogsevidence] = useState([]);

  const { evidences, getEvidences } = useEvidences()

  useEffect(() => {
    getEvidences(0);
  }, [getEvidences]);

  // Get users list
  useEffect(() => {
    setInitialRecords(sortBy(evidences, 'id'))
    setRecordsData(sortBy(evidences, 'id'))
  }, [evidences])

  const [page, setPage] = useState(1);
  const PAGE_SIZES = [10, 20, 30, 50, 100];
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const [initialRecords, setInitialRecords] = useState(sortBy(evidences, 'id'));
  const [recordsData, setRecordsData] = useState(initialRecords);

  const [search, setSearch] = useState('');

  useEffect(() => {
    setInitialRecords(() => {
      return evidences.filter((item) => {
        return (
          item.id?.toString().includes(search.toLowerCase()) ||
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          item.description.toLowerCase().includes(search.toLowerCase()) ||
          item.created_at.toLowerCase().includes(search.toLowerCase())
        );
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    const from = (page - 1) * pageSize;
    const to = from + pageSize;
    setRecordsData([...initialRecords.slice(from, to)]);
  }, [page, pageSize, initialRecords]);

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: 'id',
    direction: 'asc',
  });

  const [hideCols, setHideCols] = useState<any>(['age', 'dob', 'isActive']);

  const showHideColumns = (col: any, value: any) => {
    if (hideCols.includes(col)) {
        setHideCols((col: any) => hideCols.filter((d: any) => d !== col));
    } else {
        setHideCols([...hideCols, col]);
    }
  };

  useEffect(() => {
    const data = sortBy(initialRecords, sortStatus.columnAccessor);
    setInitialRecords(sortStatus.direction === 'desc' ? data.reverse() : data);
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortStatus]);

  const { approve, evidenceApprove, setResponseEA } = useEvidenceApprove()

  useEffect(() => {
    if (approve) {
      const toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
      });
      toast.fire({
        icon: 'success',
        title: `¡${localStorage.getItem("type_guideline") === 'true' ? 'Artefacto' : 'Lineamiento'} registrado exitosamente!`,
        padding: '10px 20px',
      });

      getEvidences(0);
    }
    setModal21(false)
    setResponseEA(false)
}, [approve, getEvidences, setResponseEA])

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    evidenceApprove(formData);
  }

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [evt.target.name]: evt.target.value === "true" ? true : false });
  };

  const handleChangeTextarea = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormData({ ...formData, [evt.target.name]: evt.target.value });
  };

  const [modal21, setModal21] = useState(false);

  const [formData, setFormData] = useState({
    id: 0,
    approve: false,
    comment: '',
  });

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit', // 'numeric' will give you the month as a number with leading zeros
    day: '2-digit',   // 'numeric' will give you the day as a number with leading zeros
  };

  const formatDate = (date: Date) => {
    return `Fecha y hora: ${moment(date).format('DD/MM/YYYY HH:mm:ss')}`;
  }

  const clearUrl = (url: string) => {
    return (url).replace('public/', '');
  }

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className="relative flex items-center justify-between pt-4">
        
        <Transition appear show={modal21} as={Fragment}>
            <Dialog as="div" open={modal21} onClose={() => setModal21(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0" />
                </Transition.Child>
                <div className="fixed inset-0 z-[999] bg-[black]/60">
                    <div className="flex min-h-screen items-start justify-center px-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="panel my-8 w-full max-w-xl overflow-hidden  rounded-lg border-0 p-0 text-black dark:text-white-dark">
                                <Card extra={"w-full h-full p-3"}>

                                <div className="flex items-center justify-between bg-[#fbfbfb] px-5 py-3 dark:bg-[#121c2c]">
                                    <h5 className="text-lg font-bold">Calificar evidencia</h5>
                                    <button onClick={() => setModal21(false)} type="button" className="text-white-dark hover:text-dark">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                        </svg>
                                    </button>
                                </div>

                                  <div className="mt-2 mb-8 w-full">
                                    <form className="space-y-5" onSubmit={submitForm}>
                                      
                                      <div className="h-full w-full">
                                        <div className="mt-2 flex items-center justify-between p-2">
                                          <div className="flex items-center justify-center gap-2">
                                            <input type="radio" value="true" id='aprobar' name="approve" className='defaultCheckbox relative flex h-[20px] min-h-[20px] w-[20px] min-w-[20px] appearance-none items-center 
                                              justify-center rounded-md border border-gray-300 text-white/0 outline-none transition duration-[0.2s]
                                            checked:text-white hover:cursor-pointer dark:border-white/10 checked:border-none checked:bg-navy-500 dark:checked:bg-navy-400'
                                              onChange={handleChange}
                                            />
                                            <label htmlFor='aprobar' className="text-base font-bold text-navy-700 dark:text-white">
                                              Aprobar
                                            </label>
                                          </div>
                                          <div>
                                            <MdDragIndicator className="h-6 w-6 text-navy-700 dark:text-white" />
                                          </div>
                                        </div>

                                        <div className="mt-2 flex items-center justify-between p-2">
                                          <div className="flex items-center justify-center gap-2">
                                          <input id="rechazar" value="false" type="radio" name="approve" className='defaultCheckbox relative flex h-[20px] min-h-[20px] w-[20px] min-w-[20px] appearance-none items-center 
                                            justify-center rounded-md border border-gray-300 text-white/0 outline-none transition duration-[0.2s]
                                          checked:text-white hover:cursor-pointer dark:border-white/10 checked:border-none checked:bg-navy-500 dark:checked:bg-navy-400'
                                            onChange={handleChange}
                                            />
                                            <label htmlFor='rechazar' className="text-base font-bold text-navy-700 dark:text-white">
                                              Rechazar  
                                            </label>
                                          </div>
                                          <div>
                                            <MdDragIndicator className="h-6 w-6 text-navy-700 dark:text-white" />
                                          </div>
                                        </div>
                                      </div>

                                      <TextareaField
                                        value={formData.comment}
                                        variant="auth"
                                        extra="mb-3"
                                        label="Descrición*"
                                        placeholder="Descripción"
                                        id="comment"
                                        type="text"
                                        name="comment"
                                        onChange={handleChangeTextarea}
                                      />

                                      <div className="flex w-full justify-end">
                                        <button type="submit" className="linear mt-2 px-5 rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                                          Registrar
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                </Card>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>

        <Transition appear show={modal22} as={Fragment}>
          <Dialog as="div" open={modal22} onClose={() => setModal22(false)}>
              <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
              >
                  <div className="fixed inset-0" />
              </Transition.Child>
              <div className="fixed inset-0 z-[999] bg-[black]/60">
                  <div className="flex min-h-screen items-start justify-center px-4">
                      <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                      >
                          <Dialog.Panel className="panel my-8 w-full max-w-xl overflow-hidden  rounded-lg border-0 p-0 text-black dark:text-white-dark">
                              <Card extra={"w-full h-full p-3"}>

                                <div className="flex items-center justify-between bg-[#fbfbfb] px-5 py-3 dark:bg-[#121c2c]">
                                  <h5 className="text-lg font-bold">Bitacora</h5>
                                  <button onClick={() => setModal22(false)} type="button" className="text-white-dark hover:text-dark">
                                      <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                      >
                                          <line x1="18" y1="6" x2="6" y2="18"></line>
                                          <line x1="6" y1="6" x2="18" y2="18"></line>
                                      </svg>
                                  </button>
                                </div>
                                <div className="mt-2 mb-8 w-full h-96 overflow-auto">

                                  <div className="w-full mx-auto mt-10 relative">

                                    <div className="timeline">
                                      <div className="timeline__group">
                                        <span className="timeline__year time" aria-hidden="true">Linea de tiempo</span>
                                        <div className="timeline__cards">


                                          {logs_evidence.map((log, i) => (
                                            <div key={i} className="timeline__card card">
                                              <header className="card__header">
                                                <time className="time" dateTime={log.created_at}>
                                                  <span className="time__day"> {formatDate(log.created_at)}</span>
                                                </time>
                                              </header>
                                              <div className="card__content">
                                                {log.comment ? (
                                                  <p>Comentario: {log.comment}</p>
                                                ):(<></>)}
                                                
                                                <div className='flex justify-between'>
                                                  <span className="text-blue-500 font-bold">{log.tag}</span>

                                                  <span className="bg-navy-800 rounded-xl px-2 py-1 text-sm font-bold text-white">
                                                    {log.user.name}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                          
                                        </div>
                                      </div>
                                    </div>

                                  </div>        

                                  <div className="flex w-full justify-end">
                                    <button
                                      onClick={() => setModal22(false)}
                                      className="linear mt-2 px-5 rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                                    >
                                      Cerrar
                                    </button>
                                  </div>
                                </div>
                            </Card>
                          </Dialog.Panel>
                      </Transition.Child>
                  </div>
              </div>
          </Dialog>
        </Transition>

        <div className="flex gap-5 ltr:ml-auto rtl:mr-auto">
          <div className="text-right">
            <InputField
              value={search}
              variant="auth"
              extra="mb-3 mr-5 w-full"
              label=""
              placeholder="Buscar..."
              id="name"
              type="text"
              name="name"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      </header>

      <div className="datatables">
        <div className="h-full w-full">
          <DataTable
              className="whitespace-nowrap table-hover"
              records={recordsData}
              columns={[
                  {
                      accessor: 'name',
                      title: 'TAREA',
                      sortable: true,
                      hidden: hideCols.includes('name'),
                      render: ({ name }) => <div className={`capitalize`}>
                        <p className="text-base font-bold text-navy-700 dark:text-white">
                          { name }
                        </p>
                      </div>,
                  },
                  {
                      accessor: 'description',
                      title: 'DESCRIPCIÓN',
                      sortable: true,
                      hidden: hideCols.includes('description'),
                      render: ({ description }) => <div className={''}>{ description }</div>,
                  },
                  {
                      accessor: 'path_file',
                      title: 'EVIDENCIA',
                      sortable: true,
                      hidden: hideCols.includes('path_file'),
                      render: ({ file }) => <div className={`capitalize`}>
                        {file.map((item, i) => {
                          return (
                            <Link
                              key={i}
                              className="flex h-9 w-9 items-center justify-center rounded-full dark:bg-white/5"
                              target='_blank'
                              to={ environment.storageUrl+clearUrl(item.path) }
                            >
                              <MdFilePresent className="h-6 w-6 text-gray-800 dark:text-white" />
                            </Link>
                          )
                        })}
                      </div>,
                  },
                  {
                      accessor: 'companie',
                      title: 'ESTADO',
                      sortable: true,
                      hidden: hideCols.includes('companie'),
                      render: ({ approve }) => <div className={`capitalize`}>
                          {approve ? (
                            <>
                              <MdCheckCircle className="text-green-500 me-1 dark:text-green-300" />
                              <p className='text-sm'>Aprobado</p>
                            </>
                          ) : (
                            <>
                              <MdOutlineError className="text-amber-500 me-1 dark:text-amber-300" />
                              <p className='text-sm'>No aprobado</p>
                            </>
                          )}
                      </div>,
                  },
                  {
                      accessor: 'grade',
                      title: 'FECHA CARGA',
                      sortable: true,
                      hidden: hideCols.includes('grade'),
                      render: ({ created_at }) => <div className={`capitalize`}>{ 
                        new Intl.DateTimeFormat('en-US', options).format(Date.parse(created_at))
                      }</div>,
                  },
                  {
                      accessor: 'actions',
                      title: 'CALIFICAR',
                      sortable: true,
                      hidden: hideCols.includes('actions'),
                      render: ({ id }) => <div className={`capitalize`}>
                          <button
                          onClick={() => {
                            formData.id = id
                            setModal21(true)
                          }}
                          className="flex h-9 w-9 items-center justify-center rounded-full dark:bg-white/5"
                        >
                          <MdOutlineLocalPolice className="h-6 w-6 text-gray-800 dark:text-white" />
                        </button>
                      </div>,
                  },
                  {
                    accessor: 'logs',
                    title: 'BITACORA',
                    sortable: true,
                    hidden: hideCols.includes('logs'),
                    render: ({ logs }) => <div className={`capitalize`}>
                      <button onClick={() => {
                        setLogsevidence(logs)
                        setModal22(true)
                      }}
                      disabled={logs.length === 0}
                      className="flex h-9 w-9 items-center justify-center rounded-full bg-indigo-100 dark:bg-indigo-100 dark:bg-white/5">
                        <MdOutlineInfo className="h-6 w-6 text-gray-800 dark:text-white" />
                      </button>
                    </div>,
                  },
              ]}
              highlightOnHover
              totalRecords={initialRecords.length}
              recordsPerPage={pageSize}
              page={page}
              onPageChange={(p) => setPage(p)}
              recordsPerPageOptions={PAGE_SIZES}
              onRecordsPerPageChange={setPageSize}
              sortStatus={sortStatus}
              onSortStatusChange={setSortStatus}
              minHeight={200}
              paginationText={({ from, to, totalRecords }) => `Mostrando  ${from} a ${to} de ${totalRecords} resultados`}
          />
        </div>
      </div>
    </Card>
  );
}

export default CheckTable;
