import InputField from "components/fields/InputField";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';

import { useLogin } from '../../hooks/useAuth';

export default function SignIn() {

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    user: '',
    pass: ''
  });
  
  const { login, setResponseAuth, getUserAuth } = useLogin(formData)
  
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [evt.target.name]: evt.target.value });
  };

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    getUserAuth(formData)
  }

  useEffect(()=>{
    //if ( localStorage.getItem("uuid") > '' ) {
      //navigate('/admin/default');
    //}
  }, [navigate])

  useEffect(() => {
    const toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
    });

    if (login) {
      if (login?.token) {
        localStorage.setItem('authToken', login.token)

        localStorage.setItem('uuid', login.user.id)

        localStorage.setItem('email', login.user.email)
        localStorage.setItem('name', login.user.name)

        if (login.period !== null) {
          localStorage.setItem('period', String(login.period))
        }

        if (login.company.logo !== null) {
          localStorage.setItem('company', String(login.company.id))
          localStorage.setItem('logo', login.company.logo.path)
        }else{
          localStorage.setItem('logo', 'null')
        }

        if (login.company.type_guideline !== null) {
          if ( login.company.type_guideline ) {
            localStorage.setItem('type_guideline', 'true')
          }else{
            localStorage.setItem('type_guideline', 'false')
          }
        }else{
          localStorage.setItem('type_guideline', 'false')
        }

        toast.fire({
          icon: 'success',
          title: `¡Bienvenido ${login.user.name}! `,
          padding: '10px 20px',
        });

        navigate('/admin/home');
        window.location.reload();
      }else{
        toast.fire({
          icon: 'error',
          title: '¡Usuario o contraseña incorrectos!',
          padding: '10px 20px',
        });

        setResponseAuth(null);
      }
    }
  }, [login, setResponseAuth, navigate])

  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Bienvenido
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
        Introduzca su correo electrónico y contraseña para iniciar sesión.
        </p>

        <form onSubmit={submitForm}>
           {/* Email */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Correo*"
            placeholder="mail@simmmple.com"
            id="email"
            type="text"
            name="user"
            onChange={handleChange}
          />

          {/* Password */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Contraseña*"
            placeholder="Min. 8 characters"
            id="password"
            type="password"
            name="pass"
            onChange={handleChange}
          />
          {/* Checkbox */}
          <div className="mb-4 flex items-center justify-between px-2">
            <Link
              className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
              to={`/auth/forgot-password`}
            >
              ¿Ha olvidado su contraseña?
            </Link>
          </div>
          <button type="submit" className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
            Iniciar sesión
          </button>
        </form>
      </div>
    </div>
  );
}
