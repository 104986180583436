/* eslint-disable */
import { Fragment, useState } from 'react';
import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import Iframe from 'react-iframe'

import Logo from "assets/images/Logo.png"
import DashIcon from "components/icons/DashIcon";
import { Dialog, Transition } from '@headlessui/react';
import Card from "components/card";

import routes from "routes";

const Sidebar = (props: {
  open: boolean;
  onClose: React.MouseEventHandler<HTMLSpanElement>;
}) => {
  const { open, onClose } = props;

  const [modal21, setModal21] = useState(false);
  return (
    <div
      className={`sm:none duration-175 pb-10 shadow-2xl transition-all fixed flex !z-50 flex-col h-screen linear bg-white shadow-white/5 dark:!bg-navy-800 dark:text-white overflow-y-scroll md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute top-4 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[70px] mt-[40px] flex items-center`}>
        <div className="flex w-full items-center justify-center">
          <img
            className="w-28 h-full"
            src={Logo}
            alt="Logo"
          />
        </div>
      </div>
      <div className="mt-[40px] mb-5 h-px bg-gray-300 dark:bg-white/30" />
      {/* Nav item */}

      <ul className="mb-auto pt-1 overflow-y-auto h-screen">
        <Links routes={routes} />

        <button key={10} onClick={() => setModal21(true)}>
          <div className="relative mb-3 flex hover:cursor-pointer">
            <li
              className="my-[3px] flex cursor-pointer items-center px-8"
              key={10}
            >
              <span
                className={`font-medium text-gray-600`}
              >
                <DashIcon />
              </span>
              <p
                className={`leading-1 ml-4 flex font-medium text-gray-600`}
              >
                Manual de usuario
              </p>
            </li>
          </div>
        </button>
      </ul>

      <Transition appear show={modal21} as={Fragment}>
        <Dialog as="div" open={modal21} onClose={() => setModal21(false)}>
            <div className="fixed inset-0 z-[999] bg-[black]/60">
                <div className="flex items-center justify-center px-4 min-h-screen">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="panel my-8 w-3/5 h-3/5 xs:w-full overflow-auto rounded-lg border-0 p-0 text-black dark:text-white-dark">
                          <Card extra={"w-full h-full p-3"}>
                                  <div className="flex items-center justify-between bg-[#fbfbfb] px-5 py-3 dark:bg-[#121c2c]">
                                      <h5 className="text-lg font-bold">
                                        Manual de usuario
                                      </h5>
                                      <button onClick={() => setModal21(false)} type="button" className="text-white-dark hover:text-dark">
                                          <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="20"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                          >
                                              <line x1="18" y1="6" x2="6" y2="18"></line>
                                              <line x1="6" y1="6" x2="18" y2="18"></line>
                                          </svg>
                                      </button>
                                  </div>

                                  <div className="mt-2 mb-8 w-full justify-center flex">
                                    <Iframe url="https://gobiernodigital.co/Manual_de_usuario_Gobierno_digital.pdf#toolbar=0&navpanes=0"
                                        width="100%"
                                        height="700px"
                                        display="block"
                                        position="relative"
                                    />
                                  </div>
                                </Card>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
      </Transition>
      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
