import { useEffect, Fragment, useState, useContext } from 'react';
import InputField from "components/fields/InputField";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';

import Select, { MultiValue } from 'react-select';

import { MdModeEditOutline } from "react-icons/md";
import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import sortBy from 'lodash/sortBy';
import { Dialog, Transition } from '@headlessui/react';

import Card from "components/card";

import { useDispatch, useSelector } from 'react-redux';

import {
  useGuidelines,
  useGuidelineEdit,
  useGuidelineRegister,
} from '../../../../hooks/useGuidelines';

import {
  useStructuresLast
} from '../../../../hooks/useStructures';

import { IRootState } from '../../../../store';

// Redux
import {
  setIdNode
} from "../../../../store/nodeSlice";

import { Can } from '@casl/react';
import { AbilityContext } from '../../../../casl/abilityContext';

type ItemType = {
  value: number;
  label: string;
};

interface Guideline {
  id: number;
  name: string;
  code: string;
  percentage_assigned: number;
  percentage_progress?: number;
  node_structure_id: number;
  description: string;
}

function CheckTable() {
  const dispatch = useDispatch();

  const ability = useContext(AbilityContext);

  const id_node = useSelector((state: IRootState) => state.node.id_node );

  const { guidelines, getGuidelines } = useGuidelines()
  const { structure, getStructureLast } = useStructuresLast()

  useEffect(() => {
    getGuidelines(id_node);

    return () => {
      dispatch(setIdNode(0));
    }
  }, [id_node, dispatch, getGuidelines])

  useEffect(() => {
    getStructureLast(1);
  }, [getStructureLast]);

  // Get guidelines list
  useEffect(() => {
    setInitialRecords(sortBy(guidelines, 'id'))
    setRecordsData(sortBy(guidelines, 'id'))
  }, [guidelines])

  const [options_select, setOptionsSelect] = useState<MultiValue<{ value: number; label: string; }>>([])

  useEffect(() => {
    const list = structure.map(objeto => ({
      value: objeto.id,
      label: objeto.name
    }));

    setOptionsSelect(list)
  }, [structure])

  const [page, setPage] = useState(1);
  const PAGE_SIZES = [10, 20, 30, 50, 100];
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const [initialRecords, setInitialRecords] = useState(sortBy([], 'id'));
  const [recordsData, setRecordsData] = useState(initialRecords);

  const [ hideCols ] = useState<any>(['age', 'dob', 'isActive']);

  const [search, setSearch] = useState('');
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: 'id',
    direction: 'asc',
  });

  useEffect(() => {
    setPage(1);
}, [pageSize]);

  useEffect(() => {
    const from = (page - 1) * pageSize;
    const to = from + pageSize;
    setRecordsData([...initialRecords.slice(from, to)]);
  }, [page, pageSize, initialRecords]);

  useEffect(() => {
    setInitialRecords(() => {
      return guidelines.filter((item) => {
        return (
          item.id.toString().includes(search.toLowerCase()) ||
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          item.code.toLowerCase().includes(search.toLowerCase()) ||
          item.percentage_assigned.toString().includes(search.toLowerCase())
        );
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    const data = sortBy(initialRecords, sortStatus.columnAccessor);
    setInitialRecords(sortStatus.direction === 'desc' ? data.reverse() : data);
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortStatus]);

  const { register, guidelineReg, setResponseRC, loadingRG } = useGuidelineRegister()

  useEffect(() => {
    const toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
    });

    if ( register?.errors ) {
      toast.fire({
        icon: 'error',
        title: register.errors.code? register.errors.code[0] : register.errors.percentage_assigned[0],
        padding: '10px 20px',
      });
    }else{
      if (register?.message) {
        if (register?.message > '')
          toast.fire({
            icon: 'success',
            title: register?.message,
            padding: '10px 20px',
          });
          getGuidelines(id_node);
          setModal21(false)
      }
    }

    setResponseRC(null)
  }, [register, getGuidelines, setResponseRC, id_node])

  const [formData, setFormData] = useState({
    id: 0,
    name: '',
    code: '',
    percentage_assigned: 0,
    node_structure_id: 0,
    description: ''
  });

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    isEditing ?  guidelineEdit(formData) : guidelineReg(formData);
  }

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [evt.target.name]: evt.target.value });
  };

  const handleChangeTextarea = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormData({ ...formData, [evt.target.name]: evt.target.value });
  };

  const handleSelectChange = (name: string, evt: ItemType) => {
    // evt.map(obj => { return obj.value })
    setFormData({ ...formData, [name]: evt.value });
  };

  const [modal21, setModal21] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleSave = () => {
    // Logic for saving the saved title
    setFormData({
      id: 0,
      name: '',
      code: '',
      percentage_assigned: 0,
      node_structure_id: 0,
      description: ''
    })

    setIsEditing(false);
    setModal21(true)
  };

  /* Method to edit guideline */

  const { edited, guidelineEdit, setResponseEG, loadingEG } = useGuidelineEdit()

  useEffect(() => {
    const toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
    });

    if ( edited?.errors ) {
      toast.fire({
        icon: 'error',
        title: edited?.errors.code ? edited.errors.code[0] : edited.errors.percentage_assigned ? edited.errors.percentage_assigned[0] : edited.errors.description ? edited.errors.description[0] : '',
        padding: '10px 20px',
      });
    }else{
      if (edited?.valid) {
        if (edited?.message > '')
          toast.fire({
            icon: 'success',
            title: edited?.message,
            padding: '10px 20px',
          });
          getGuidelines(id_node);
          setModal21(false)
      }
    }

    setResponseEG(null)
  }, [edited, getGuidelines, setResponseEG, id_node])


  const handleEdit = (guideline: Guideline) => {

    // Logic for saving the saved title
    setFormData({
      id: guideline.id,
      name: guideline.name,
      code: guideline.code,
      percentage_assigned: guideline.percentage_assigned,
      node_structure_id: guideline.node_structure_id,
      description: guideline.description
    })

    setIsEditing(true);
    setModal21(true)
  };

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className="relative flex items-center justify-between pt-4">
        <Can I="store" a="guidelines" ability={ability}>
          <button onClick={handleSave} className="linear mt-2 w-1/6 rounded-xl bg-brand-500 py-[10px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
            Nuevo
          </button>
        </Can>
        
        <Transition appear show={modal21} as={Fragment}>
            <Dialog as="div" open={modal21} onClose={() => setModal21(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0" />
                </Transition.Child>
                <div className="fixed inset-0 z-[999] bg-[black]/60">
                    <div className="flex min-h-screen items-start justify-center px-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="panel my-8 w-full max-w-xl overflow-hidden  rounded-lg border-0 p-0 text-black dark:text-white-dark">
                                <Card extra={"w-full h-full p-3"}>

                                <div className="flex items-center justify-between bg-[#fbfbfb] px-5 py-3 dark:bg-[#121c2c]">
                                    <h5 className="text-lg font-bold">{ isEditing ? 'Editar' : 'Registrar' } - {localStorage.getItem("type_guideline") === 'true' ? 'Artefacto' : 'Lineamiento'}</h5>
                                    <button onClick={() => setModal21(false)} type="button" className="text-white-dark hover:text-dark">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                        </svg>
                                    </button>
                                </div>

                                  <div className="mt-2 mb-8 w-full">
                                    <form className="space-y-5" onSubmit={submitForm}>

                                      <InputField
                                        value={formData.name}
                                        variant="auth"
                                        extra="mb-3"
                                        label="Nombre*"
                                        placeholder="TI..."
                                        id="name"
                                        type="text"
                                        name="name"
                                        onChange={handleChange}
                                      />

                                      <InputField
                                        value={formData.code}
                                        variant="auth"
                                        extra="mb-3"
                                        label="Código*"
                                        placeholder="LM-01..."
                                        id="code"
                                        type="text"
                                        name="code"
                                        onChange={handleChange}
                                      />

                                      <InputField
                                        value={formData.percentage_assigned}
                                        variant="auth"
                                        extra="mb-3"
                                        label="Porcentaje*"
                                        placeholder="1%"
                                        id="percentage_assigned"
                                        type="number"
                                        name="percentage_assigned"
                                        onChange={handleChange}
                                      />

                                      <div className='mb-3'>
                                        <label
                                          className={`text-sm text-navy-700 dark:text-white`}
                                        >
                                          Relacionar*
                                        </label>
                                        <Select
                                          options={options_select}
                                          value={ (id_node > 0) ? options_select.filter(item => item.value === id_node) : options_select.filter(item => item.value === formData.node_structure_id) }
                                          placeholder="Seleccionar nivel*"
                                          id="node_structure_id"
                                          name="node_structure_id"
                                          onChange={(e: ItemType) =>  handleSelectChange('node_structure_id', e)}
                                        />
                                      </div>

                                      <div className='mb-3'>
                                        <label
                                          htmlFor='description'
                                          className={`text-sm text-navy-700 dark:text-white`}
                                        >
                                          Descripción
                                        </label>
                                        <textarea
                                          value={formData.description}
                                          name="description"
                                          id="description"
                                          onChange={handleChangeTextarea}
                                          className={`mt-2 flex h-20 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none`}
                                        ></textarea>
                                      </div>

                                      <div className="flex w-full justify-end">
                                        <button
                                          disabled={loadingEG}
                                          type="submit"
                                          className="linear mt-2 px-5 rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                                            { loadingEG || loadingRG ? 'Cargando...' : isEditing ? 'Guardar cambios' : 'Registrar' }
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                </Card>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>

        <div className="flex gap-5 ltr:ml-auto rtl:mr-auto">
          <div className="text-right">
            <InputField
              value={search}
              variant="auth"
              extra="mb-3 mr-5 w-full"
              label=""
              placeholder="Buscar..."
              id="name"
              type="text"
              name="name"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      </header>

      <div className="datatables">
        <DataTable
            striped={true} // Add striped prop
            horizontalSpacing="md" // Add horizontalSpacing prop (adjust the value as needed)
            verticalSpacing="md" // Add verticalSpacing prop (adjust the value as needed)
            fontSize={16}
            className="whitespace-nowrap table-hover"
            records={recordsData}
            columns={[
                {
                  accessor: 'id',
                  title: '#',
                  sortable: true,
                  hidden: hideCols.includes('id'),
                  render: ({id}, index) => <div className={`capitalize`}>{ index+1 }</div>,
                },
                {
                  accessor: 'name',
                  title: 'Nombre',
                  sortable: true,
                  hidden: hideCols.includes('first_name'),
                  render: ({ name }) => <div className={``}>{ name }</div>,
                },
                {
                  accessor: 'code',
                  title: 'Código',
                  sortable: true,
                  hidden: hideCols.includes('code'),
                  render: ({ code }) => <div className={`capitalize`}>{ code }</div>,
                },
                {
                  accessor: 'percentage_assigned',
                  title: 'Porcentaje',
                  sortable: true,
                  hidden: hideCols.includes('percentage_assigned'),
                  render: ({ percentage_assigned }) => <div className={`capitalize`}>{ percentage_assigned }%</div>,
                },
                {
                  accessor: 'node',
                  title: 'Relación',
                  sortable: true,
                  hidden: hideCols.includes('node'),
                  render: ({ node }) => <div className={`capitalize`}>{ node?.name }</div>,
                },
                {
                  accessor: 'description',
                  title: 'Descripción',
                  sortable: true,
                  hidden: hideCols.includes('description'),
                  render: ({ description }) => <div className={`truncate w-40`}>{ description }</div>,
                },
                {
                  accessor: 'is_active',
                  title: 'Tareas',
                  sortable: true,
                  hidden: hideCols.includes('is_active'),
                  render: ({ id }) => <div className={`capitalize`}>
                    <Link
                      className="capitalize  hover:underline  dark:hover:text-white  linear mt-2 w-full rounded-sm bg-cyan-500 px-3 py-2 text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                      to={`${id}/tasks`}
                    >
                      Tareas
                    </Link>
                  </div>,
                },
                {
                  accessor: 'edit',
                  title: 'Editar',
                  sortable: true,
                  hidden: hideCols.includes('edit'),
                  render: (row) => <div className={`capitalize`}>
                    <div className="mt-3 flex items-center gap-3">
                      <Can I="update" a="guidelines" ability={ability}>
                        <div onClick={() => handleEdit(row)} className="cursor-pointer mr-4 flex items-center justify-center text-gray-600 dark:text-white">
                          <MdModeEditOutline />
                        </div>
                      </Can>
                    </div>
                  </div>,
                },
            ]}
            highlightOnHover
            totalRecords={initialRecords.length}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            sortStatus={sortStatus}
            onSortStatusChange={setSortStatus}
            minHeight={200}
            paginationText={({ from, to, totalRecords }) => `Mostrando  ${from} a ${to} de ${totalRecords} resultados`}
        />
    </div>

    </Card>
  );
}

export default CheckTable;
