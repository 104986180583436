import React, {useEffect} from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link } from "react-router-dom";
import { RiMoonFill, RiSunFill } from "react-icons/ri";

import { useNavigate } from 'react-router-dom';
import avatar from "assets/img/avatars/avatar4.png";
import { environment } from "environment/environment";

import {
  useComapniePeriods
} from "../../hooks/useCompanies";

const Navbar = (props: {
  onOpenSidenav: () => void;
  brandText: string;
  secondary?: boolean | string;
}) => {
  const { onOpenSidenav, brandText } = props;
  const [darkmode, setDarkmode] = React.useState(false);

  const { periods, getPeriods } = useComapniePeriods();

  useEffect(() => {
    getPeriods();
  }, [getPeriods]);

  const navigate = useNavigate();

  const clearUrl = (url: string) => {
    return (url).replace('public/', '');
  }

  return (
    <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
      <div className="ml-[6px]">
        <div className="h-6 w-[224px] pt-1">
          <a
            className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            href=" "
          >
            Página
            <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
              {" "}
              /{" "}
            </span>
          </a>
          <Link
            className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to="#"
          >
            {brandText}
          </Link>
        </div>
        <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {brandText}
          </Link>
        </p>
      </div>

      <div className="relative mt-[3px] flex h-[61px] w-[350px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[280px] md:flex-grow-0 md:gap-1 xl:w-[300px] xl:gap-2">
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>

        <div className="cursor-pointer text-gray-600 ml-5">
          <label
            className={`text-sm text-navy-700 dark:text-white`}
          >Periodo: </label>
          <select
            name="period_year"
            id="period_year"
            defaultValue={localStorage.getItem("period")}
            onChange={e => {
              localStorage.setItem('period', e.target.value);
              window.location.reload();
            }}>
              {periods.map((period: any) => (
                period.id === Number(localStorage.getItem("period")) ? (
                  <option selected key={period.id} value={period.id}>{period.name}</option>
                ) : (
                  <option key={period.id} value={period.id}>{period.name}</option>
                )
              ))}
          </select>
        </div>

        <div
          className="cursor-pointer text-gray-600 ml-5"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove("dark");
              setDarkmode(false);
            } else {
              document.body.classList.add("dark");
              setDarkmode(true);
            }
          }}
        >
          {darkmode ? (
            <RiSunFill className="h-4 w-4  text-gray-600 dark:text-white" />
          ) : (
            <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
          )}
        </div>
        {/* Profile & Dropdown */}
        <Dropdown
          button={
            <img
              className="h-10 w-10 rounded-full"
              src={
                localStorage.getItem('logo') !== 'null' ?  environment.storageUrl+''+clearUrl(localStorage.getItem('logo')) : avatar
              }
              alt="Logo"
            />
          }
          children={
            <div className="flex h-48 w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="mt-3 ml-4">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    👋 Hola, { localStorage.getItem("name") }
                  </p>{" "}
                </div>
              </div>
              <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="mt-3 ml-4 flex flex-col">
                <button
                  onClick={() =>{
                    localStorage.clear();
                    navigate('/auth');
                  }}
                  className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                >
                  Cerrar sesión
                </button>
              </div>
            </div>
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />
      </div>
    </nav>
  );
};

export default Navbar;
