import React from 'react';


interface LevelsData {
  id: number;
  level_num: number;
  id_parent?: number;
  name: string;
  path_file: string;
}

interface Props {
  levels: LevelsData[];
  onclick: (event: LevelsData) => void;
}

const Breadcrumb = ({ levels, onclick } : Props) => {
  return (
    <nav className="text-gray-600 font-medium">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <button className="text-blue-500 hover:underline">Inicio</button>
          <svg className="w-3 h-3 mx-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M8.293 9.293a1 1 0 011.414 0L13 12.586V11a1 1 0 112 0v3a1 1 0 01-1 1H6a1 1 0 01-1-1v-1a1 1 0 112 0v1h4a1 1 0 01-.707-.293L8.293 9.293z"></path>
          </svg>
        </li>

        {levels.map((level, i) => (
          i+1 === levels.length ? (
            <li key={i} className="flex items-center">
            <button className="hover:underline"> {level.name} </button>
              <svg className="w-3 h-3 mx-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M8.293 9.293a1 1 0 011.414 0L13 12.586V11a1 1 0 112 0v3a1 1 0 01-1 1H6a1 1 0 01-1-1v-1a1 1 0 112 0v1h4a1 1 0 01-.707-.293L8.293 9.293z"></path>
              </svg>
            </li>
          ) : (
            <li key={i} className="flex items-center">
            <button className="text-blue-500 hover:underline" onClick={(e) => onclick(level)} > {level.name} </button>
              <svg className="w-3 h-3 mx-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M8.293 9.293a1 1 0 011.414 0L13 12.586V11a1 1 0 112 0v3a1 1 0 01-1 1H6a1 1 0 01-1-1v-1a1 1 0 112 0v1h4a1 1 0 01-.707-.293L8.293 9.293z"></path>
              </svg>
            </li>
          )
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
