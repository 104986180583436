import { useEffect, useState } from 'react';
import InputField from "components/fields/InputField";
import { Link } from "react-router-dom";

import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import sortBy from 'lodash/sortBy';

import Card from "components/card";

import {
  useTasksAssigned,
  useEvidences
} from '../../../../hooks/useTasks';

import {
  MdDriveFolderUpload,
} from "react-icons/md";

function CheckTable() {
  
  const { tasks, getTasks } = useTasksAssigned()

  useEffect(() => {
    getTasks();
  }, [getTasks]);

  // Get guidelines list
  useEffect(() => {
    setInitialRecords(sortBy(tasks, 'id'))
    setRecordsData(sortBy(tasks, 'id'))
  }, [tasks])


  const [page, setPage] = useState(1);
  const PAGE_SIZES = [10, 20, 30, 50, 100];
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const [initialRecords, setInitialRecords] = useState(sortBy([], 'id'));
  const [recordsData, setRecordsData] = useState(initialRecords);

  const [ hideCols ] = useState<any>(['age', 'dob', 'isActive']);

  const [search, setSearch] = useState('');
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: 'id',
    direction: 'asc',
  });

  useEffect(() => {
    setPage(1);
}, [pageSize]);

  useEffect(() => {
    const from = (page - 1) * pageSize;
    const to = from + pageSize;
    setRecordsData([...initialRecords.slice(from, to)]);
  }, [page, pageSize, initialRecords]);

  useEffect(() => {
    setInitialRecords(() => {
      return tasks.filter((item) => {
        return (
          item.id.toString().includes(search.toLowerCase()) ||
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          item.created_at.toLowerCase().includes(search.toLowerCase()) ||
          item.deadline.toString().includes(search.toLowerCase()) ||
          item.description.toLowerCase().includes(search.toLowerCase())
        );
      });

    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    const data = sortBy(initialRecords, sortStatus.columnAccessor);
    setInitialRecords(sortStatus.direction === 'desc' ? data.reverse() : data);
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortStatus]);

  /* Method to edit guideline */


  const { evidences, getEvidences } = useEvidences()


  const [ tabEvidences, setTabE ] = useState('')

  const [ task_id, setTaskId ] = useState(0)

  const selectEvidencesTask = (e: number) => {
    setTaskId(e)
    setTabE('evidences')
    getEvidences(e)
  }

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit', // 'numeric' will give you the month as a number with leading zeros
    day: '2-digit',   // 'numeric' will give you the day as a number with leading zeros
  };

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className="relative flex items-center justify-between pt-4">

        <div className="flex gap-5 ltr:ml-auto rtl:mr-auto">
          <div className="text-right">
            <InputField
              value={search}
              variant="auth"
              extra="mb-3 mr-5 w-full"
              label=""
              placeholder="Buscar..."
              id="name"
              type="text"
              name="name"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      </header>

      <div className="datatables">
        <DataTable
            striped={true} // Add striped prop
            horizontalSpacing="md" // Add horizontalSpacing prop (adjust the value as needed)
            verticalSpacing="md" // Add verticalSpacing prop (adjust the value as needed)
            fontSize={16}
            className="whitespace-nowrap table-hover"
            records={recordsData}
            columns={[
                {
                  accessor: 'id',
                  title: '#',
                  sortable: true,
                  hidden: hideCols.includes('id'),
                  render: ({id}, index) => <div className={`capitalize`}>{ index+1 }</div>,
                },
                {
                  accessor: 'name',
                  title: 'Tarea',
                  sortable: true,
                  hidden: hideCols.includes('first_name'),
                  render: ({ name }) => <div className={``}>{ name }</div>,
                },
                {
                  accessor: 'deadline',
                  title: 'Fecha limite',
                  sortable: true,
                  hidden: hideCols.includes('deadline'),
                  render: ({ deadline }) => <div className={`capitalize`}>
                    { new Intl.DateTimeFormat('en-US', options).format(Date.parse(deadline)) }
                  </div>,
                },
                {
                  accessor: 'created_at',
                  title: 'Fecha creación',
                  sortable: true,
                  hidden: hideCols.includes('created_at'),
                  render: ({ created_at }) => <div className={``}>{ 
                    new Intl.DateTimeFormat('en-US', options).format(Date.parse(created_at))
                  }</div>,
                },
                {
                  accessor: 'evidences',
                  title: 'Evidencias',
                  sortable: true,
                  hidden: hideCols.includes('evidences'),
                  render: ({ id }) => <div className={`flex items-center justify-center w-1/4`}>
                    <Link
                      className="capitalize dark:hover:text-white w-10 rounded-sm bg-gray-300 p-2 font-medium text-white transition duration-200 hover:bg-gray-700 active:bg-gray-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                      to={`${id}/evidences`}
                    >
                      <MdDriveFolderUpload className="h-6 w-6 text-navy-700 hover:text-white" />
                    </Link>
                  </div>,
                },
            ]}
            highlightOnHover
            totalRecords={initialRecords.length}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            sortStatus={sortStatus}
            onSortStatusChange={setSortStatus}
            minHeight={200}
            paginationText={({ from, to, totalRecords }) => `Mostrando  ${from} a ${to} de ${totalRecords} resultados`}
        />
    </div>

    </Card>
  );
}

export default CheckTable;
