import { useState, useCallback } from "react";

import {
  tasksGet,
  taskGet,
  editedTask,
  registerTask,
  removeTask,
  evidencesGet,
  registerEviedence,
  approveEviedence,
  tasksAssignedGet,
} from "../services/tasksService";

interface Task {
  id: number;
  name: string;
  guideline_id: number;
  approver_id: string;
  deadline: string;
  created_at?: string;
  uploaders: Array<{
    id: number;
    name: string;
  }>,
  description: string;
}

export const useTasks = () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const getTasks = useCallback(async (id_guideline: number) => {
    try {
      setLoading(true);
      setError(null);
      const newUsers = await tasksGet(id_guideline);
      setTasks(newUsers);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { tasks: tasks, getTasks, loading, setTasks };
};

export const useTasksAssigned = () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const getTasks = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const newUsers = await tasksAssignedGet();
      setTasks(newUsers);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { tasks: tasks, getTasks, loading, setTasks };
};

export const useTask = () => {
  const [tasks, setUsers] = useState<Task>();
  const [loading, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const getTask = useCallback(async (id: number) => {
    try {
      setLoading(true);
      setError(null);
      const newUsers = await taskGet(id);
      setUsers(newUsers);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { task: tasks, getTask, loading };
};

export const useTaskRegister = () => {
  const [ register, setResponseRT ] = useState<Boolean>(false);
  const [ loadingRT, setLoading ] = useState<boolean>(false);

  const [, setError] = useState(null);

  const taskReg = useCallback(async (props: Task): Promise<Boolean> => {
    try {
      setLoading(true);
      setError(null);
      const userResponse = await registerTask(props);
      setResponseRT(userResponse);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
    return false;
  }, []);

  return { register: register, taskReg, setResponseRT, loadingRT };
};

export const useTaskRemove = () => {
  const [ remove, setResponseRMT ] = useState<Boolean>(false);
  const [ loadingRT, setLoading ] = useState<boolean>(false);

  const [, setError] = useState(null);

  const taskRemove = useCallback(async (task_id: number): Promise<Boolean> => {
    try {
      setLoading(true);
      setError(null);
      const userResponse = await removeTask(task_id);
      setResponseRMT(userResponse);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
    return false;
  }, []);

  return { remove: remove, taskRemove, setResponseRMT, loadingRT };
};

export const useTaskEdited = () => {
  const [ edited, setResponseUT ] = useState<Boolean>(false);
  const [ loadingUT, setLoading ] = useState<boolean>(false);

  const [, setError] = useState(null);

  const taskEdit = useCallback(async (props: Task): Promise<Boolean> => {
    try {
      setLoading(true);
      setError(null);
      const userResponse = await editedTask(props);
      setResponseUT(userResponse);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
    return false;
  }, []);

  return { edited: edited, taskEdit, setResponseUT, loadingUT };
};

interface Evidence {
  id: number;
  name: string;
  approve: boolean;
  description: string;
  tasks_id: Array<number>;
  created_at?: string;
  name_file?: string;
  path_file?: string;
  logs?: Array<{
    id: number;
    tag: string;
    comment: string;
    created_at: string;
    user: {
      name: string;
    }
  }>,
  file?: Array<{
    path: string;
  }>
}

interface EvidenceReg {
  id: number;
  name: string;
  approve: boolean;
  description: string;
  tasks_id: Array<number>;
  created_at?: string;
  name_file?: string;
  path_file?: string;
  logs?: Array<{
    id: number;
    tag: string;
    comment: string;
    created_at: string;
  }>,
  file: File
}

export const useEvidences = () => {
  const [ evidences, setUsers ] = useState<Evidence[]>([]);
  const [ loading, setLoading ] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const getEvidences = useCallback(async (task: number) => {
    try {
      setLoading(true);
      setError(null);
      const newUsers = await evidencesGet(task);
      setUsers(newUsers);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { evidences: evidences, getEvidences, loading };
};

export const useTaskEvidence = () => {
  const [register, setResponseRT] = useState<Boolean>(false);
  const [loadingRu, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const evidenceReg = useCallback(async (props: Task): Promise<Boolean> => {
    try {
      setLoading(true);
      setError(null);
      const userResponse = await registerTask(props);
      setResponseRT(userResponse);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
    return false;
  }, []);

  return { register: register, evidenceReg, setResponseRT, loadingRu };
};

interface RespoonseEvidenceReg {
  status: boolean;
  message: string;
}

export const useEvidenceRegister = () => {
  const [ register, setResponseRE] = useState<RespoonseEvidenceReg>({ status: false, message: ''});
  const [ loadingRE, setLoading] = useState<boolean>(false);

  const [, setError] = useState(null);

  const evidenceReg = useCallback(async (props: EvidenceReg): Promise<RespoonseEvidenceReg> => {
    try {
      setLoading(true);
      setError(null);
      const userResponse = await registerEviedence(props);
      setResponseRE(userResponse);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
    return { status: false, message: '' };
  }, []);

  return { register: register, evidenceReg, setResponseRE, loadingRE };
};

interface EvidenceApprove {
  id: number;
  approve: boolean;
  comment: string;
}

export const useEvidenceApprove = () => {
  const [approve, setResponseEA] = useState<Boolean>(false);
  const [loadingRu, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const evidenceApprove = useCallback(async (props: EvidenceApprove): Promise<Boolean> => {
    try {
      setLoading(true);
      setError(null);
      const userResponse = await approveEviedence(props);
      setResponseEA(userResponse);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
    return false;
  }, []);

  return { approve: approve, evidenceApprove, setResponseEA };
};
