import React, { useEffect, useState } from "react";

import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import sortBy from 'lodash/sortBy';

import Card from "components/card";
import Progress from "components/progress";

import {
  useDependencies,
} from '../../../hooks/useDependencies';

// const columns = columnsDataCheck;
export default function ComplexTable(props: { tableData: any }) {

  const { dependencies, getDependencies } = useDependencies();

  useEffect(() => {
    getDependencies();
  }, [getDependencies])

    // Get dependencies list
    useEffect(() => {
      setInitialRecords(sortBy(dependencies, 'id'))
      setRecordsData(sortBy(dependencies, 'id'))
    }, [dependencies])
  
    const [page, setPage] = useState(1);
    const PAGE_SIZES = [10, 20, 30, 50, 100];
    const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
    const [initialRecords, setInitialRecords] = useState(sortBy([], 'id'));
    const [recordsData, setRecordsData] = useState(initialRecords);
  
    const [hideCols] = useState<any>(['age', 'dob', 'isActive']);
  
    const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
      columnAccessor: 'id',
      direction: 'asc',
    });
  
    useEffect(() => {
      setPage(1);
  }, [pageSize]);
  
    useEffect(() => {
      const from = (page - 1) * pageSize;
      const to = from + pageSize;
      setRecordsData([...initialRecords.slice(from, to)]);
    }, [page, pageSize, initialRecords]);

  return (
    <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
      <div className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Dependencias
        </div>
      </div>

      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
        <DataTable
            striped={true} // Add striped prop
            horizontalSpacing="md" // Add horizontalSpacing prop (adjust the value as needed)
            verticalSpacing="md" // Add verticalSpacing prop (adjust the value as needed)
            fontSize={16}
            className="whitespace-nowrap table-hover"
            records={recordsData}
            columns={[
                {
                  accessor: 'id',
                  title: '#',
                  sortable: true,
                  hidden: hideCols.includes('id'),
                  render: ({id}, index) => <div className={`capitalize`}>{ index+1 }</div>,
                },
                {
                  accessor: 'name',
                  title: 'NOMBRE',
                  sortable: true,
                  hidden: hideCols.includes('first_name'),
                  render: ({ name }) => <div className={`capitalize`}>{ name }</div>,
                },
                {
                  accessor: 'dependency',
                  title: 'TAREAS ASIGNADAS',
                  sortable: true,
                  hidden: hideCols.includes('dependency'),
                  render: ({ total_tasks, total_tasks_approved }) => <div className={`capitalize`}>
                      { total_tasks + total_tasks_approved }
                  </div>,
                },
                {
                  accessor: 'total_tasks_approved',
                  title: 'AVANCE',
                  sortable: true,
                  hidden: hideCols.includes('total_tasks_approved'),
                  render: ({ total_tasks_approved, total_tasks }) => <div className={`text-center`}>
                    {(!isNaN((total_tasks_approved/total_tasks)*100)) ? (total_tasks_approved/total_tasks)*100 : 0}%
                    <Progress value={(!isNaN((total_tasks_approved/total_tasks)*100)) ? (total_tasks_approved/total_tasks)*100 : 0} />
                  </div>,
                },
            ]}
            highlightOnHover
            totalRecords={initialRecords.length}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            sortStatus={sortStatus}
            onSortStatusChange={setSortStatus}
            minHeight={200}
            paginationText={({ from, to, totalRecords }) => `Mostrando  ${from} a ${to} de ${totalRecords} resultados`}
        />
      </div>
    </Card>
  );
}
