import { useState, useCallback } from "react";

import {
  structuresGet,
  registerStructure,
  editStructure,
  removeStructure,
  levelsGet,
  registerLevels,
  structuresGetLast,
  closeStructure,
  periodGet
} from "../services/structureService";

interface Structure {
  id?: number;
  name: string;
  percentage_assigned: number;
  percentage_progress?: number;
  period_id: number;
  parent_id: number;
  level_id: number;
  sons?: Structure[];
}

export const useStructures = () => {
  const [structure, setStructure] = useState<Structure[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const getStructures = useCallback(async (id_parent: number) => {
    try {
      setLoading(true);
      setError(null);
      const newStructure = await structuresGet(id_parent);
      setStructure(newStructure);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { structure: structure, getStructures, loading, setStructure };
};

export const useStructuresLast = () => {
  const [structure, setStructure] = useState<Structure[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const getStructureLast = useCallback(async (id_parent: number) => {
    try {
      setLoading(true);
      setError(null);
      const newStructure = await structuresGetLast(id_parent);
      setStructure(newStructure);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { structure: structure, getStructureLast, loading };
};

export const useStructureRegister = () => {
  const [register, setResponseRC] = useState<Boolean>(false);
  const [loadingRu, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const companyReg = useCallback(async (props: Structure): Promise<Boolean> => {
    try {
      setLoading(true);
      setError(null);
      const userResponse = await registerStructure(props);
      setResponseRC(userResponse);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
    return false;
  }, []);

  return { register: register, companyReg, setResponseRC, loadingRu };
};

export const useStructureRemove = () => {
  const [remove, setResponseSR] = useState<Boolean>(false);
  const [loadingRu, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const strucutureRm = useCallback(async (node_structure_id: number): Promise<Boolean> => {
    try {
      setLoading(true);
      setError(null);
      const userResponse = await removeStructure(node_structure_id);
      setResponseSR(userResponse);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
    return false;
  }, []);

  return { remove: remove, strucutureRm, setResponseSR, loadingRu };
};

export const useStructureEdited = () => {
  const [edited, setResponseES] = useState<Boolean>(false);
  const [loadingRu, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const structureEdit = useCallback(async (props: Structure): Promise<Boolean> => {
    try {
      setLoading(true);
      setError(null);
      const userResponse = await editStructure(props);
      setResponseES(userResponse);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
    return false;
  }, []);

  return { edited: edited, structureEdit, setResponseES, loadingRu };
};

interface Levels {
  levels: Array<{
    name: string;
  }>,
  period_id: number;
}

interface LevelsData {
  id: number;
  level_num: number;
  name: string; 
  path_file: string;
  code: string;
}

export const useLevelRegister = () => {
  const [register, setResponseRC] = useState<Boolean>(false);
  const [loadingRu, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const levelsReg = useCallback(async (props: Levels): Promise<Boolean> => {
    try {
      setLoading(true);
      setError(null);
      const userResponse = await registerLevels(props);
      setResponseRC(userResponse);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
    return false;
  }, []);

  return { register: register, levelsReg, setResponseRC, loadingRu };
};

export const useLevels = () => {
  const [levels, setStructure] = useState<LevelsData[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const getLevels = useCallback(async (period_id: number) => {
    try {
      setLoading(true);
      setError(null);
      const newStructure = await levelsGet(period_id);
      setStructure(newStructure);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { levels: levels, getLevels, loading };
};

interface Period {
  can_modify_structure: boolean;
  can_modify_autodiagnostic: boolean;
  name: string;
}

export const usePeriod = () => {
  const [ period, setPeriod ] = useState<Period>();
  const [loading, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const getPeriod = useCallback(async (period_id: number) => {
    try {
      setLoading(true);
      setError(null);
      const newStructure = await periodGet(period_id);
      setPeriod(newStructure);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { period: period, getPeriod, loading };
};

export const useCloseStructure = () => {
  const [ closed, setResponseCS] = useState<Boolean>(false);
  const [ loading, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const closeStrucutre = useCallback(async (period_id: number) => {
    try {
      setLoading(true);
      setError(null);
      const response = await closeStructure(period_id);
      setResponseCS(response);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { closed: closed, closeStrucutre, loading };
};
