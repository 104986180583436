import { useEffect, useState, useRef } from "react";

import Card from "components/card";
import Chart from "./Chart";
import Guidelines from "./Guidelines";
import { useDispatch } from 'react-redux';

import Breadcrumb from "../../execution/components/Breadcrumb";

import {
  usePeiod
} from '../../../../hooks/usePeriod';

import {
  useLevels
} from '../../../../hooks/useStructures'

import {
  setIdNode
} from "../../../../store/nodeSlice";

interface Data {
  series: [
    {
      name: string;
      data: number[];
      ids: number[];
    },
    {
      name: string;
      data: number[];
      ids: number[];
    },
  ],
}

interface LevelsData {
  id: number;
  level_num: number;
  id_parent?: number;
  name: string; 
  path_file: string;
  code: string;
}

const Banner = () => {
  const dispatch = useDispatch();
  const { period, getPeriod } = usePeiod()

  const [parent, setParent] = useState<number>(null)

  useEffect(() => {
    getPeriod()
  }, [getPeriod])

  useEffect(() => {
    if (period) {
      setFilteredData( period.nodes.filter(e => e.parent_id === parent) )
    }
  }, [period,parent])

  const chartRef = useRef(null);

  const [data, setData] = useState<Data>({
    series: [
      {
        name: '',
        data: [],
        ids: [],
      },
      {
        name: '',
        data: [],
        ids: [],
      },
    ]
  });

  const [filteredData, setFilteredData] = useState([]); // Neuer State für

  const [categories, setCategories] = useState([]); // Neuer State für

  useEffect(()=>{
    setCategories( filteredData.map(item => item.name) )

    const newData: Data = {
      series: [
        {
          ...data.series[0],
          name: 'Autodiagnóstico',
          data: filteredData.map(item => item.auto_diagnostic.toFixed(2)),
          ids: filteredData.map(item => item.id),
        },
        {
          ...data.series[1],
          name: 'Avance general',
          data: filteredData.map(item => item.percentage_progress.toFixed(2)),
          ids: filteredData.map(item => item.id),
        },
      ],
    };
    setData(newData);
  }, [filteredData])

  useEffect(() => {
    if (chartRef.current && chartRef.current.chartInstance) {
      chartRef.current.chartInstance.update();
    }
  }, [data]);


  const { getLevels, levels } = useLevels()

  const [level_activated, setLevelActivate] = useState<LevelsData>()

  const [levelsBreadcrumb, setLevesBreadcrumb] = useState<LevelsData[]>([])

  const [lowestLevel, setLowestLevel] = useState(0)

  useEffect(() => {
    if (levels.length > 0) 
      setLowestLevel( Math.min(...levels.map(item => item.level_num)) )
  }, [levels])

  useEffect(()=>{
    getLevels( Number(localStorage.getItem('period')) )
  }, [getLevels])

  useEffect(()=>{
    if (lowestLevel === 0) return
      setLevelActivate( levels.filter(e => e.level_num === lowestLevel)[0] )
  }, [levels, lowestLevel])

  const setPositon = (level: LevelsData) => {
    setParent( level.id_parent )
    setLevesBreadcrumb( [...levels.filter(e => e.level_num < level.level_num)] );
    setLevelActivate( levels.filter(e => e.level_num === level.level_num )[0] );
  }

  useEffect(()=>{
    if (level_activated) {
      level_activated.id_parent = parent;
      setLevesBreadcrumb([...levelsBreadcrumb, level_activated ]);
    }
  }, [level_activated, parent, setLevesBreadcrumb])

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6 flex justify-center"}>


      {/* Print parent component */}
      <div className="pt-5">
        <Breadcrumb levels={levelsBreadcrumb} onclick={(e) => {
          setPositon(e)
        }} />
      </div>
      
      <div className='flex w-full justify-center py-5'>
        <div className='h-auto w-full'>
          {level_activated ? (
            <Chart
              categories={categories}
              data={data}
              onclick={(id: number) => {
                setParent(id)
                setLevelActivate(levels.filter(e => e.level_num === level_activated.level_num+1)[0])

                dispatch(setIdNode(id));
              }}
            />
          ):(
            <Guidelines />
          )}
        </div>
      </div>
    </Card>
  );
}

export default Banner;
