export const environment = {
  /* A boolean value that is used to determine if the app is in production mode or not. */
  production: false,

  // url: "http://127.0.0.1:8000/",
  // baseUrl: "http://127.0.0.1:8000/api/",
  // storageUrl: "http://127.0.0.1:8000/storage/",

  url: "https://gobiernodigital.co/BACKEND_DGP/public/",
  baseUrl: "https://gobiernodigital.co/BACKEND_DGP/public/api/",
  storageUrl: "https://gobiernodigital.co/BACKEND_DGP/public/storage/",

  mapsApiKey: "AIzaSyDeEjjlcYpoGl12OOQl1P9gpHyYvCsB8AU",
};
