import React from "react";

// Imports
import Home from "views/home";

import Graphics from "views/admin/graphics";

import Companies from "views/admin/companies";
import Dependencies from "views/admin/dependencies";
import Execution from "views/admin/execution";

import SelfDiagnosis from "views/admin/self-diagnosis";

import Structure from "views/admin/structure";

import Guidelines from "views/admin/guidelines";
import Tasks from "views/admin/tasks";
import Evidences from "views/admin/evidences";

import TasksAssigned from "views/admin/tasks-assigned";

import Approval from "views/admin/approval";

import Reports from "views/admin/report";

import Users from "views/admin/users";

import Roles from "views/admin/roles";
import Permissions from "views/admin/permissions";


import SignIn from "views/auth/SignIn";
import ResetPassword from "views/auth/ResetPassword";
import ForgotPassword from "views/auth/ForgotPassword";

// Icon Imports
import {
  MdList,
  MdHome,
  MdGroupWork,
  MdOutlineLan,
  MdOutlineGppGood,
  MdContentPasteSearch,
  MdScreenSearchDesktop,
  MdSupervisedUserCircle,
  MdOutlineCorporateFare,
  MdOutlineRadar,
} from "react-icons/md";


import { FaTasks } from "react-icons/fa";


import { FaUsersGear } from "react-icons/fa6";

import {TbReportAnalytics} from 'react-icons/tb';

const routes = [
  {
    name: "Login",
    layout: "/auth",
    path: "login",
    icon: <MdHome className="h-6 w-6" />,
    component: <SignIn />,
    menu: false,
    I: 'index',
    a: 'periods'
  },
  {
    name: "Login",
    layout: "/auth",
    path: "reset-password/:token/:email",
    icon: <MdHome className="h-6 w-6" />,
    component: <ResetPassword />,
    menu: false,
    I: 'index',
    a: 'periods'
  },
  {
    name: "Login",
    layout: "/auth",
    path: "forgot-password",
    icon: <MdHome className="h-6 w-6" />,
    component: <ForgotPassword />,
    menu: false,
    I: 'index',
    a: 'periods'
  },
  {
    name: "Login",
    layout: "/auth",
    path: "reset/:token/:email",
    icon: <MdHome className="h-6 w-6" />,
    component: <ResetPassword />,
    menu: false,
    I: 'index',
    a: 'periods'
  },
  {
    name: "Inicio",
    layout: "/admin",
    path: "home",
    icon: <MdHome className="h-6 w-6" />,
    component: <Home />,
    menu: true,
    I: 'index',
    a: 'periods'
  },
  {
    name: "Gráficas",
    layout: "/admin",
    path: "graphics",
    icon: <MdOutlineRadar className="h-6 w-6" />,
    component: <Graphics />,
    menu: true,
    I: 'index',
    a: 'periods'
  },
  {
    name: "Ejecución",
    layout: "/admin",
    path: "execution",
    icon: <MdOutlineGppGood className="h-6 w-6" />,
    component: <Execution />,
    menu: true,
    I: 'index',
    a: 'periods'
  },
  {
    name: "Autodiagnóstico",
    layout: "/admin",
    path: "self-diagnosis",
    icon: <MdScreenSearchDesktop className="h-6 w-6" />,
    component: <SelfDiagnosis />,
    menu: true,
    I: 'index',
    a: 'autodiagnostics'
  },
  //divider
  {
    name: "Estructura",
    layout: "/admin",
    path: "structure",
    icon: <MdOutlineLan className="h-6 w-6" />,
    component: <Structure />,
    menu: true,
    I: 'index',
    a: 'nodes_structure'
  },
  {
    name: localStorage.getItem("type_guideline") === 'true' ? 'Artefactos' : 'Lineamientos',
    layout: "/admin",
    path: "guidelines",
    icon: <MdList className="h-6 w-6" />,
    component: <Guidelines />,
    menu: true,
    I: 'index',
    a: 'guidelines'
  },
  {
    name: "Tareas",
    layout: "/admin",
    path: "guidelines/:id_guideline/tasks",
    icon: <MdList className="h-6 w-6" />,
    component: <Tasks />,
    menu: false,
    I: 'index',
    a: 'tasks'
  },
  {
    name: "Evidencias",
    layout: "/admin",
    path: "guidelines/:id_guideline/tasks/:id_task/evidences",
    icon: <MdList className="h-6 w-6" />,
    component: <Evidences />,
    menu: false,
    I: 'index',
    a: 'evidencies'
  },
  {
    name: "Tareas asignadas",
    layout: "/admin",
    path: "tasks-assigned",
    icon: <FaTasks className="h-5 w-5" />,
    component: <TasksAssigned />,
    menu: true,
    I: 'index',
    a: 'guidelines'
  },
  {
    name: "Evidencias",
    layout: "/admin",
    path: "tasks-assigned/:id_task/evidences",
    icon: <MdList className="h-6 w-6" />,
    component: <Evidences />,
    menu: false,
    I: 'index',
    a: 'evidencies'
  },
  {
    name: "Aprobación",
    layout: "/admin",
    path: "approval",
    icon: <MdContentPasteSearch className="h-6 w-6" />,
    component: <Approval />,
    menu: true,
    I: 'approve',
    a: 'evidencies'
  },
  {
    name: "Reportes",
    layout: "/admin",
    path: "reportes",
    icon: <TbReportAnalytics className="h-6 w-6" />,
    component: <Reports />,
    menu: true,
    I: 'index',
    a: 'periods'
  },
  {
    name: "Dependencias",
    layout: "/admin",
    path: "dependencies",
    icon: <MdGroupWork className="h-6 w-6" />,
    component: <Dependencies />,
    menu: true,
    I: 'index',
    a: 'dependencies'
  },
  {
    name: "Usuarios",
    layout: "/admin",
    path: "users",
    icon: <MdSupervisedUserCircle className="h-6 w-6" />,
    component: <Users />,
    menu: true,
    I: 'index',
    a: 'users'
  },
  {
    name: "Empresas",
    layout: "/admin",
    path: "companies",
    icon: <MdOutlineCorporateFare className="h-6 w-6" />,
    component: <Companies />,
    menu: true,
    I: 'index',
    a: 'companies'
  },
  {
    name: "Roles",
    layout: "/admin",
    path: "roles",
    icon: <FaUsersGear className="h-6 w-6" />,
    component: <Roles />,
    menu: true,
    I: 'index',
    a: 'roles'
  },
  {
    name: "Permissions",
    layout: "/admin",
    path: "roles/:id_rol/permissions",
    icon: <MdList className="h-6 w-6" />,
    component: <Permissions />,
    menu: false,
    I: 'index',
    a: 'permissions'
  },
];
export default routes;
