import { MdOutlineLibraryAdd } from "react-icons/md";
import { useEffect, useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import InputField from "components/fields/InputField";
import Swal from 'sweetalert2';
import Card from "components/card";

import {
  useStructureRegister
} from '../../../../hooks/useStructures';

interface Props {
  id_parent: number;
  level_id: number;
  onRegistrationComplete: () => void;
}

const Banner = ({ id_parent, level_id, onRegistrationComplete }: Props) => {

  const [modal21, setModal21] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    percentage_assigned: 0,
    period_id: Number(localStorage.getItem('period')),
    parent_id: 0,
    level_id: 0,
  });

  useEffect(()=>{
    formData.parent_id = id_parent;
  }, [id_parent, formData])

  useEffect(()=>{
    formData.level_id = level_id;
  }, [level_id, formData])

  const { register, companyReg, setResponseRC } = useStructureRegister()

  useEffect(() => {
    if (register) {
      const toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
      });
      toast.fire({
        icon: 'success',
        title: '¡Registrado exitosamente!',
        padding: '10px 20px',
      });

      setFormData({...formData, name: '', percentage_assigned: 0})
      onRegistrationComplete()
      setModal21(false)
    }
    
    setResponseRC(false)
  }, [register, setResponseRC, onRegistrationComplete, formData])

  const printrData = () => {
    setModal21(true)
  }

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [evt.target.name]: evt.target.value });
  };
  
  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!formData.parent_id) {
      delete formData.parent_id;
    }

    companyReg(formData);
  }

  return (
    <div className="flex flex-col items-start justify-center rounded-2xl bg-clip-border px-3 py-4">

        {/* Background and profile */}
        <div
            onClick={printrData}
            className="cursor-pointer relative mt-1 flex h-28 w-28 justify-center rounded-xl bg-cover items-center text-center text-4xl"
            style={{  backgroundColor: `#e9ecef` }}
        >
            <MdOutlineLibraryAdd />
        </div>

        <Transition appear show={modal21} as={Fragment}>
            <Dialog as="div" open={modal21} onClose={() => setModal21(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0" />
                </Transition.Child>
                <div className="fixed inset-0 z-[999] bg-[black]/60">
                    <div className="flex min-h-screen items-start justify-center px-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="panel my-8 w-full max-w-xl overflow-hidden  rounded-lg border-0 p-0 text-black dark:text-white-dark">
                                <Card extra={"w-full h-full p-3"}>

                                <div className="flex items-center justify-between bg-[#fbfbfb] px-5 py-3 dark:bg-[#121c2c]">
                                    <h5 className="text-lg font-bold"> { isEditing ? 'Editar' : 'Registrar' }</h5>
                                    <button onClick={() => setModal21(false)} type="button" className="text-white-dark hover:text-dark">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                        </svg>
                                    </button>
                                </div>

                                  <div className="mt-2 mb-8 w-full">
                                    <form className="space-y-5" onSubmit={submitForm}>

                                      <InputField
                                        value={formData.name}
                                        variant="auth"
                                        extra="mb-3"
                                        label="Nombre*"
                                        placeholder="Nombre"
                                        id="name"
                                        type="text"
                                        name="name"
                                        onChange={handleChange}
                                      />

                                      <InputField
                                        value={formData.percentage_assigned}
                                        variant="auth"
                                        extra="mb-3"
                                        label="Porcentaje*"
                                        placeholder="1%"
                                        id="percentage_assigned"
                                        type="number"
                                        name="percentage_assigned"
                                        onChange={handleChange}
                                      />

                                      <button type="submit" className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                                        { isEditing ?  'Guardar cambios' : 'Registrar' }
                                      </button>
                                    </form>
                                  </div>
                                </Card>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    </div>
  );
};

export default Banner;