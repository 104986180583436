import { useRef, useEffect, useState } from "react";
import { HiLockClosed } from "react-icons/hi";
import Swal from 'sweetalert2';

// Components
import Card from "components/card";
import Breadcrumb from "./Breadcrumb";
import HierarchicalTable from "./HierarchicalTable";

import {
  MdOutlineSave
} from "react-icons/md";

// Hooks
import {
  useStructures,
  useLevels,
  usePeriod
} from '../../../../hooks/useStructures'

import {
  useGuidelines
} from '../../../../hooks/useGuidelines';

import {
  useAutoDiagnosis,
  useCloseAutoDiagnostic
} from '../../../../hooks/useAutoDiagnosis';

interface LevelsData {
  id: number;
  level_num: number;
  id_parent?: number;
  name: string; 
  path_file: string;
  code: string;
}

const Banner = () => {
  const [parent, setParent] = useState<number>(null)

  const { getLevels, levels } = useLevels();

  useEffect(()=>{
    getLevels( Number(localStorage.getItem('period')) )
  }, [getLevels])
  
  const { autoDiagnosis, getAutoDiagnosis } = useAutoDiagnosis()

  useEffect(() => {
    getAutoDiagnosis()
  }, [getAutoDiagnosis])
  
  const { structure, getStructures } = useStructures()

  useEffect(()=>{
    getStructures(parent)
  },[autoDiagnosis, getStructures, parent])

  const { guidelines, getGuidelines } = useGuidelines();

  useEffect( () => {
    getGuidelines(0)
  },[getGuidelines])

  const { getPeriod, period } = usePeriod()

  useEffect(()=>{
    getPeriod(Number(localStorage.getItem('period')))
  }, [getPeriod])


  const [level_activated, setLevelActivate] = useState<LevelsData>()

  const [levelsBreadcrumb, setLevesBreadcrumb] = useState<LevelsData[]>([])

  useEffect(()=>{
    setLevelActivate( levels.filter(e => e.level_num === 1)[0] )
  }, [levels])

  useEffect(()=>{
    if (level_activated) {
      level_activated.id_parent = parent;
      setLevesBreadcrumb([...levelsBreadcrumb, level_activated ]);
    }
  }, [level_activated, parent])

  const setPositon = (level: LevelsData) => {
    setParent( level.id_parent )
    setLevesBreadcrumb( [...levels.filter(e => e.level_num < level.level_num)] );
    setLevelActivate( levels.filter(e => e.level_num === level.level_num )[0] );
  }

  const refreshPage = () => {
    getAutoDiagnosis()
  }

  const { closed, closedAutoDiagnostic } =   useCloseAutoDiagnostic()

  const closeStructure = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'linear mt-2 px-5 rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200',
        cancelButton: 'btn btn-dark ltr:mr-3 rtl:ml-3',
        popup: 'sweet-alerts',
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: 'Cerrar auto diagnóstico',
        text: "¿Está seguro de ejecutar esta acción?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Cerrar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        padding: '2em',
      })
      .then((result) => {
        if (result.value) {
          //execute the Hook to perform the request
          closedAutoDiagnostic(Number(localStorage.getItem('period')))
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire('Cancelado', 'No se ejecutó ninguna acción', 'error');
        }
      });
  }

  useEffect(() => {
    if (closed) {
      const toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
      });
      toast.fire({
        icon: 'info',
        title: '¡cerrado exitosamente!',
        padding: '10px 20px',
      });

      //getDependencies();
      getPeriod(Number(localStorage.getItem('period')))
    }
  }, [closed, getPeriod])


  const childRef = useRef(null);

  // Función para ejecutar la función en el componente hijo
  const executeChildFunction = () => {
    if (childRef.current) {
      childRef.current.saveADiagnotic();
    }
  };

  return (
    <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
      <div className="w-full">

        {/* Print parent component */}
        <Breadcrumb levels={levelsBreadcrumb} onclick={(e) => {
          setPositon(e)
        }} />
        
        <div className="border-t border-gray-300 my-4" />

        {/** Print childre´s component */}
        <div className="p-4">

          <h1 className="text-2xl font-bold mb-4">Tabla de Auto Diagnóstico</h1>
          {structure && period ? (
            <HierarchicalTable open={period.can_modify_autodiagnostic} ref={childRef} data={structure} guidelines={guidelines} autoDiagnosis={autoDiagnosis} onRegistrationComplete={refreshPage} />
          ):(<>Cargando...</>)}
        </div>
      </div>

      { period && period.can_modify_autodiagnostic ? (
          <>
            <button
              className="fixed bottom-28 right-8 w-16 h-16 rounded-full flex justify-center items-center bg-brand-500 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              onClick={executeChildFunction}
              >
                <MdOutlineSave className="w-6 h-6" />
            </button>

            <button
              className="fixed bottom-8 right-8 w-16 h-16 flex justify-center items-center bg-[#f63b3b] hover:bg-[#f63b3b] text-white dark:text-white font-bold py-2 px-4 rounded-full shadow-md"
              onClick={closeStructure}
              >
                <HiLockClosed className="w-6 h-6" />
            </button>
          </>
      ) : (<></>)}
    </Card>
  );
};

export default Banner;
