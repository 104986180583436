import axiosInstance from "../axiosConfig";
import { environment } from "../environment/environment";

interface Guideline {
  id: number;
  name: string;
  code: string;
  percentage_assigned: number;
  percentage_progress?: number;
  node_structure_id: number;
  description: string;
  node?: {
    name: string;
  }
}

const guideline = {
  id: 0,
  name: '',
  code: '',
  percentage_assigned: 0,
  percentage_progress: 0,
  node_structure_id: 0,
  description: '',
}

export const guidelinesGet = async (id_node: number): Promise<Guideline[]> => {
  try {
    const url = id_node ? `${environment.baseUrl}guidelines?include=node&filter[NodeStructure]=${id_node}` : `${environment.baseUrl}guidelines?include=node&filter[period]=${localStorage.getItem('period')}`;

    const res = await axiosInstance.get(url);

    if (res.statusText !== "OK") {
      return [];
    }

    return res.data;
  } catch (error) {
    return [];
  }
};

export const guidelineGet = async (id: number): Promise<Guideline> => {
  try {
    const res = await axiosInstance.get(`${environment.baseUrl}guidelines/${id}`);

    if (res.statusText !== "OK") {
      return guideline;
    }

    return res.data;
  } catch (error) {
    return guideline;
  }
};

export const guidelinesParentGet = async (parent_id :number): Promise<Guideline[]> => {
  try {
    const res = await axiosInstance.get(`${environment.baseUrl}guidelines?filter[NodeStructure]=${parent_id}`);

    if (res.statusText !== "OK") {
      return [];
    }

    return res.data;
  } catch (error) {
    return [];
  }
};

interface PropsResponse {
  message: string;
  errors: {
    code: string[];
    percentage_assigned: string[];
  },
  success: {
    status: string;
  }
}

const reset: PropsResponse = {
  message: '',
  errors: {
    code: [],
    percentage_assigned: [],
  },
  success: {
    status: ''
  }
};

export const registerGuideline= async (props: Guideline): Promise<PropsResponse> => {
  if (props.name === '') return reset;

  try {
    const res = await axiosInstance.post(
      `${environment.baseUrl}guidelines`,
      props
    ).then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

    return res;
  } catch (error) {
    return reset;
  }
};

export const editGuideline = async (props: Guideline): Promise<PropsResponse> => {
  if (props.name === '') return reset;

  try {
    const res = await axiosInstance.put(
      `${environment.baseUrl}guidelines/${props.id}`,
      props
    ).then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

    return res;
  } catch (error) {
    return reset;
  }
};
