import Card from "components/card";
import { environment } from "../../../../environment/environment";

interface Props {
  id: number;
  name: string;
  percentage_assigned: number;
  percentage_progress: number;
  path_file?: string;
  file?: string;
  onclick: (event: number) => void;
  code?: string;
}

const Banner = ({ id, name, percentage_assigned, percentage_progress, onclick, path_file, file, code }: Props) => {
  return (
    <Card extra={"w-full h-full p-4"}>
    {/* Your storage */}
    <div onClick={() => onclick(id)} className="cursor-pointer mb-auto flex flex-col items-center justify-center" >

      <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[1rem] dark:!bg-navy-700 dark:text-white">
        <img
          className="w-full h-full"
          src={(file) ? file : environment.storageUrl+path_file}
          alt="Logo" />
      </div>

      <h4 className="mb-px mt-3 text-2xl font-bold text-navy-700 dark:text-white overflow-hidden">
        {code} / {name}
      </h4>
    </div>

    
    <div className="border-t border-gray-300 my-4"></div>

    {/* Progress bar */}
    <div className="flex flex-col">
      <div className="flex justify-between">
        <div className="items-start text-start">
          <span className="text-xs">Avance</span>
          <p className="text-sm font-medium text-gray-600">{parseFloat(percentage_progress.toFixed(2))}%</p>
        </div>

        <div className="items-end text-end">
          <span className="text-xs">Estimado</span>
          <p className="text-sm font-medium text-gray-600">{parseFloat( String(percentage_assigned))}%</p>
        </div>
      </div>
      <div className="mt-2 flex h-3 w-full items-center rounded-full bg-lightPrimary dark:!bg-navy-700">
        <span className={`h-full rounded-full bg-brand-500 dark:!bg-white`} 
        style={{
          width: `${percentage_progress}%`
        }}/>
      </div>
    </div>
  </Card>
  );
};

export default Banner;