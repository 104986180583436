import {useEffect, Fragment, useState} from 'react';
import { MdModeEditOutline, MdDelete } from "react-icons/md";
import { Dialog, Transition } from '@headlessui/react';
import Swal from 'sweetalert2';

import Card from "components/card";
import InputField from "components/fields/InputField";

import { environment } from "../../../../environment/environment";

import {
  useStructureEdited,
  useStructureRemove
} from '../../../../hooks/useStructures';

interface Props {
  id: number;
  name: string;
  percentage_assigned: number;
  path_file: string;
  onclick: (event: number) => void;
  can_modify_structure: boolean;
  onRegistrationComplete: () => void;
  level_id: number;
  parent_id: number;
}

const Banner = ({
  id,
  name,
  percentage_assigned,
  onclick,
  path_file,
  can_modify_structure,
  level_id,
  parent_id,
  onRegistrationComplete,
}: Props) => {

  const { remove, strucutureRm, setResponseSR} = useStructureRemove()

  const deleteStructure = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'bg-[#4CAF50] border-none text-white py-1 px-2 rounded-md mx-3',
        cancelButton: 'bg-red-500 border-none text-white py-1 px-2 rounded-md',
        popup: 'sweet-alerts',
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: 'Eliminar estructura',
        text: "¿Está seguro de eliminar este componente?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        padding: '2em',
      })
      .then((result) => {
        if (result.value) {
          strucutureRm(id);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire('Cancelado', 'No se ejecutó ninguna acción', 'error');
        }
      });
  }

  useEffect(() => {
    if (remove) {
      const toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
      });
      toast.fire({
        icon: 'success',
        title: '¡Elimininado exitosamente!',
        padding: '10px 20px',
      });

      onRegistrationComplete()
    }

    setResponseSR(false)
  }, [remove, setResponseSR, onRegistrationComplete])

  const [formData, setFormData] = useState({
    id: id,
    name: '',
    percentage_assigned: 0,
    period_id: Number(localStorage.getItem('period')),
    parent_id: parent_id,
    level_id: level_id,
  });

  useEffect(() => {
    formData.parent_id = parent_id;
  }, [parent_id, formData])

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [evt.target.name]: evt.target.value });
  };

  const [modal21, setModal21] = useState(false);

  const handleEdit = () => {
    setFormData({...formData, name: name, percentage_assigned: percentage_assigned})
    setModal21(true)
  }

  const { edited, setResponseES, structureEdit} = useStructureEdited()

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    structureEdit(formData);
  }

  useEffect(() => {
    if (edited) {
      const toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
      });
      toast.fire({
        icon: 'success',
        title: '¡Editado exitosamente!',
        padding: '10px 20px',
      });

      onRegistrationComplete();
      setModal21(false);
    }

    setResponseES(false)
  }, [edited, setResponseES, onRegistrationComplete])

  return (
    <>
      <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          {/* Background and profile */}
          <div
            className="relative mt-1 flex h-20 w-full justify-center rounded-md bg-cover"
            style={{  backgroundColor: `#e9ecef` }}
          >

            {can_modify_structure ? (
              <div className="flex w-full justify-between p-2 absolute top-0">
                <div
                  onClick={deleteStructure}
                  className="cursor-pointer text-red-700">
                  <MdDelete className="w-5 h-5" />
                </div>
                <div
                  onClick={handleEdit}
                  className="cursor-pointer text-lime-500">
                  <MdModeEditOutline className="w-5 h-5" />
                </div>
              </div>
            ):(<></>)}

            <div onClick={() => onclick(id)} className="cursor-pointer absolute -bottom-12 flex h-auto w-2/4 items-center justify-center rounded-full border-[4px] border-white bg-gray-100 dark:!border-navy-700">
              <img
                className="w-full h-full rounded-full"
                src={environment.storageUrl+path_file}
                alt="Logo" />
            </div>
          </div>

          {/* Name and position */}
          <div className="mt-16 flex flex-col items-center">
            <h4 className="text-xl font-bold text-navy-700 dark:text-white">
              {name}
            </h4>
            <p className="text-base font-normal text-gray-600">
              {percentage_assigned}%
            </p>
          </div>
      </div>
    
        <Transition appear show={modal21} as={Fragment}>
            <Dialog as="div" open={modal21} onClose={() => setModal21(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0" />
                </Transition.Child>
                <div className="fixed inset-0 z-[999] bg-[black]/60">
                    <div className="flex min-h-screen items-start justify-center px-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="panel my-8 w-full max-w-xl overflow-hidden  rounded-lg border-0 p-0 text-black dark:text-white-dark">
                                <Card extra={"w-full h-full p-3"}>

                                <div className="flex items-center justify-between bg-[#fbfbfb] px-5 py-3 dark:bg-[#121c2c]">
                                    <h5 className="text-lg font-bold">Editar</h5>
                                    <button onClick={() => setModal21(false)} type="button" className="text-white-dark hover:text-dark">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                        </svg>
                                    </button>
                                </div>

                                  <div className="mt-2 mb-8 w-full">
                                    <form className="space-y-5" onSubmit={submitForm}>

                                      <InputField
                                        value={formData.name}
                                        variant="auth"
                                        extra="mb-3"
                                        label="Nombre*"
                                        placeholder="Nombre"
                                        id="name"
                                        type="text"
                                        name="name"
                                        onChange={handleChange}
                                      />

                                      <InputField
                                        value={formData.percentage_assigned}
                                        variant="auth"
                                        extra="mb-3"
                                        label="Porcentaje*"
                                        placeholder="1%"
                                        id="percentage_assigned"
                                        type="number"
                                        name="percentage_assigned"
                                        onChange={handleChange}
                                      />

                                      <button type="submit" className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                                        Guardar cambios
                                      </button>
                                    </form>
                                  </div>
                                </Card>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    </>
  );
};

export default Banner;