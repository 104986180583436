import React, { useEffect, useState } from "react";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";

import Widget from "components/widget/Widget";
import Usuarios from "./components/Users";
import Dependencies from "./components/Dependencies";
import tableDataComplex from "./variables/tableDataComplex";

// Hooks
import {
  useStructures,
} from '../../hooks/useStructures';

import {
  useGuidelines
} from '../../hooks/useGuidelines';

import {
  useEvidences
} from '../../hooks/useTasks';

import {
  useAutoDiagnosis
} from '../../hooks/useAutoDiagnosis';

const Dashboard = () => {
  const { getStructures, structure } = useStructures()

  useEffect(()=>{
    getStructures(null)
  },[getStructures])

  const [avance, setAvance] = useState<number>(0);

  useEffect(()=>{
    const lowestLevel = Math.min(...structure.map(item => item.level_id));

    const filteredData = structure.filter(item => item.level_id === lowestLevel);

    let avance = 0;
    filteredData.map(item => {
      avance += item.percentage_progress;
      return null;
    })

    setAvance(avance)
  }, [structure])  

  /**
   * Method to get the guidelines
   */

  const { guidelines, getGuidelines } = useGuidelines()
  const [ avanceGuidelines, setAvanceGuidelines ] = useState<number>(0);

  useEffect(() => {
    getGuidelines(0);
  }, [getGuidelines]);

  useEffect(() => {
    if ( guidelines.length === 0 ) return;

    // Calcula la suma de los porcentajes de progreso
    let suma = guidelines.reduce((total, lineamiento) => total + lineamiento.percentage_progress, 0);

    // Calcula el porcentaje de progreso general
    let porcentajeGeneral = suma / guidelines.length;

    setAvanceGuidelines( parseFloat(porcentajeGeneral.toFixed(2)) )
  }, [guidelines])

  /**
   * Method for autoDiagnosis
   */

  const { getAutoDiagnosis, autoDiagnosis } = useAutoDiagnosis();
  const [ autoDiagnosisApprove, setAutoDiagnosisApprove ] = useState<number>(0);

  useEffect(() => {
    getAutoDiagnosis();
  }, [getAutoDiagnosis]);

  useEffect(() => {
    if ( autoDiagnosis.length === 0 || guidelines.length === 0 ) return;

    const registeredAutoDiagnosisCount = autoDiagnosis.filter(autoDiag => 
      guidelines.find(guideline => guideline.id === autoDiag.guideline_id) && autoDiag.diagnostic > 0
    ).length;

    setAutoDiagnosisApprove((registeredAutoDiagnosisCount / guidelines.length) * 100);
  }, [autoDiagnosis, guidelines])

  /**
   * Method to get the evidences
   */

  const { evidences, getEvidences } = useEvidences();
  const [ evidencesApprove, setEvidencesApprove ] = useState<number>(0);

  useEffect(() => {
    getEvidences(0);
  }, [getEvidences]);

  useEffect(() => {
    if ( evidences.length === 0 ) return;
    setEvidencesApprove(evidences.filter(item => item.approve == true).length)
  }, [evidences])

  return (
    <div>
      {/* Card widget */}
      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-4 3xl:grid-cols-4">
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Ejecución"}
          subtitle={`Avance: ${parseFloat(avance.toFixed(2)) + 0.01}/100`}
        />
        <Widget
          icon={<IoDocuments className="h-6 w-6" />}
          title={"Autodiagnóstico"}
          subtitle={`Porcentaje: ${parseFloat(autoDiagnosisApprove.toFixed(2)) + 0.01}%`}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={localStorage.getItem("type_guideline") === 'true' ? 'Artefactos' : 'Lineamientos'}
          subtitle={`Gestionado:  ${parseFloat(avanceGuidelines.toFixed(2)) + 0.01}%`}
        />
        <Widget
          icon={<MdDashboard className="h-6 w-6" />}
          title={"Aprobación"}
          subtitle={`Aprobadas: ${evidencesApprove} de ${evidences.length}`}
        />
      </div>

      {/* Tables & Charts */}
      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        <Usuarios tableData={tableDataComplex} />

        <Dependencies tableData={tableDataComplex} />
      </div>
    </div>
  );
};

export default Dashboard;
