import { useEffect, Fragment, useState, useContext } from 'react';
import CardMenu from "components/card/CardMenu";
import InputField from "components/fields/InputField";
import Swal from 'sweetalert2';

import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import sortBy from 'lodash/sortBy';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';

import Card from "components/card";

import { MdModeEditOutline } from "react-icons/md";

import {
  useComapnies,
  useCompaniEdit,
  useCompanyRegister
} from '../../../../hooks/useCompanies';

import { AbilityContext } from '../../../../casl/abilityContext';

interface Company {
  id: number;
  name: string;
  nit: number;
  logo: File;
  name_user: string;
  email: string;
  type_guideline: boolean;
}

function CheckTable() {
  const { t } = useTranslation();

  const ability = useContext(AbilityContext);

  const { companies, getCompanies } = useComapnies()

  useEffect(() => {
    ability.can('index', 'companies') ? getCompanies('all') : getCompanies('');
  }, [getCompanies, ability]);

  // Get users list
  useEffect(() => {
    setInitialRecords(sortBy(companies, 'id'))
    setRecordsData(sortBy(companies, 'id'))
  }, [companies])

  const [page, setPage] = useState(1);
  const PAGE_SIZES = [10, 20, 30, 50, 100];
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const [initialRecords, setInitialRecords] = useState(sortBy([], 'id'));
  const [recordsData, setRecordsData] = useState(initialRecords);

  const [hideCols] = useState<any>(['age', 'dob', 'isActive']);

  const [search, setSearch] = useState('');
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: 'id',
    direction: 'asc',
  });

  useEffect(() => {
    setInitialRecords(() => {
      return companies.filter((item) => {
        return (
          item.id.toString().includes(search.toLowerCase()) ||
          item.name.toLowerCase().includes(search.toLowerCase())
        );
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const { register, companyReg, setResponseRC } = useCompanyRegister()

  useEffect(() => {
    if (register) {
      const toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
      });
      toast.fire({
        icon: 'success',
        title: '¡Empresa registrada exitosamente!',
        padding: '10px 20px',
      });

      ability.can('index', 'companies') ? getCompanies('all') : getCompanies('');
    }
    setModal21(false)
    setResponseRC(false)
  }, [register, getCompanies, setResponseRC, ability])


  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [evt.target.name]: evt.target.value });
  };

  const handleChangeChecked = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [evt.target.name]: evt.target.checked });
  };
  
  const handleChangeFile = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const files = evt.target.files;

    if (files && files.length > 0) {
      setFormData({ ...formData, [evt.target.name]: files[0] });
    }
  };

  const [modal21, setModal21] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const fileData: BlobPart[] = ['contenido del archivo']; // Puedes proporcionar los datos del archivo aquí
  const fileName = 'archivo.txt';
  const fileOptions: FilePropertyBag = { type: 'text/plain', lastModified: Date.now() };

  const [formData, setFormData] = useState({
    id: 0,
    name: '',
    nit: NaN,
    logo: new File(fileData, fileName, fileOptions),
    name_user: '',
    email: '',
    type_guideline: false,
  });

  const handleSave = () => {
    setFormData({
      id: 0,
      name: '',
      nit: undefined,
      logo: null,
      name_user: '',
      email: '',
      type_guideline: false,
    })

    setIsEditing(false);
    setModal21(true)
  };

  /**
   * Process to edited Compani
   */

  const { edited, companiEdit, setResponseED } = useCompaniEdit()

  const handleEdit = (company: Company) => {
    setFormData({
      id: company.id,
      name: company.name,
      nit: company.nit,
      logo: null,
      name_user: company.name_user,
      email: company.email,
      type_guideline: company.type_guideline ? true : false,
    })

    console.log( company )

    setIsEditing(true);
    setModal21(true)
  };

  useEffect(() => {
    const toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
    });

    if ( edited?.errors ) {
      toast.fire({
        icon: 'error',
        title: edited?.errors.logo ? edited.errors.logo[0] : edited.errors.name[0],
        padding: '10px 20px',
      });
    }else{
      if (edited?.valid) {
        if (edited?.message > '')
          toast.fire({
            icon: 'success',
            title: edited?.message,
            padding: '10px 20px',
          });

          ability.can('index', 'companies') ? getCompanies('all') : getCompanies('');
          setModal21(false)
      }
    }

    setResponseED(null)
  }, [edited, getCompanies, setResponseED, ability])

  /**
   * Submit form
   */

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    isEditing ? companiEdit(formData) : companyReg(formData);;
  }

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className="relative flex items-center justify-between pt-4">
        <button onClick={handleSave} className="linear mt-2 w-1/6 rounded-xl bg-brand-500 py-[10px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Nueva
        </button>
        
        <Transition appear show={modal21} as={Fragment}>
            <Dialog as="div" open={modal21} onClose={() => setModal21(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0" />
                </Transition.Child>
                <div className="fixed inset-0 z-[999] bg-[black]/60">
                    <div className="flex min-h-screen items-start justify-center px-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="panel my-8 w-full max-w-xl overflow-hidden  rounded-lg border-0 p-0 text-black dark:text-white-dark">
                                <Card extra={"w-full h-full p-3"}>

                                <div className="flex items-center justify-between bg-[#fbfbfb] px-5 py-3 dark:bg-[#121c2c]">
                                    <h5 className="text-lg font-bold"> { isEditing ? 'Editar' : 'Registrar' } - {t('empresa')}</h5>
                                    <button onClick={() => setModal21(false)} type="button" className="text-white-dark hover:text-dark">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                        </svg>
                                    </button>
                                </div>

                                  <div className="mt-2 mb-8 w-full">
                                    <form className="space-y-5" onSubmit={submitForm}>

                                      <div className='grid grid-cols-2'>
                                        <InputField
                                          value={formData.name}
                                          variant="auth"
                                          extra="mb-3 mr-2"
                                          label="Nombre*"
                                          placeholder="Empresa S.A.S"
                                          id="name"
                                          type="text"
                                          name="name"
                                          onChange={handleChange}
                                        />

                                        <InputField
                                          value={formData.nit}
                                          variant="auth"
                                          extra="mb-3 ml-2"
                                          label="Nit*"
                                          placeholder="981-765-432"
                                          id="nit"
                                          type="text"
                                          name="nit"
                                          onChange={handleChange}
                                        />

                                        <InputField
                                          value={formData.name_user}
                                          variant="auth"
                                          extra="mb-3 mr-2"
                                          label="Nombre administrador*"
                                          placeholder="admin empresa"
                                          id="name_user"
                                          type="text"
                                          name="name_user"
                                          onChange={handleChange}
                                        />

                                        <InputField
                                          value={formData.email}
                                          variant="auth"
                                          extra="mb-3 ml-2"
                                          label="Correo electrónico*"
                                          placeholder="admin@empresa.com"
                                          id="email"
                                          type="email"
                                          name="email"
                                          onChange={handleChange}
                                        />

                                        <div className="w-full">
                                          <div className='mb-3 mr-2'>
                                            <label className='text-sm text-navy-700 dark:text-white ml-3 font-bold' htmlFor="file">Logo*</label>
                                            <input
                                              type="file"
                                              accept='jpg,jpeg,png'
                                              id="logo"
                                              name="logo"
                                              onChange={handleChangeFile}
                                              className='mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none'
                                            />
                                          </div>
                                        </div>

                                        <div className="w-full">
                                          <div className='mb-3 mr-2'>
                                            <label className='text-sm text-navy-700 dark:text-white ml-3 font-bold' htmlFor="file">¿Artefactos?</label>
                                            <input
                                              type="checkbox"
                                              name='type_guideline'
                                              checked={formData.type_guideline === true ? true : false}
                                              onChange={handleChangeChecked}
                                              className={`mt-2 flex h-4 w-full items-center justify-center rounded-xl border bg-white/0 p-1 outline-none    border-gray-200 dark:!border-white/10 dark:text-white`}
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <button type="submit" className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                                        { isEditing ?  'Guardar cambios' : 'Registrar' }
                                      </button>
                                    </form>
                                  </div>
                                </Card>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>

        <div className="flex items-center gap-5 ltr:ml-auto rtl:mr-auto">
          <div className="flex md:items-center md:flex-row flex-col gap-5">
            <div className="dropdown"></div>
          </div>
          <div className="text-right">
            <InputField
              value={search}
              variant="auth"
              extra="mb-3 mr-5"
              label=""
              placeholder="Buscar..."
              id="name"
              type="text"
              name="name"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <CardMenu />
      </header>

      <div className="datatables">
        <DataTable
          striped={true} // Add striped prop
          horizontalSpacing="md" // Add horizontalSpacing prop (adjust the value as needed)
          verticalSpacing="md" // Add verticalSpacing prop (adjust the value as needed)
          fontSize={16}
          className="whitespace-nowrap table-hover"
          records={recordsData}
          columns={[
            {
              accessor: 'id',
              title: 'ID',
              sortable: true,
              hidden: hideCols.includes('id'),
              render: ({id}, index) => <div className={`capitalize`}>{ index+1 }</div>,
            },
            {
              accessor: 'name',
              title: 'Nombre',
              sortable: true,
              hidden: hideCols.includes('first_name'),
              render: ({ name }) => <div className={`capitalize`}>{ name }</div>,
            },
            {
              accessor: 'is_active',
              title: 'Acciones',
              sortable: true,
              hidden: hideCols.includes('is_active'),
              render: (row) => <div className={`capitalize`}>
              <div className="mt-3 flex items-center gap-3">
                <div onClick={() => handleEdit(row)} className="cursor-pointer mr-4 flex items-center justify-center text-gray-600 dark:text-white">
                  <MdModeEditOutline />
                </div>
              </div>
              </div>,
            },
          ]}
          highlightOnHover
          totalRecords={initialRecords.length}
          recordsPerPage={pageSize}
          page={page}
          onPageChange={(p) => setPage(p)}
          recordsPerPageOptions={PAGE_SIZES}
          onRecordsPerPageChange={setPageSize}
          sortStatus={sortStatus}
          onSortStatusChange={setSortStatus}
          minHeight={200}
          paginationText={({ from, to, totalRecords }) => `${t('showing')}  ${from} ${t('to')} ${to} ${t('of')} ${totalRecords} ${t('results')}`}
        />
    </div>

    </Card>
  );
}

export default CheckTable;
