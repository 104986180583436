
import { useEffect } from "react";
import { MdBarChart } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';

import Card from "components/card";

import { IRootState } from '../../../../store';

import {
  useGuidelines
} from "../../../../hooks/useGuidelines";

import {
  setIdNode
} from "../../../../store/nodeSlice";

const Tables = () => {
  const dispatch = useDispatch();

  const id_node = useSelector((state: IRootState) => state.node.id_node );

  const { guidelines, getGuidelines } = useGuidelines();

  useEffect(() => {
    getGuidelines(id_node);

    return () => {
      dispatch(setIdNode(0));
    }
  }, [id_node, dispatch, getGuidelines])

  return (
    <div className="h-screen">
      <div className="text-center pt-3 mt-10 mb-10">
        <h1 className="text-3xl font-extrabold text-navy-700">Lineamientos</h1>
      </div>

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3 h-36">
        {guidelines.map((item, index: number) => {
          return (
            <Card extra="!flex-row flex-grow items-center rounded-[20px] py-5">
              <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
                <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
                  <span className="flex items-center text-brand-500 dark:text-white">
                    {<MdBarChart className="h-7 w-7"/>}
                  </span>
                </div>
              </div>

              <div className="h-50 ml-4 flex w-full flex-col justify-center pr-4">
                <p className="font-dm text-sm font-medium text-gray-600">{item.name}</p>
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                  {item.code}
                </h4>

                <div className="w-full">
                  <div className="mt-2 flex h-3 w-full items-center rounded-full bg-lightPrimary dark:!bg-navy-700">
                    <span className={`h-full rounded-full bg-brand-500 dark:!bg-white flex text-center`} 
                    style={{
                      width: `${item.percentage_progress}%`
                    }}>
                      {item.percentage_progress}%
                    </span>  
                  </div>
                </div>
              </div>
            </Card>
          )
        })}
      </div>
    </div>
  );
};

export default Tables;
