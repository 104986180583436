import { useState, useCallback } from "react";

import {
  editDependenci,
  dependenciesGet,
  registerDependencie,
  changeDependenciStatus
} from "../services/dependenciesService";

interface Dependenci {
  id: number;
  name: string;
  company_id: number;
  total_tasks?: number;
  total_tasks_approved?: number;
}

export const useDependencies = () => {
  const [ dependencies, setUsers ] = useState<Dependenci[]>([]);
  const [ loading, setLoading ] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const getDependencies = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const newUsers = await dependenciesGet();
      setUsers(newUsers);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { dependencies: dependencies, getDependencies, loading };
};

export const useDependencieRegister = () => {
  const [register, setResponseRU] = useState<Boolean>(false);
  const [loadingRu, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const dependencieReg = useCallback(async (props: Dependenci): Promise<Boolean> => {
    try {
      setLoading(true);
      setError(null);
      const userResponse = await registerDependencie(props);
      setResponseRU(userResponse);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
    return false;
  }, []);

  return { register: register, dependencieReg, loadingRu, setResponseRU };
};

export const useStateDependenci = () => {
  const [ statusChange, setResponseCS ] = useState<Boolean>(false);
  const [ loadingCS, setLoading ] = useState<Boolean>(false);

  const [ errors , setError ] = useState(null);

  const changeState = useCallback(async (id: number): Promise<Boolean> => {
    try {
      setLoading(true);
      setError(null);
      const userResponse = await changeDependenciStatus(id);
      setResponseCS(userResponse);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
    return false;
  }, []);

  return { statusChange: statusChange, setResponseCS, changeState, loadingCS, errors };
};


interface PropsResponse {
  message: string;
  errors: {
    code: string[];
    percentage_assigned: string[];
  },
  success: {
    status: string;
  },
  valid?: Dependenci
}

const reset: PropsResponse = {
  message: '',
  errors: {
    code: [],
    percentage_assigned: [],
  },
  success: {
    status: ''
  }
};

export const useDependenciEdit = () => {
  const [ edited, setResponseED ] = useState<PropsResponse>(null);
  const [ loadingED, setLoading ] = useState<boolean>(false);

  const [ errors, setError ] = useState(null);

  const dependenciEdit = useCallback(async (props: Dependenci): Promise<PropsResponse> => {
    try {
      setLoading(true);
      setError(null);
      const userResponse = await editDependenci(props);
      setResponseED(userResponse);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
    return reset;
  }, []);

  return { edited: edited, dependenciEdit, setResponseED, loadingED, errors };
};

