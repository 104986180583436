// ability.ts
import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability';

type Actions = 'index' | 'save' | 'edit';
type Subjects = 'users';

interface Permissions {
  permissions: string[];
}

export type AppAbility = Ability<[Actions, Subjects]>;

export function defineAbilitiesFor(permissions: Permissions): AppAbility {
  const { can, build } = new AbilityBuilder<Ability<[Actions, Subjects]>>(Ability as AbilityClass<AppAbility>);

  permissions.permissions.forEach((permission: string) => {
    const [action, subject] = permission.split('.');

    // console.log(action, subject);

    can(subject as Actions, action as Subjects);
  });

  return build();
}