import { useEffect, useState } from 'react';
import { useParams, Link } from "react-router-dom";
import InputField from "components/fields/InputField";
import Swal from 'sweetalert2';

import { Stack, Group, Box } from '@mantine/core';

import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import sortBy from 'lodash/sortBy';

import {
  MdOutlineArrowBack,
} from "react-icons/md";

import { CiCircleChevDown, CiCircleChevUp } from "react-icons/ci";


import Card from "components/card";

import {
  useRol,
  usePermissions,
  useAddPermissionToRole,
  useRemovePermissionFromRole
} from '../../../../hooks/useRoles';

interface Permissions {
  id: number;
  name: string;
  description: string;
  guard_name: string;
  isAssigned: boolean;
}

function CheckTable() {
  const { id_rol } = useParams();

  const { rol, getRol } = useRol()
  const { permissions, getPermissions } = usePermissions()


  useEffect(() => {
    getPermissions(Number(id_rol))
    getRol(Number(id_rol))
  }, [getPermissions, id_rol, getRol])

  /**
   * Config for DataTable
   */

  const [page, setPage] = useState(1);
  const PAGE_SIZES = [10, 20, 30, 50, 100];
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const [initialRecords, setInitialRecords] = useState(sortBy([], 'id'));
  const [recordsData, setRecordsData] = useState(initialRecords);

  const [hideCols] = useState<any>(['age', 'dob', 'isActive']);

  const [search, setSearch] = useState('');
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: 'id',
    direction: 'asc',
  });

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  useEffect(() => {
    const from = (page - 1) * pageSize;
    const to = from + pageSize;
    setRecordsData([...initialRecords.slice(from, to)]);
  }, [page, pageSize, initialRecords]);

  useEffect(() => {
    setInitialRecords(() => {
      return permissions.filter((item) => {
        return (
          item.id.toString().includes(search.toLowerCase()) ||
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          item.description.toLowerCase().includes(search.toLowerCase()) ||
          item.guard_name.toLowerCase().includes(search.toLowerCase())
        );
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    const data = sortBy(initialRecords, sortStatus.columnAccessor);
    setInitialRecords(sortStatus.direction === 'desc' ? data.reverse() : data);
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortStatus]);

  // Get permissions list

  const [permissionsGroup, setPermissionsGroup] = useState<any>([]);

  useEffect(() => {
    const indexPermissions = permissions.filter(permission => permission.name.endsWith('.index'));

    setInitialRecords(sortBy(indexPermissions, 'id'));
    setRecordsData(sortBy(indexPermissions, 'id'));

    const grouped = permissions.reduce((groups: Record<string, Permissions[]>, permission) => {
      const [group, action] = permission.name.split(".");
      if (!groups[group]) {
        groups[group] = [];
      }

      if (action !== 'index')
        groups[group].push(permission);
      return groups;
    }, {});

    setPermissionsGroup(grouped);
  }, [permissions])

  

  const [formData,] = useState({
    role: 0,
    permissions: [0]
  });

  const { addPermissionRol, addPermissionToRole, setResponseAuth } = useAddPermissionToRole()

  const { remove, removePermissionFromRole, setResponseRmvP } = useRemovePermissionFromRole()

  const changePermission = (permission: number, status: boolean) => {

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-secondary',
            cancelButton: 'btn btn-dark ltr:mr-3 rtl:ml-3',
            popup: 'sweet-alerts',
        },
        buttonsStyling: false,
    });
    swalWithBootstrapButtons
        .fire({
            title: (!status) ? ' Remover permiso' : 'Asignar permiso',
            text: "¿Está seguro de ejecutar esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ejecutar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
            padding: '2em',
        })
        .then((result) => {
            if (result.value) {
                formData.role = Number(id_rol)
                formData.permissions = [permission]

                if ( status ) {
                    addPermissionToRole(formData)
                }else{
                    removePermissionFromRole(formData)
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                swalWithBootstrapButtons.fire('Cancelado', 'No se ejecutó ninguna acción', 'error');
            }
        });
  }

  useEffect(()=>{
    const toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
    });

    if (addPermissionRol) {
        toast.fire({
            icon: 'success',
            title: '¡Permiso asignado con éxito!',
            padding: '10px 20px',
        });

        getPermissions(Number(id_rol))
        setResponseAuth(false);
    }

    if (remove) {
        toast.fire({
            icon: 'success',
            title: '¡Permiso retirado con éxito!',
            padding: '10px 20px',
        });

        getPermissions(Number(id_rol))
        setResponseRmvP(false);
    }

  }, [addPermissionRol, remove, getPermissions, id_rol, setResponseAuth, setResponseRmvP])

  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});

  const toggleRow = (id: string) => {
    setExpandedRows(prevState => ({ ...prevState, [id]: !prevState[id] }));
  };

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>

      <header className="relative flex items-center justify-between pt-4">
        <div className="relative flex flex-row justify-between mt-5">
          <div className="flex items-center">
            <Link
              className="flex h-9 w-9 items-center justify-center rounded-full bg-indigo-100 dark:bg-indigo-100 dark:bg-white/5"
              to={`/admin/roles`}
            >
              <MdOutlineArrowBack className="h-6 w-6 text-gray-800 dark:text-white" />
            </Link>

            <h4 className="text-xl font-bold text-navy-700 ms-4 dark:text-white">
              Permisos {rol ? (<>/ Rol: {rol.name}</>) : (<></>)}
            </h4>
          </div>
        </div>

        <div className="flex gap-5 ltr:ml-auto rtl:mr-auto">
          <div className="text-right">
            <InputField
              value={search}
              variant="auth"
              extra="mb-3 mr-5 w-full"
              label=""
              placeholder="Buscar..."
              id="name"
              type="text"
              name="name"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      </header>

      <div className="datatables">
        <DataTable
            striped={true} // Add striped prop
            horizontalSpacing="md" // Add horizontalSpacing prop (adjust the value as needed)
            verticalSpacing="md" // Add verticalSpacing prop (adjust the value as needed)
            fontSize={16}
            className="whitespace-nowrap table-hover"
            records={recordsData}
            columns={[
              {
                accessor: 'expand',
                title: 'Expandir',
                sortable: false,
                hidden: hideCols.includes('expand'),
                render: (row) => (
                  <div onClick={() => toggleRow(row.id)}>
                    <button
                      className="w-6 h-6 rounded"
                    >
                      {expandedRows[row.id] ? <CiCircleChevUp  className='w-6 h-6' /> : <CiCircleChevDown className='w-6 h-6' />}
                    </button>
                  </div>
                ),
              },
              {
                accessor: 'description',
                title: 'Descripción',
                sortable: true,
                hidden: hideCols.includes('description'),
                render: ({ description }) => <div className={``}>{ description }</div>,
              },
              {
                accessor: 'isAssigned',
                title: 'Asignar/Remover',
                sortable: true,
                hidden: hideCols.includes('isAssigned'),
                render: (row) => <div className={``}>
                  <div className="mt-3 flex items-center gap-3">
                    <input
                      type="checkbox"
                      value={row.isAssigned}
                      checked={row.isAssigned}
                      defaultChecked={row.isAssigned}
                      onChange={(e) => changePermission(row.id, !row.isAssigned)}
                      className={`mt-2 flex h-4 w-full items-center justify-center rounded-xl border bg-white/0 p-1 outline-none    border-gray-200 dark:!border-white/10 dark:text-white`}
                    />
                  </div>
                </div>,
              },
            ]}
            rowExpansion={{
              content: ({ record }) => (
                <Stack className='gap-6 p-10'>
                  <Group className='gap-6'>
                    {permissionsGroup[record.name.split(".")[0]].map((permission: Permissions, index: number) => (
                      <Box key={permission.id} className='w-full grid grid-cols-6'>
                        <div></div>
                        <div className='text-left col-span-4'>
                          {permission.description}
                        </div>
                        <div className="mt-3 flex items-center gap-3">
                          <input
                            type="checkbox"
                            checked={permission.isAssigned}
                            defaultChecked={permission.isAssigned}
                            onChange={(e) => changePermission(permission.id, !permission.isAssigned)}
                            className={`mt-2 flex h-4 w-full items-center justify-center rounded-xl border bg-white/0 p-1 outline-none    border-gray-200 dark:!border-white/10 dark:text-white`}
                          />
                        </div>
                      </Box>
                    ))}
                  </Group>
                </Stack>
              ),
            }}
            highlightOnHover
            totalRecords={initialRecords.length}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            sortStatus={sortStatus}
            onSortStatusChange={setSortStatus}
            minHeight={200}
            paginationText={({ from, to, totalRecords }) => `Mostrando  ${from} a ${to} de ${totalRecords} resultados`}
        />
      </div>
    </Card>
  );
}

export default CheckTable;
