import { useState, useCallback } from "react";

import {
  periodGet,
  periodsGet,
  periodRegister,
  periodsUserGet,
  implementStructure
} from "../services/periodService";

interface Period {
  id: number;
  name: string;
  period: string;
  can_modify_structure: boolean;
  nodes: [
    {
      auto_diagnostic: number;
      percentage_assigned: number;
      percentage_progress: number;
      name: string;
      parent_id: number; 
    }
  ]
}

export const usePeiods = () => {
  const [ periods, setUsers ] = useState<Period[]>([]);
  const [ loading, setLoading ] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const getPeriods = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const newUsers = await periodsGet();
      setUsers(newUsers);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { periods: periods, getPeriods, loading };
};

export const usePeiod = () => {
  const [ period, setUsers ] = useState<Period>();
  const [ loading, setLoading ] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const getPeriod = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const newUsers = await periodGet();
      setUsers(newUsers);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { period: period, getPeriod, loading };
};

interface PeriodRegister {
  valid: {
    id: number;
  }
}

export const useRegisterPeiod = () => {
  const [ period, setUsers ] = useState<PeriodRegister>();
  const [ loading, setLoading ] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const registerPeriod = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const newUsers = await periodRegister();
      setUsers(newUsers);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { register_eriod: period, registerPeriod, loading };
};

interface Permissions {
  period: number;
}

export const useValidatePeriod = () => {
  const [ valid, setPermissions ] = useState<Permissions>({ period: 0 });
  const [ loading, setLoading ] = useState<Boolean>(false);

  const [ errors, setErrors ] = useState(null);

  const validatePeriod = useCallback(async () => {
    try {
      setLoading(true);
      setErrors(null);
      const newUsers = await periodsUserGet();
      setPermissions(newUsers);
    } catch (e: any) {
      setErrors(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { valid: valid, validatePeriod, errors, loading };
};

export const useImplementStructurePeriod = () => {
  const [ period, setUsers ] = useState<Boolean>(null);
  const [ loading, setLoading ] = useState<Boolean>(false);

  const [ error, setError] = useState(null);

  const implementStructurePeriod = useCallback(async (id_period: number) => {
    try {
      setLoading(true);
      setError(null);
      const newUsers = await implementStructure(id_period);
      setUsers(newUsers);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { implement: period, implementStructurePeriod, loading, error };
};
