import { useState, useCallback } from "react";

import {
  guidelinesGet,
  guidelineGet,
  editGuideline,
  registerGuideline,
  guidelinesParentGet,
} from "../services/guidelinesService";

interface Guideline {
  id: number;
  name: string;
  code: string;
  percentage_assigned: number;
  percentage_progress?: number;
  node_structure_id: number;
  description: string;
  node?: {
    name: string;
  }
}

export const useGuidelines = () => {
  const [guidelines, setUsers] = useState<Guideline[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const getGuidelines = useCallback(async (id_node: number) => {
    try {
      setLoading(true);
      setError(null);
      const newUsers = await guidelinesGet(id_node);
      setUsers(newUsers);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { guidelines: guidelines, getGuidelines, loading };
};

export const useGuideline = () => {
  const [guidelines, setGuidelines] = useState<Guideline>();
  const [loading, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const getGuidelines = useCallback(async (id: number) => {
    try {
      setLoading(true);
      setError(null);
      const newUsers = await guidelineGet(id);
      setGuidelines(newUsers);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { guideline: guidelines, getGuidelines, loading, setGuidelines };
};

export const useGuidelinesParent = () => {
  const [guidelines, setGuidelines] = useState<Guideline[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const getGuidelinesParent = useCallback(async (parent_id: number) => {
    try {
      setLoading(true);
      setError(null);
      const newUsers = await guidelinesParentGet(parent_id);
      setGuidelines(newUsers);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { guidelines: guidelines, getGuidelinesParent, loading, setGuidelines };
};



interface PropsResponse {
  message: string;
  errors: {
    code: string[];
    percentage_assigned: string[];
    description?: string[];
  },
  success: {
    status: string;
  },
  valid?: Guideline
}

const reset: PropsResponse = {
  message: '',
  errors: {
    code: [],
    percentage_assigned: [],
    description: [],
  },
  success: {
    status: ''
  }
};


export const useGuidelineRegister = () => {
  const [register, setResponseRC] = useState<PropsResponse>(null);
  const [loadingRG, setLoading] = useState<boolean>(false);

  const [, setError] = useState(null);

  const guidelineReg = useCallback(async (props: Guideline): Promise<PropsResponse> => {
    try {
      setLoading(true);
      setError(null);
      const userResponse = await registerGuideline(props);
      setResponseRC(userResponse);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
    return reset;
  }, []);

  return { register: register, guidelineReg, setResponseRC, loadingRG };
};

export const useGuidelineEdit = () => {
  const [ edited, setResponseEG ] = useState<PropsResponse>(null);
  const [ loadingEG, setLoading ] = useState<boolean>(false);

  const [ errors, setError ] = useState(null);

  const guidelineEdit = useCallback(async (props: Guideline): Promise<PropsResponse> => {
    try {
      setLoading(true);
      setError(null);
      const userResponse = await editGuideline(props);
      setResponseEG(userResponse);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
    return reset;
  }, []);

  return { edited: edited, guidelineEdit, setResponseEG, loadingEG, errors };
};
