import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';
import { environment } from './environment/environment'

// Create an instance of Axios
const axiosInstance: AxiosInstance = axios.create({
  baseURL: environment.baseUrl, // Set your API base URL here
});

// Configura un interceptor de respuesta
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      window.location.href = '/auth/login';
    }
    return Promise.reject(error);
  }
);

// Add a request interceptor
axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    // config.headers['Content-Type'] = 'application/json';
    return config
  },
  error => {
    Promise.reject(error)
  }
);

export default axiosInstance;
