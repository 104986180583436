import Card from "components/card";

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Parent from "./Parent";
import Box from "./Card";
import CardAdd from "./CardAdd";

import Breadcrumb from "./Breadcrumb";
import Levels from "./Levels";

import { FiSearch } from "react-icons/fi";

import { HiLockClosed } from "react-icons/hi";

import Swal from 'sweetalert2';

// Redux
import {
  setIdNode
} from "../../../../store/nodeSlice";

// Hooks
import {
  useStructures,
  useLevels,
  useCloseStructure,
  usePeriod
} from '../../../../hooks/useStructures';

import {
  useValidatePeriod,
  useRegisterPeiod,
  useImplementStructurePeriod
} from '../../../../hooks/usePeriod';

import { useEffect, useState } from "react";

interface LevelsData {
  id: number;
  level_num: number;
  id_parent?: number;
  name: string; 
  path_file: string;
}

const Banner = () => {
  const [name_parent, setNameParent] = useState<string>('GOBIERNO DIGITAL')
  const [parent, setParent] = useState<number>(null)

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { getPeriod, period } = usePeriod()
  const { getLevels, levels } = useLevels()
  const { getStructures, structure } = useStructures()

  const { registerPeriod, register_eriod } = useRegisterPeiod()
  
  const { valid, validatePeriod } = useValidatePeriod()

  useEffect(()=>{
    if ( Number(localStorage.getItem('period')) !== 0 ) {
      getPeriod( Number(localStorage.getItem('period')) )
    }else{
      validatePeriod()
    }
  }, [getPeriod, validatePeriod])

  useEffect(()=>{
    if ( valid.period == null ) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-secondary',
            cancelButton: 'btn btn-dark ltr:mr-3 rtl:ml-3',
            popup: 'sweet-alerts',
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
          .fire({
              title: 'No se encontró  un periodo habilitado',
              text: "¿Desea habilitar el periodo para esta compañía?",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'HABILITAR',
              cancelButtonText: 'Cancelar',
              reverseButtons: true,
              padding: '2em',
          })
          .then((result) => {
              if (result.value) {
                registerPeriod();
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                swalWithBootstrapButtons.fire('Cancelado', 'No se ejecutó ninguna acción', 'error');
              }
          });
    }
  }, [valid, registerPeriod])

  const { implementStructurePeriod, implement  } = useImplementStructurePeriod()

  useEffect(()=>{
    if (register_eriod) {
      if ( register_eriod.valid.id ) {
        const toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
        });
  
        toast.fire({
          icon: 'success',
          title: '¡Periodo habilitado con éxito!',
          padding: '10px 20px',
        });

        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-secondary',
            cancelButton: 'btn btn-dark ltr:mr-3 rtl:ml-3',
            popup: 'sweet-alerts',
          },
          buttonsStyling: false,
        });
        swalWithBootstrapButtons
            .fire({
                title: 'Implementar estructura anterior',
                text: "¿Desea implementar la estructura del año anterior en este año?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'IMPLEMENTAR',
                cancelButtonText: 'Cancelar',
                reverseButtons: true,
                padding: '2em',
            })
            .then((result) => {
                if (result.value) {
                  implementStructurePeriod(register_eriod.valid.id); //Implementar estructura
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                  swalWithBootstrapButtons.fire('Cancelado', 'No se ejecutó ninguna acción', 'error');
                }
            });

  
        localStorage.setItem('period', register_eriod.valid.id.toString());
      }
    }
  }, [register_eriod, implementStructurePeriod])

  useEffect(()=>{
    if (implement !== null) {
      const toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
      });
      if (implement) {
        toast.fire({
          icon: 'success',
          title: '¡Estrucutura implementada!',
          padding: '10px 20px',
        });

        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }else{
        toast.fire({
          icon: 'error',
          title: '¡No se implemento la estructura!',
          padding: '10px 20px',
        });
      }
    }
  }, [implement])

  useEffect(()=>{
    getStructures(parent)
  }, [getStructures, parent])

  useEffect(()=>{
    getLevels( Number(localStorage.getItem('period')) )
  }, [getLevels])

  const [level_activated, setLevelActivate] = useState<LevelsData>()

  const [levelsBreadcrumb, setLevesBreadcrumb] = useState<LevelsData[]>([])

  useEffect(()=>{
    if ( levels.length > 0 ) {
      const minValueItem = levels.reduce((prev, curr) => prev.level_num < curr.level_num ? prev : curr);
      setLevelActivate( levels.filter(e => e.level_num === minValueItem.level_num)[0] )
    }
  }, [levels])

  const selectNewParent = (e: number) => {
    if ( (levels.filter(e => e.level_num === level_activated.level_num+1 )[0]) !== undefined ) {
      setNameParent(structure.filter(s => s.id === e)[0].name)
      setParent(e)

      setLevelActivate( levels.filter(e => e.level_num === level_activated.level_num+1 )[0] )
    }else if( e > 0 ) {
      dispatch(setIdNode(e));
      navigate('/admin/guidelines');
    }
  }

  useEffect(()=>{
    if (level_activated) {
      level_activated.id_parent = parent;
      setLevesBreadcrumb([...levelsBreadcrumb, level_activated ]);
    }
  }, [level_activated])

  const [reloadData, setReloadData] = useState<boolean>(false);

  useEffect(() => {
    if (reloadData) {
      // Establecer la bandera de recarga de nuevo a false después de recargar
      setReloadData(false);
    }
  }, [reloadData]);

  /**
   * Refresw view elements
   */
  const handleRegistrationComplete = () => {
    getStructures(parent)
  };

  const setPositon = (level: LevelsData) => {
    setParent( level.id_parent )
    setLevesBreadcrumb( [...levels.filter(e => e.level_num < level.level_num)] );
    setLevelActivate( levels.filter(e => e.level_num === level.level_num )[0] );

    setNameParent( (level.id_parent != null) ? structure.filter(s => s.id === level.id_parent)[0].name : 'GOBIERNO DIGITAL' )
  }

  const clearUrl = (url: string) => {
    return (url).replace('public/', '');
  }

  const [filteredStructure, setFilteredStructure] = useState([]);

  useEffect(()=>{
    setFilteredStructure(structure);
  },[structure, parent])

  const [search, setSearch] = useState('');
  useEffect(() => {
    setFilteredStructure( () => {
      return structure.filter((item) => {
        return (
          item.id.toString().includes(search.toLowerCase()) ||
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          item.percentage_assigned.toString().includes(search.toLowerCase())
        );
      })
    })
  }, [search, structure]);

  const { closed, closeStrucutre } = useCloseStructure()

  const closeStructure = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'linear mt-2 px-5 rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200',
        cancelButton: 'btn btn-dark ltr:mr-3 rtl:ml-3',
        popup: 'sweet-alerts',
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: 'Cerrar estructura',
        text: "¿Está seguro de ejecutar esta acción?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Cerrar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        padding: '2em',
      })
      .then((result) => {
        if (result.value) {
          //execute the Hook to perform the request
          closeStrucutre(Number(localStorage.getItem('period')))
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire('Cancelado', 'No se ejecutó ninguna acción', 'error');
        }
      });
  }

  useEffect(() => {
    if (closed) {
      const toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
      });
      toast.fire({
        icon: 'info',
        title: '¡Se cerro exitosamente!',
        padding: '10px 20px',
      });

      //setResponseCS(false)
      //getDependencies();
      getPeriod(Number(localStorage.getItem('period')))
    }
  }, [closed, getPeriod])

  return (
    <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
      <div className="w-full">

        {/* Print parent component */}
        <Breadcrumb levels={levelsBreadcrumb} onclick={(e) => {setPositon(e)}} />

        {levels.length === 0 ? (
          <Levels />
        ) : (<></>)}

        {/* Print parent component */}
        <Parent name={name_parent} />
        
        <div className="border-t border-gray-300 my-4"></div>

        {level_activated ? (
          <div className="flex w-full items-end justify-end">
            <p className="text-lg font-medium">
              { level_activated.name }
            </p>
          </div>
        ) : (
          <></>
        )}

        <div className="w-full flex items-end justify-end ">
          <div className="mt-[3px] w-[200px] flex h-[61px] flex-grow justify-around rounded-full px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[220px] md:flex-grow-0 md:gap-1 xl:w-[220px] xl:gap-2">
            <div className=" w-full flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[200px]">
              <p className="pl-3 pr-2 text-xl">
                <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
              </p>
              <input
                type="text"
                value={search}
                placeholder="Buscar..."
                onChange={(e) => setSearch(e.target.value)}
                className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
              />
            </div>
          </div>
        </div>

        <div className="grid gap-4 px-2 md:grid-cols-5 sm:grid-cols-4 xl:grid-cols-6 ">
          {/* Print add component */}
          { period && period.can_modify_structure && level_activated ? ( 
            <CardAdd id_parent={parent} level_id={level_activated.id} onRegistrationComplete={handleRegistrationComplete} />
          ):(
            <></>
          )}

          {/* Print childre´s component */}
          {level_activated && filteredStructure
            .filter((item) => item.parent_id === parent)
              .map((col, i) => {
                return <>
                <Box
                  onRegistrationComplete={handleRegistrationComplete}
                  key={i}
                  path_file={clearUrl(level_activated.path_file)}
                  percentage_assigned={col.percentage_assigned}
                  name={col.name}
                  id={col.id}
                  onclick={(e) => selectNewParent(e)}
                  can_modify_structure={period && period.can_modify_structure}
                  level_id={col.level_id}
                  parent_id={col.parent_id}
                />
                </>
          })}
        </div>
      </div>

      { period && period.can_modify_structure ? (
        <button
          className="fixed bottom-8 right-8 w-16 h-16 flex justify-center items-center bg-[#f63b3b] hover:bg-[#f63b3b] text-white dark:text-white font-bold py-2 px-4 rounded-full shadow-md"
          onClick={closeStructure}
          >
            <HiLockClosed />
        </button>
      ) : (<></>)}
    </Card>
  );
};

export default Banner;
