import axiosInstance from "../axiosConfig";
import { environment } from "../environment/environment";

interface Structure {
  id?: number;
  name: string;
  percentage_assigned: number;
  percentage_progress?: number;
  period_id: number;
  parent_id: number;
  level_id: number;
}

interface Levels {
  levels: Array<{
    name: string;
  }>,
  period_id: number;
}

interface LevelsData {
  id: number;
  level_num: number;
  name: string;
  path_file: string;
  code: string;
}

export const structuresGet = async (period_id: number): Promise<Structure[]> => {
  try {
    const res = await axiosInstance.get(`${environment.baseUrl}nodes/structures?filter[period]=${localStorage.getItem("period")}`);

    if (res.statusText !== "OK") {
      return [];
    }

    return res.data;
  } catch (error) {
    return [];
  }
};

export const structuresGetLast = async (period_id: number): Promise<Structure[]> => {
  try {
    const res = await axiosInstance.get(`${environment.baseUrl}nodes/structures?filter[period]=${localStorage.getItem("period")}&filter[levelNum]=last`);

    if (res.statusText !== "OK") {
      return [];
    }

    return res.data;
  } catch (error) {
    return [];
  }
};

export const registerStructure = async (props: Structure): Promise<Boolean> => {
  if (props.name === '') return false;

  try {
    const res = await axiosInstance.post(`${environment.baseUrl}nodes/structures`, props);

    if (res.statusText !== "OK") {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};

export const editStructure = async (props: Structure): Promise<Boolean> => {
  if (props.name === '') return false;

  try {
    const res = await axiosInstance.put(`${environment.baseUrl}nodes/structures/${props.id}`, props);

    if (res.statusText !== "OK") {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};

export const removeStructure = async (node_structure_id: number): Promise<Boolean> => {
  if (node_structure_id === 0) return false;

  try {
    const res = await axiosInstance.delete(`${environment.baseUrl}nodes/structures/${node_structure_id}`);

    if (res.statusText !== "OK") {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};

export const registerLevels = async (props: Levels): Promise<Boolean> => {
  if (props.levels.length === 0) return false;

  try {
    const res = await axiosInstance.post(`${environment.baseUrl}levels`, props, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (res.statusText !== "OK") {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};

export const levelsGet = async (period_id: number): Promise<LevelsData[]> => {
  try {
    const res = await axiosInstance.get(`${environment.baseUrl}periods/${period_id}?include=levels`);

    if (res.statusText !== "OK") {
      return [];
    }

    return res.data.levels;
  } catch (error) {
    return [];
  }
};

interface Period {
  can_modify_structure: boolean;
  can_modify_autodiagnostic: boolean;
  name: string;
}

export const periodGet = async (period_id: number): Promise<Period> => {
  try {
    const res = await axiosInstance.get(`${environment.baseUrl}periods/${period_id}`);

    if (res.statusText !== "OK") {
      return {
        can_modify_structure: false,
        can_modify_autodiagnostic: false,
        name: ''
      };
    }

    return res.data;
  } catch (error) {
    return {
      can_modify_structure: false,
      can_modify_autodiagnostic: false,
      name: ''
    };
  }
};

export const closeStructure = async (period_id: number): Promise<Boolean> => {
  try {
    const res = await axiosInstance.get(`${environment.baseUrl}periods/close/structure/${period_id}`);

    if (res.statusText !== "OK") {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};