import InputField from "components/fields/InputField";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import { 
  useForgotPassword
} from '../../hooks/useAuth';

import {MdOutlineArrowBack} from "react-icons/md";

export default function SignIn() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
  });
  
  const { forgotPassword, forgot_password } = useForgotPassword()
  
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [evt.target.name]: evt.target.value });
  };

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    forgotPassword(formData);
  }

  useEffect(() => {
    const toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
    });

    if (forgot_password) {
      if (forgot_password?.success) {
        toast.fire({
          icon: 'success',
          title: `¡Se envio un correo eléctronico para asignar una nueva contraseña!`,
          padding: '10px 20px',
        });

        navigate('/auth/login');
      }else if ( forgot_password?.error.email ) {
        toast.fire({
          icon: 'error',
          title: 'Correo eléctronico no encontraso.',
          padding: '10px 20px',
        });
      } else if ( forgot_password?.errors.email || forgot_password?.errors.token ) {
        toast.fire({
          icon: 'error',
          title: 'El token de restablecimiento de contraseña no es válido.',
          padding: '10px 20px',
        });
      } else if ( forgot_password?.errors.password ) {
        toast.fire({
          icon: 'error',
          title: 'La contraseña debe tener al menos 8 caracteres y signos *#@$.',
          padding: '10px 20px',
        });
      }
    }
  }, [forgot_password, navigate])

  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">

        <Link
          className="flex h-9 w-9 items-center justify-center rounded-full bg-indigo-100 dark:bg-indigo-100 dark:bg-white/5"
          to={`/auth/login`}
        >
          <MdOutlineArrowBack className="h-6 w-6 text-gray-800 dark:text-white" />
        </Link>

        <br />
        <hr />
        <br />

        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Recupera tu cuenta
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Ingresa tu correo electrónico para buscar tu cuenta.
        </p>

        <form onSubmit={submitForm}>
          {/* Password */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Correo eléctronico*"
            placeholder="Correo eléctronico"
            id="email"
            type="email"
            name="email"
            onChange={handleChange}
          />
          {/* Checkbox */}
          <button type="submit" className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
            Buscar
          </button>
        </form>
      </div>
    </div>
  );
}
