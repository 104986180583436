import { useEffect, Fragment, useState, useContext } from 'react';
import InputField from "components/fields/InputField";
import { useParams, Link } from "react-router-dom";
import Swal from 'sweetalert2';
import Select, { MultiValue } from 'react-select';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import {
  MdDelete,
  MdDragIndicator,
  MdModeEditOutline,
  MdOutlineArrowBack,
  MdDriveFolderUpload,
} from "react-icons/md";

import { Dialog, Transition } from '@headlessui/react';

import Card from "components/card";

import {
  useTasks,
  useTaskRemove,
  useTaskRegister,
  useTaskEdited,  
} from '../../../../hooks/useTasks';

import {
  useUsersDependenci
} from '../../../../hooks/useUsers';

import {
  useDependencies
} from '../../../../hooks/useDependencies';

import {
  useGuideline
} from '../../../../hooks/useGuidelines';

// Redux
import {
  setIdNode
} from "../../../../store/nodeSlice";

import { Can } from '@casl/react';
import { AbilityContext } from '../../../../casl/abilityContext';

type ItemType = {
  value: number;
  label: string;
};

interface Task {
  id: number;
  name: string;
  guideline_id: number;
  deadline: string;
  approver_id: string;
  uploaders: Array<{
    id: number;
    name: string;
  }>;
  description: string;
}

function CheckTable() {
  const dispatch = useDispatch();
  const ability = useContext(AbilityContext);

  const { id_guideline } = useParams();

  const { users, getUsersDependencie } = useUsersDependenci()
  const { tasks, getTasks } = useTasks()
  const { dependencies, getDependencies } = useDependencies()

  const { guideline, getGuidelines } = useGuideline()

  useEffect(() => {
    if (guideline) {
      dispatch(setIdNode(guideline.node_structure_id));
    }
  }, [guideline, dispatch])

  const [options_select, setOptionsSelect] = useState<MultiValue<{ value: number; label: string; }>>([])

  useEffect(() => {
    getDependencies();
  }, [getDependencies]);

  const [formData, setFormData] = useState<Task>({
    id: 0,
    name: '',
    guideline_id: 0,
    deadline: "",
    approver_id: "",
    uploaders: [],
    description: ""
  });

  useEffect(() => {
    getTasks(Number(id_guideline))
    getGuidelines(Number(id_guideline))
  }, [getTasks, id_guideline, getGuidelines])

  const [dependecies_list, setOptionsDependencies] = useState<ItemType[]>([])

  useEffect(() => {
    const list = dependencies.map(objeto => ({
      value: objeto.id,
      label: objeto.name
    }));

    setOptionsDependencies(list)
  }, [dependencies])

  
  useEffect(() => {
    const list = users.map(user => ({
      value: user.id,
      label: user.name
    }));

    setOptionsSelect(list)
  }, [users])


  const { register, setResponseRT, loadingRT, taskReg } = useTaskRegister();

  useEffect(() => {
    if (register) {
      const toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
      });
      toast.fire({
        icon: 'success',
        title: '¡Tarea registrada exitosamente!',
        padding: '10px 20px',
      });

      setResponseRT(null)
    }
    getTasks(Number(id_guideline))
    setModal21(false)
  }, [register, getTasks, setResponseRT, id_guideline])



  const { edited, setResponseUT, taskEdit, loadingUT} = useTaskEdited();

  useEffect(() => {
    if (register) {
      const toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
      });
      toast.fire({
        icon: 'success',
        title: '¡Tarea editada exitosamente!',
        padding: '10px 20px',
      });

      setResponseUT(null)
    }
    getTasks(Number(id_guideline))
    setModal21(false)
  }, [edited, getTasks, setResponseUT])


  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    isEditing ? taskEdit(formData) : taskReg(formData);
  }

  const handleChangeDate = (evt: React.ChangeEvent<HTMLInputElement>) => {
    //console.log(  new Date(evt.target.value).getDay() ,  moment(evt.target.value).format('d-m-Y')  )
    setFormData({ ...formData, [evt.target.name]: evt.target.value });
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [evt.target.name]: evt.target.value });
  };

  const handleChangeTextarea = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormData({ ...formData, [evt.target.name]: evt.target.value });
  };

  const handleSelectChange = (name: string, evt: MultiValue<{ value: number; label: string; }>) => {
    setFormData({ ...formData, [name]: evt.map((obj) => obj ? { id: obj.value, name: obj.label } : null) });
  };

  const [modal21, setModal21] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleSave = () => {
    // Logic for saving the saved title
    setFormData({
      id: 0,
      name: '',
      guideline_id: Number(id_guideline),
      deadline: "",
      approver_id: localStorage.getItem("uuid"),
      uploaders: [],
      description: ''
    })

    setIsEditing(false);
    setModal21(true);
  };

  const handleEdit = (task: Task) => {

    const date = moment(task.deadline);
    const formattedDate = date.format('YYYY-MM-DD');

    setFormData({
      id: task.id,
      name: task.name,
      guideline_id: task.guideline_id,
      deadline: formattedDate,
      approver_id: task.approver_id,
      uploaders: task.uploaders,
      description: task.description
    })

    console.log(formattedDate)

    setIsEditing(true);
    setModal21(true)
  };

  const tiggersGet = (evt: { value: number; label: string; }) => {
    getUsersDependencie( evt.value )
  }

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit', // 'numeric' will give you the month as a number with leading zeros
    day: '2-digit',   // 'numeric' will give you the day as a number with leading zeros
  };

  const { remove, setResponseRMT, taskRemove} = useTaskRemove()

  const deleteTask = (id: number) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'bg-[#4CAF50] border-none text-white py-1 px-2 rounded-md mx-3',
        cancelButton: 'bg-red-500 border-none text-white py-1 px-2 rounded-md',
        popup: 'sweet-alerts',
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: 'Eliminar tarea',
        text: "¿Está seguro de eliminar esta tarea?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        padding: '2em',
      })
      .then((result) => {
        if (result.value) {
          taskRemove(id);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire('Cancelado', 'No se ejecutó ninguna acción', 'error');
        }
      });
  }

  useEffect(() => {
    if (remove) {
      const toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
      });
      toast.fire({
        icon: 'success',
        title: '¡Tarea eliminada!',
        padding: '10px 20px',
      });

      getTasks(Number(id_guideline))
    }

    setResponseRMT(false)
  }, [remove, setResponseRMT, getTasks, id_guideline])

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <div className="relative flex flex-row justify-between mt-5">
        <div className="flex items-center">
          <Link
            className="flex h-9 w-9 items-center justify-center rounded-full bg-indigo-100 dark:bg-indigo-100 dark:bg-white/5"
            to={`/admin/guidelines`}
          >
            <MdOutlineArrowBack className="h-6 w-6 text-gray-800 dark:text-white" />
          </Link>

          <h4 className="text-xl font-bold text-navy-700 ms-4 dark:text-white">
            Tareas {guideline ? (<>/ {guideline.code}</>) : (<></>)}
          </h4>
        </div>
        <Can I="store" a="tasks" ability={ability}>
          <button onClick={handleSave} className="linear mt-2 w-1/6 rounded-xl bg-brand-500 py-[10px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
            Nueva
          </button>
        </Can>
      </div>

      <Transition appear show={modal21} as={Fragment}>
        <Dialog as="div" open={modal21} onClose={() => setModal21(false)}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0" />
            </Transition.Child>
            <div className="fixed inset-0 z-[999] bg-[black]/60">
                <div className="flex min-h-screen items-start justify-center px-4">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="panel my-8 w-full max-w-xl overflow-hidden  rounded-lg border-0 p-0 text-black dark:text-white-dark">
                            <Card extra={"w-full h-full p-3"}>

                            <div className="flex items-center justify-between bg-[#fbfbfb] px-5 py-3 dark:bg-[#121c2c]">
                                <h5 className="text-lg font-bold">{isEditing ? 'Editar' : 'Registrar'} Tarea</h5>
                                <button onClick={() => setModal21(false)} type="button" className="text-white-dark hover:text-dark">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                </button>
                            </div>

                              <div className="mt-2 mb-8 w-full">
                                <form className="space-y-5" onSubmit={submitForm}>

                                  <InputField
                                    value={formData.name}
                                    variant="auth"
                                    extra="mb-3"
                                    label="Nombre*"
                                    placeholder="Tarea ..."
                                    id="name"
                                    type="text"
                                    name="name"
                                    onChange={handleChange}
                                  />

                                  <InputField
                                    value={formData.deadline}
                                    variant="auth"
                                    extra="mb-3"
                                    label="Fecha limite*"
                                    placeholder="dd-mm-yyyy"
                                    pattern="\d{1,2}-\d{1,2}-\d{4}"
                                    id="deadline"
                                    type="date"
                                    name="deadline"
                                    onChange={handleChangeDate}
                                  />

                                  <div className='mb-3'>
                                    <label
                                      className={`text-sm text-navy-700 dark:text-white`}
                                    >
                                      Dependencia
                                    </label>
                                    <Select
                                      options={dependecies_list}
                                      placeholder="Seleccionar dependencia"
                                      id="dependency_id"
                                      name="dependency_id"
                                      onChange={(e: any) =>  tiggersGet(e)}
                                    />
                                  </div>

                                  <div className='mb-3'>
                                    <label
                                      className={`text-sm text-navy-700 dark:text-white`}
                                    >
                                      Responsables:
                                    </label>
                                    <Select
                                      value={ isEditing ? formData.uploaders?.map((obj) => obj ? { value: obj.id, label: obj.name } : null) : formData.uploaders?.map((obj) => obj ? { value: obj.id, label: obj.name } : null) }
                                      onChange={(item) => handleSelectChange('uploaders', item)}
                                      placeholder="Seleccione responsables"
                                      options={options_select}
                                      isMulti
                                      isSearchable={true}/>
                                  </div>

                                  <div className='mb-3'>
                                    <label
                                      htmlFor='description'
                                      className={`text-sm text-navy-700 dark:text-white`}
                                    >
                                      Descripción
                                    </label>
                                    <textarea
                                      value={formData.description}
                                      name="description"
                                      id="description"
                                      onChange={handleChangeTextarea}
                                      className={`mt-2 flex h-20 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none`}
                                    ></textarea>
                                  </div>

                                  <div className="flex w-full justify-end">
                                    {isEditing ? (
                                      <button
                                        type="submit"
                                        disabled={loadingUT}
                                        className="linear mt-2 px-5 rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                                          {loadingUT ? (
                                          'Cargando...'
                                        ) : (
                                          'Guardar cambios'
                                        )} 
                                      </button>
                                    ):(
                                      <button
                                      type="submit"
                                      disabled={loadingRT}
                                      className="linear mt-2 px-5 rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                                        {loadingRT ? (
                                          'Cargando...'
                                        ) : (
                                          'Registrar'
                                        )} 
                                    </button>
                                    )}
                                  </div>
                                </form>
                              </div>
                            </Card>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
      </Transition>


      <div className="datatables">
        <div className="h-full w-full">

          <div className="mt-5 flex items-center justify-between p-2">
            <div className=" flex items-center justify-center w-1/4 border-2 h-10">
              <h6 className='text-sm font-semibold'>
                TAREA
              </h6>
            </div>
            <div className="flex items-center justify-center w-1/4 border-2 h-10">
              <h6 className='text-sm font-semibold'>
                USUARIOS ASIGNADOS
              </h6>
            </div>
            <div className="flex items-center justify-center w-1/4 border-2 h-10">
              <h6 className='text-sm font-semibold'>
                FECHA LIMITE
              </h6>
            </div>
            <div className="flex items-center justify-center w-1/4 border-2 h-10">
              <h6 className='text-sm font-semibold'>
                EVIDENCIAS
              </h6>
            </div>
            <div className="flex items-center justify-center w-1/4 border-2 h-10">
              <h6 className='text-sm font-semibold'>
                Editar
              </h6>
            </div>
          </div>

          {tasks.map((task, i) => (
            <div key={i} className="mt-5 flex items-center justify-between p-2">
              <div className="flex items-start justify-start gap-2 w-1/4">
                <MdDragIndicator className="h-6 w-6 text-navy-700 dark:text-white" />
                <p className="text-base font-bold text-navy-700 dark:text-white">
                  {task.name}
                </p>
              </div>
              <div className="flex items-center justify-center w-1/4">
                <ul className="list-disc">
                {task.uploaders.map((uploader, u)=> (
                  <li key={`u-${u}`}><p className='text-sm'>{uploader.name}</p></li>
                ))}
                </ul>
              </div>
              <div className="flex items-center justify-center w-1/4">
                <span className="font-normal">{ new Intl.DateTimeFormat('en-US', options).format(Date.parse(task.deadline)) }</span>
              </div>
              <div className="flex items-center justify-center w-1/4">
                <Link
                  className="capitalize dark:hover:text-white w-10 rounded-sm bg-gray-300 p-2 font-medium text-white transition duration-200 hover:bg-gray-700 active:bg-gray-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  to={`${task.id}/evidences`}
                >
                  <MdDriveFolderUpload className="h-6 w-6 text-navy-700 hover:text-white" />
                </Link>
              </div>
              <div className="flex items-center justify-center w-1/4">
                <Can I="update" a="tasks" ability={ability}>
                  <div onClick={() => handleEdit(task)} className="cursor-pointer mr-4 flex items-center justify-center text-gray-600 dark:text-white">
                    <MdModeEditOutline />
                  </div>
                </Can>

                <Can I="destroy" a="tasks" ability={ability}>
                  <div
                    onClick={() => deleteTask(task.id)}
                    className="cursor-pointer text-red-700">
                    <MdDelete className="w-5 h-5" />
                  </div>
                </Can>
              </div>
            </div>
          ))}

        </div>
      </div>
    </Card>
  );
}

export default CheckTable;
