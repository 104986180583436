import axiosInstance from "../axiosConfig";
import { environment } from "../environment/environment";

interface Rol {
  id: number;
  name: string;
  guard_name: string;
}

interface Permissions {
  id: number;
  name: string;
  description: string;
  guard_name: string;
  isAssigned: boolean;
}

export const rolesGet = async (): Promise<Rol[]> => {
  try {
    const res = await axiosInstance.get(`${environment.baseUrl}roles`);

    if (res.statusText !== "OK") {
      return [];
    }

    return res.data;
  } catch (error) {
    return [];
  }
};

export const rolGet = async (rol: number): Promise<Rol> => {
  try {
    const res = await axiosInstance.get(`${environment.baseUrl}roles/${rol}`);

    if (res.statusText !== "OK") {
      return {id: 0, name: '', guard_name: ''};
    }

    return res.data[0];
  } catch (error) {
    return {id: 0, name: '', guard_name: ''};
  }
};

export const permissionsGet = async (rol: number): Promise<Permissions[]> => {
  try {
    const res = await axiosInstance.get(`${environment.baseUrl}role/permissions/${rol}`);

    if (res.statusText !== "OK") {
      return [];
    }

    return res.data;
  } catch (error) {
    return [];
  }
};

export const registerRol = async (props: Rol): Promise<Boolean> => {
  if (props.name === '') return false;

  try {
    const res = await axiosInstance.post(`${environment.baseUrl}roles`, props);

    if (res.statusText !== "Created") {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};

export const editRol = async (props: Rol): Promise<Boolean> => {
  if (props.name === '') return false;

  try {
    const res = await axiosInstance.put(`${environment.baseUrl}roles/${props.id}`, props);

    if (res.statusText !== "OK") {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};

export const changeRolStatus = async (
  user: number
): Promise<Boolean> => {
  try {
    const res = await axiosInstance.delete(
      `${environment.baseUrl}roles/${user}`,
    );

    return true;
  } catch (error) {
    return false;
  }
};

interface PropsPermissions {
  role: number;
  permissions: Array<number>;
}

export const addPermission = async ({ permissions, role }: PropsPermissions): Promise<Boolean> => {
  if (permissions.length === 0) return false;

  try {
    const res = await axiosInstance.put(`${environment.baseUrl}assigned/permissions/${role}`, {permissions});

    if (res.statusText !== "OK") {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};

export const removePermission = async ({ permissions, role }: PropsPermissions): Promise<Boolean> => {
  if (permissions.length === 0) return false;

  try {
    const res = await axiosInstance.put(`${environment.baseUrl}remove/permission/${role}/${permissions[0]}`);

    if (res.statusText !== "OK") {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};
