import axiosInstance from "../axiosConfig";
import { environment } from "../environment/environment";

interface Users {
  id: number;
  name: string;
  email: string;
  company_id: number;
  rol_id: number;
  dependency?: {
    name: string;
  },
  tasks?:[]
}

export const usersGet = async (permited : string): Promise<Users[]> => {
  try {
    const res = await axiosInstance.get(permited === 'all' ?
      `${environment.baseUrl}users?include=dependency,tasks,taskUploader`
      :
      `${environment.baseUrl}users?include=dependency,tasks,taskUploader&filter[period]=${localStorage.getItem("period")}`
    );

    if (res.statusText !== "OK") {
      return [];
    }

    return res.data;
  } catch (error) {
    return [];
  }
};

export const usersGetDependencie = async (id_dependencie: number): Promise<Users[]> => {
  try {
    const res = await axiosInstance.get(`${environment.baseUrl}users?filter[Dependency]=${id_dependencie}`);

    if (res.statusText !== "OK") {
      return [];
    }

    return res.data;
  } catch (error) {
    return [];
  }
};

export const registerUser = async (props: Users): Promise<Boolean> => {
  if (props.name === '') return false;

  try {
    const res = await axiosInstance.post(`${environment.baseUrl}users`, props);

    if (res.statusText !== "OK") {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};

export const editUser = async (props: Users): Promise<Boolean> => {
  if (props.name === '') return false;

  try {
    const res = await axiosInstance.put(`${environment.baseUrl}users/${props.id}`, props);

    if (res.statusText !== "OK") {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};

export const changeUserStatus = async (
  user: number
): Promise<Boolean> => {
  try {
    const res = await axiosInstance.delete(
      `${environment.baseUrl}users/${user}`,
    );

    return true;
  } catch (error) {
    return false;
  }
};

interface Permissions {
  permissions: string[];
}

export const permissionsUserGet = async (): Promise<Permissions> => {
  try {
    const res = await axiosInstance
      .get(
        `${environment.baseUrl}user/permissions`,
      ).then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response.data;
      });

    return res;
  } catch (error) {
    return { permissions: []};
  }
};
