import { useEffect, useState } from 'react';

import { Routes, Route, Navigate } from "react-router-dom";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";

import {
  usePermissionsUser
} from './hooks/useUsers'

import { Ability } from '@casl/ability';
import { AbilityContext } from './casl/abilityContext';
import { defineAbilitiesFor, AppAbility } from './casl/ability';

const App = () => {

  const { permissions, getPermissions } = usePermissionsUser();

  useEffect(() => {
    if ( localStorage.getItem("uuid") !== null ) {
      getPermissions()
    }
  }, [getPermissions])

  const [ability, setAbility] = useState<AppAbility>(new Ability());

  useEffect(() => {
    if ( permissions.permissions ) {
      setAbility(defineAbilitiesFor(permissions));
    }
  }, [permissions])

  return (
    <AbilityContext.Provider value={ability}>
      <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route path="admin/*" element={<AdminLayout />} />
        <Route path="/" element={<Navigate to="/auth/login" replace />} />
      </Routes>
    </AbilityContext.Provider>
  );
};

export default App;
