import { useRef, useState, useCallback } from 'react';

import {
  loginUser,
  logout,
  passwordReset,
  forgotPasswordEC,
} from '../services/authService';

interface Props {
  user: string;
  pass: string;
}

interface PropsResponseLogin {
  token: string;
  user: {
    id: string;
    email: string;
    name: string;
    surname: string;
  },
  period: {
    id: number;
  },
  company: {
    id: number;
    logo: {
      path: string,
    };
    type_guideline: string;
  }
}

export function useLogin ({ user, pass }: Props) {
  const [ login, setResponseAuth ] = useState<PropsResponseLogin>();
  const [loading, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);
  const previousSearch = useRef(user);

  const getUserAuth = useCallback(async ({ user, pass }: Props) => {
    try {
      setLoading(true);
      setError(null);
      previousSearch.current = user;
      const userResponse = await loginUser({ user, pass });
      setResponseAuth(userResponse);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { login: login, getUserAuth, loading, setResponseAuth };
};

export function useLogout () {
  const [login, setResponseAuth] = useState<Boolean>(false);
  const [loading, setLoading] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const authLogout = useCallback(async (): Promise<Boolean> => {

    try {
      setLoading(true);
      setError(null);
      const userResponse = await logout();
      setResponseAuth(userResponse);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
    return false;
  }, []);

  return { login: login, authLogout, loading };
};

interface PropsPasswordReset {
  token: string;
  email: string;
  password: string;
  password_confirmation: string;
}

interface PropsRespoonsePR {
  success: string;
  error: {
    email: string;
  }
  errors : {
    email: string;
    password: string;
    token: string;
  }
}

export function usePasswordReset() {
  const [ password_reset, setResponseAuth ] = useState<PropsRespoonsePR>();
  const [ loading, setLoading ] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const PasswordReset = useCallback(async (props: PropsPasswordReset) => {
    try {
      setLoading(true);
      setError(null);
      const userResponse = await passwordReset(props);
      setResponseAuth(userResponse);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { password_reset: password_reset, PasswordReset, loading };
};

interface PropsForgotPassword {
  email: string;
}

export function useForgotPassword() {
  const [ forgot_password, setResponseFP ] = useState<PropsRespoonsePR>();
  const [ loading, setLoading ] = useState<Boolean>(false);

  const [, setError] = useState(null);

  const forgotPassword = useCallback(async (props: PropsForgotPassword) => {
    try {
      setLoading(true);
      setError(null);
      const userResponse = await forgotPasswordEC(props);
      setResponseFP(userResponse);
    } catch (e: any) {
      setError(e.message);
    } finally {
      // tanto en el try como en el catch
      setLoading(false);
    }
  }, []);

  return { forgot_password: forgot_password, forgotPassword, loading };
};

