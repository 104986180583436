import {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  RefObject,
} from 'react';

import Swal from 'sweetalert2';

import { FiSearch } from "react-icons/fi";

import {
  useAutoDiagnosisEdit,
  useAutoDiagnosisRegister
} from '../../../../hooks/useAutoDiagnosis';

import {
  useLevels,
} from '../../../../hooks/useStructures';


interface autoDiagnosis {
  id: number;
  diagnostic: number;
  guideline_id: number;
}

interface Guideline {
  id: number;
  name: string;
  code: string;
  percentage_assigned: number;
  percentage_progress?: number;
  node_structure_id: number;
  description: string;
}

interface ChildProps {
  data: any[];
  guidelines: Guideline[];
  autoDiagnosis: autoDiagnosis[];
  onRegistrationComplete: () => void;
  open: boolean;
}

const HierarchicalTable = forwardRef<RefObject<any>, ChildProps>(
  ({ data, guidelines, autoDiagnosis, onRegistrationComplete, open }, ref) => {
    // A
  const { autoDiagnosisEdit, setResponseEA, edited } = useAutoDiagnosisEdit()
  const { autoDiagnosisReg, setResponseRC, register } = useAutoDiagnosisRegister()

  // Encuentra el nivel más bajo en el arreglo:
  const [lowestLevel, setLowestLevel] = useState(0)

  //Encuentra el nivel mas alto en el arreglo:
  const [lowestMaxLevel, setLowestMaxLevel] = useState(0)

  // Utiliza la función filter para mantener solo los elementos con el nivel más bajo:
  const [filteredData, setFilteredStructure] = useState([]);

  const { getLevels, levels } = useLevels()

  useEffect(()=>{
    getLevels( Number(localStorage.getItem('period')) )
  }, [getLevels])

  useEffect(() => {
    setLowestLevel( Math.min(...data.map(item => item.level_id)) )
    setLowestMaxLevel( Math.max(...data.map(item => item.level_id)) )
  }, [data])

  const [inputValues, setInputValues] = useState<number[]>([]); // Inicializa con tres campos

  useEffect(()=>{
    if (autoDiagnosis) {
      autoDiagnosis.forEach(item => {
        inputValues[item.guideline_id] = item.diagnostic;
      });
    }
  }, [autoDiagnosis])

  const [edit, setEdit] = useState<autoDiagnosis[]>([]);
  const [save, setSave] = useState<autoDiagnosis[]>([]);

  const handleInputData = (value: number, guideline_id: number) => {
    const exist = autoDiagnosis.filter(item => item.guideline_id === guideline_id);

    //console.log( "Value: ", value )

    if ( exist.length ) {

      const index = edit.findIndex(item => item.guideline_id === guideline_id);

      if (index !== -1) {
        // Si el elemento ya existe, actualízalo
        setEdit(edit.map((item, i) => i === index ? {
          id: exist[0].id,
          diagnostic: value,
          guideline_id: guideline_id
        } : item));
      } else {
        // Si el elemento no existe, añádelo
        setEdit([...edit, {
          id: exist[0].id,
          diagnostic: value,
          guideline_id: guideline_id
        }]);
      }
    }else{
      const index = save.findIndex(item => item.guideline_id === guideline_id);

      if (index !== -1) {
        // Si el elemento ya existe, actualízalo
        setSave(save.map((item, i) => i === index ? {
          id: 0,
          diagnostic: value,
          guideline_id: guideline_id
        } : item));
      } else {
        // Si el elemento no existe, añádelo
        setSave([...save, {
          id: 0,
          diagnostic: value,
          guideline_id: guideline_id
        }]);
      }
    }
  }

  const changeInputData = (value: number, guideline_id: number) => {

    if ( value === 0 ) value = NaN;

    let inputValuesR = [...inputValues];

    inputValuesR[guideline_id] = value;

    setInputValues(inputValuesR);
  }

  const saveADiagnotic = () => {
    //event.preventDefault();
    if (edit.length > 0) {
      autoDiagnosisEdit(edit)
    }

    if (save.length > 0) {
      autoDiagnosisReg(save)
    }
  }

  // Funcion que ejecuta el padre para guardar los datos
  useImperativeHandle(ref, () => ({
    current: null,
    saveADiagnotic
  }));

  useEffect(() => {
    if (register) {
      const toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 4000,
      });
      toast.fire({
        icon: 'success',
        title: '¡Registrado exitosamente!',
        padding: '10px 20px',
      });

      setSave([])
      onRegistrationComplete()
    }
    
    setResponseRC(false)
  }, [register, setResponseRC, onRegistrationComplete])

  useEffect(() => {
    if (edited) {
      const toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
      });
      toast.fire({
        icon: 'success',
        title: '¡Editado exitosamente!',
        padding: '10px 20px',
      });

      setEdit([])
      onRegistrationComplete()
    }

    setResponseEA(false)
  }, [edited, setResponseEA, onRegistrationComplete])

  const [search, setSearch] = useState('');
  useEffect(() => {
    setFilteredStructure( () => {
      return data.filter((item) => {
        return (
          item.id.toString().includes(search.toLowerCase()) ||
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          item.percentage_assigned.toString().includes(search.toLowerCase())
        );
      })
    })
  }, [search, data]);

  useEffect(() => {
    if ( filteredData.length > 0 ) {
      setLowestLevel( Math.min(...filteredData.map(item => item.level_id)) )
    }
  },[filteredData])

  const renderHierarchy = (items: any[], level: number) => {
    return items.map((item, index) => (
      <tr key={item.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
        {level === 0 && (
          <td className="" colSpan={1}>
            {item.name}
          </td>
        )}
        {level > 0 && (
          <td className="w-48 p-4 text-center relative justify-center" colSpan={1}>
            <div className="absolute inset-0 flex items-center w-40">
              <p className="">
                {item.name}
              </p>
            </div>
          </td>
        )}

        { item.level_id === lowestMaxLevel ? (
          guidelines.map((guideline, index)=>{
            return (
              guideline.node_structure_id === item.id && (
                <div className='flex justify-between' key={guideline.id}>
                  <td className="text-sm p-4 w-60" colSpan={1}>
                    {guideline.name}
                  </td>

                  <td className="p-4">
                    <input
                      key={guideline.id}
                      type="number"
                      disabled={!open}
                      value={inputValues[guideline.id]}
                      onBlur={(e) => handleInputData(Number(e.target.value), guideline.id)}
                      onChange={(e) => changeInputData(Number(e.target.value), guideline.id)}
                      className="w-32 p-2 border border-gray-300 rounded-md"
                    />
                  </td>
                </div>
              )
            )
          })
        ) : (<></>)}
        
        <td className="w-12 p-4">
          {item.sons && renderHierarchy(item.sons, level + 1)}
        </td>
      </tr>
    ));
  };

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">

      <div className="w-full flex items-end justify-start ">
        <div className="mt-[3px] w-[200px] flex h-[61px] flex-grow justify-around rounded-full px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[220px] md:flex-grow-0 md:gap-1 xl:w-[220px] xl:gap-2">
          <div className=" w-full flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[200px]">
            <p className="pl-3 pr-2 text-xl">
              <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
            </p>
            <input
              type="text"
              value={search}
              placeholder="Buscar..."
              onChange={(e) => setSearch(e.target.value)}
              className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
            />
          </div>
        </div>
      </div>

      <table className="w-full text-sm text-left text-gray-800 dark:text-gray-400">
          <thead>
            <tr>
              {levels && levels.map((level, index) => {
                return (
                  levels.length === index + 1 ? (
                    <th key={index} className='uppercase py-3 flex'>
                      <div className='w-60 p-4'>
                        {level.name}
                      </div>
                      <div className='w-60 py-4 pr-5'>
                        {localStorage.getItem("type_guideline") === 'true' ? 'Artefactos' : 'Lineamientos'}
                      </div>
                    </th>
                  ):(
                    <th key={index} className='w-36 uppercase px-6'>
                      {level.name}
                    </th>
                  )
                )
              })}
            </tr>
          </thead>

          <tbody>
            {guidelines && lowestLevel ? (
              renderHierarchy(filteredData.filter(item => item.level_id === lowestLevel), 0)
            ):(<>Cargando...</>)}
          </tbody>
      </table>
    </div>
  );
});

export default HierarchicalTable;